import React, { FC } from 'react';
import { formatPrice } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface Props {
    cash: [string, number, number][];
}

export const RegisterSessionDetailCash: FC<Props> = ({ cash }) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('cashName')}</TableCell>
                        <TableCell>{t('cashAmount')}</TableCell>
                        <TableCell>{t('cashTotal')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ maxHeight: '200px' }}>
                    {cash.map((item) => (
                        <TableRow key={item[0]}>
                            <TableCell>{t(item[0])}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{item[1]}</TableCell>
                            <TableCell>{formatPrice(item[2])}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
