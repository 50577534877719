const checkLastDigits = (numbers: number[], amount: number) => {
    return (
        numbers.length === 2 &&
        amount.toString().split('.')[1]?.length > 1 &&
        (amount.toString().charAt(amount.toString().length - 1) === `${numbers[0]}` ||
            amount.toString().charAt(amount.toString().length - 1) === `${numbers[1]}`)
    );
};

export function roundAmount(amount: number): number {
    amount = parseFloat(amount.toFixed(2));
    switch (true) {
        case checkLastDigits([1, 6], amount):
            return amount > 0 ? amount - 0.01 : amount + 0.01;
        case checkLastDigits([2, 7], amount):
            return amount > 0 ? amount - 0.02 : amount + 0.02;
        case checkLastDigits([3, 8], amount):
            return amount > 0 ? amount + 0.02 : amount - 0.02;
        case checkLastDigits([4, 9], amount):
            return amount > 0 ? amount + 0.01 : amount - 0.01;
        default:
            return amount;
    }
}

export function roundReturnAmount(amount: number): number {
    amount = parseFloat(amount.toFixed(2));
    switch (true) {
        case checkLastDigits([1, 6], amount):
            return amount > 0 ? amount - 0.01 : amount + 0.01;
        case checkLastDigits([2, 7], amount):
            return amount > 0 ? amount - 0.02 : amount + 0.02;
        case checkLastDigits([3, 8], amount):
            return amount > 0 ? amount - 0.03 : amount + 0.03;
        case checkLastDigits([4, 9], amount):
            return amount > 0 ? amount - 0.04 : amount + 0.04;
        default:
            return amount;
    }
}
