import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IProduct, IProductQuery, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../index';
import { productsClient } from '../clients';

import { QueryKeys } from '../enums';

const client = productsClient;
const listKey = QueryKeys.PRODUCTS;
const itemKey = QueryKeys.PRODUCT;

export function useProductsList(...args: UseListParams<IProduct, IProductQuery>) {
    return useList(client, listKey, ...args);
}

export function useProduct(...args: UseItemParams<IProduct>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveProduct() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteProduct() {
    return useDelete(client, listKey);
}

export function useUploadProductImage(): UseMutationResult<void, AxiosError, { productId: string; file: File }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ productId, file }) => client.uploadImage(productId, file),
        onSuccess: async (result, { productId }) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, productId] });
        },
    });
}

export function useDeleteProductImage(): UseMutationResult<void, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (productId) => client.deleteImage(productId),
        onSuccess: async (result, productId) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, productId] });
        },
    });
}
