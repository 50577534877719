import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mixed, number, object, string } from 'yup';
import { IProduct } from '../../shared';

export function useOutStockWithReason() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                product: mixed<IProduct>().required(),
                amount: number().typeError(t('invalidNumber')).required(),
                reasonId: string().required(),
            }),
        [t],
    );
}
