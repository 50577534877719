import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    formatPrice,
    ICash,
    IRegisterSession,
    PermissionKeys,
    useHasPermission,
    useUpdateRegistrationSession,
} from '../../../shared';
import { calculateCashTotal, getDefaultCashInputs } from '../../utils';
import { useRegisterSession } from '../../validators';
import { RegisterSessionInputs } from '../register-session-inputs/register-session-inputs.component';

interface Props {
    registerSession: IRegisterSession;
}
export const RecountStartRegisterSession: FC<Props> = ({ registerSession }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<keyof ICash>('amount100Euro');
    const [total, setTotal] = useState(0);
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.REGISTER_SESSIONS_WRITE);

    const { mutateAsync: updateRegisterSession } = useUpdateRegistrationSession();
    const form = useForm<ICash>({
        resolver: yupResolver(useRegisterSession()),
        mode: 'onSubmit',
        defaultValues: getDefaultCashInputs(),
    });

    useEffect(() => {
        form.reset(registerSession.cashAtStart);
    }, [form, registerSession]);

    const handleSubmit = async (cash: ICash) => {
        await updateRegisterSession({
            id: registerSession.id,
            cashAtStart: cash,
            totalCashAtStart: calculateCashTotal(cash),
        });
        setOpen(false);
    };

    const values = form.watch();
    useEffect(() => {
        setTotal(calculateCashTotal(values));
    }, [values]);

    return (
        <>
            <Button
                variant="outlined"
                sx={{ width: '100%' }}
                onClick={() => setOpen(true)}
                disabled={!hasWritePermission}
            >
                {t('recountStartRegisterSession')}
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
                <FormProvider {...form}>
                    <form
                        onSubmit={form.handleSubmit(handleSubmit)}
                        onSelect={(event) => setSelected((event.target as HTMLInputElement).id as keyof ICash)}
                    >
                        <DialogTitleWithClose onClose={() => setOpen(false)}>
                            {t('recountStartRegisterSession')}
                        </DialogTitleWithClose>
                        <RegisterSessionInputs selected={selected} />
                        <DialogContent sx={{ backgroundColor: 'background.default', py: 0 }}>
                            <Box sx={{ my: 2 }}>
                                <Typography variant="subtitle1">
                                    {t('totalInRegister')}: {formatPrice(total)}
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" fullWidth variant="contained">
                                {t('updateStart')}
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
};
