import { IRegisterSession, IStartRegisterSession, IUpdateRegisterSession, ListModel } from '../models';
import { QueryKeys } from '../enums';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { registerSessionClient } from '../clients';
import { useItem, UseItemParams, useList, UseListParams } from './read-only.hook';

const client = registerSessionClient;
const listKey = QueryKeys.REGISTER_SESSIONS;
const itemKey = QueryKeys.REGISTER_SESSION;

export function useGetRegisterSessionsList(
    ...args: UseListParams<IRegisterSession>
): UseQueryResult<ListModel<IRegisterSession>> {
    return useList(client, listKey, ...args);
}

export function useGetRegisterSession(...args: UseItemParams<IRegisterSession>): UseQueryResult<IRegisterSession> {
    return useItem(client, listKey, ...args);
}

export function useStartRegistrationSession(): UseMutationResult<IRegisterSession, AxiosError, IStartRegisterSession> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.createOne(item),
        onSuccess: async (currentRegisterSession, request) => {
            queryClient.setQueryData([itemKey, request.organisationId], currentRegisterSession);
        },
    });
}

export function useUpdateRegistrationSession(): UseMutationResult<
    IRegisterSession,
    AxiosError,
    IUpdateRegisterSession
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.updateOne(item.id, item),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, result.organisationId] });
        },
    });
}

export function useStopRegistrationSession(): UseMutationResult<IRegisterSession, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id) => client.stopRegisterSession(id),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, result.organisationId] });
        },
    });
}

export function useCurrentRegisterSession(organisationId?: string) {
    return useQuery<IRegisterSession, AxiosError>({
        queryKey: [itemKey, organisationId],
        queryFn: () => client.getCurrentRegisterSession(organisationId),
        enabled: !!organisationId,
    });
}
