import { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { PermissionKeys } from '../../enums';
import { Forbidden } from '../../pages';
import { useHasPermission } from '../../utils';
import { ErrorFallback } from '../error-fallback/error-fallback.component';
import { Loading } from '../loading/loading.component';
import { useSelectedOrganisation } from '../../contexts';

interface Props {
    requiredPermission?: PermissionKeys | PermissionKeys[];
    children?: ReactNode;
}

export const ProtectedRoute: FC<Props> = ({ requiredPermission, children }) => {
    const { hasPermission, isPending } = useHasPermission();
    const { organisation, isPending: isPendingOrganisation } = useSelectedOrganisation();

    if (isPending || isPendingOrganisation) return <Loading fullscreen />;
    if ((requiredPermission && !hasPermission(requiredPermission)) || !organisation?.id) return <Forbidden />;

    return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
};
