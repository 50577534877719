import React, { FC, useEffect, useState } from 'react';
import {
    formatAmount,
    ICategory,
    ISupplier,
    Loading,
    Page,
    useProductsList,
    useSelectedOrganisation,
} from '../../../shared';
import { Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useGenerateShoppingList } from '../../utils';
import { format } from 'date-fns';

export const ShoppingListsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();

    const [selectedProducts, setSelectedProducts] = useState<string[] | undefined>([]);
    const { data: products, isPending } = useProductsList(
        {
            page: 1,
            pageSize: 10000,
            sortBy: 'shopping_list',
            organisationId: organisation?.id,
        },
        { enabled: !!organisation },
    );

    useEffect(() => {
        setSelectedProducts(
            products?.data.filter((product) => product.stockTarget > (product.stockCount || 0)).map(({ id }) => id),
        );
    }, [products?.data]);

    const onSelectionChange = (ids: GridRowSelectionModel) => {
        setSelectedProducts(ids as string[]);
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('name'),
            flex: 1,
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            flex: 1,
            valueFormatter: (value: ISupplier) => value?.name,
        },
        {
            field: 'category',
            headerName: t('category'),
            flex: 1,
            valueFormatter: (value: ICategory) => value?.name,
        },
        {
            field: 'stockCount',
            headerName: t('stockCount'),
            flex: 1,
            valueFormatter: (value: number, row) => formatAmount(row.measurementUnit, value || 0, t),
        },
        {
            field: 'stockTarget',
            headerName: t('minExpectedStockTarget'),
            flex: 1,
            valueFormatter: (value: number, row) => formatAmount(row.measurementUnit, value || 0, t),
        },
        {
            field: 'standardOrderAmount',
            headerName: t('standardOrderAmount'),
            flex: 1,
            valueFormatter: (value: number, row) => formatAmount(row.measurementUnit, value || 0, t),
        },
    ];

    return (
        <Page
            onBack={() => navigate(-1)}
            title={t('shoppingLists')}
            actions={[
                <Button
                    download={`${t('shoppingLists')}-${format(new Date(), 'dd-MM-yyyy')}`}
                    href={useGenerateShoppingList(selectedProducts, products?.data || [], t)}
                    component={Link}
                    color="primary"
                    variant="contained"
                >
                    {t('printShoppingLists')}
                </Button>,
            ]}
        >
            {isPending ? (
                <Loading />
            ) : (
                <DataGrid
                    checkboxSelection
                    rowSelectionModel={selectedProducts}
                    onRowSelectionModelChange={onSelectionChange}
                    rows={products?.data || []}
                    columns={columns}
                    hideFooter={true}
                />
            )}
        </Page>
    );
};
