import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateString, FormGrid, IParams, LabelValue } from '../../../shared';
import { SavePurchaseItem } from '../../components';
import { useCompletePurchase, usePurchase } from '../../hooks';
import { IPurchaseItem } from '../../models';
import { PurchaseItemsTable } from '../../components';

export const PurchaseItemsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: purchase } = usePurchase(id);
    const { mutateAsync: completePurchase, isPending } = useCompletePurchase();
    const [editPurchaseItem, setEditPurchaseItem] = useState<IPurchaseItem | undefined>(undefined);
    const [open, setOpen] = useState(false);

    const onComplete = async () => {
        try {
            await completePurchase({ id });
            navigate(`/stock/purchases/${id}/detail`);
        } catch (e) {
            throw e;
        }
    };

    const handleClose = () => {
        setOpen(false);
        setEditPurchaseItem(undefined);
    };

    return (
        <Card variant="outlined">
            {purchase && (
                <CardContent>
                    <Typography variant="subtitle1" pt={2} mb={1}>
                        {t('invoiceData')}
                    </Typography>
                    <Box sx={{ '.MuiTypography-root': { fontSize: '14px' } }}>
                        <LabelValue label={t('isGift')} value={purchase.gift ? t('yes') : t('no')} />
                        {purchase.invoiceNumber && (
                            <LabelValue label={t('invoiceNumber')} value={purchase.invoiceNumber} />
                        )}
                        <LabelValue label={t('date')} value={formatDateString(purchase.date.toString())} />
                        {purchase.supplier && <LabelValue label={t('supplier')} value={purchase.supplier?.name} />}
                    </Box>

                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1">{t('products')}</Typography>
                    <PurchaseItemsTable
                        purchase={purchase}
                        setOpen={setOpen}
                        setEditPurchaseItem={setEditPurchaseItem}
                    />

                    <Button variant="outlined" sx={{ mt: 2 }} onClick={() => setOpen(true)}>
                        + {t('addProduct')}
                    </Button>
                    <SavePurchaseItem
                        purchase={purchase}
                        purchaseItem={editPurchaseItem}
                        open={open}
                        onClose={handleClose}
                    />
                </CardContent>
            )}

            <CardActions sx={{ backgroundColor: 'background.default' }}>
                <FormGrid containerProps={{ justifyContent: 'space-between' }}>
                    <Button
                        startIcon={<ChevronLeft />}
                        onClick={() => navigate(`/stock/purchases/${id}`)}
                        disabled={isPending}
                    >
                        {t('shared.previous')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onComplete}
                        disabled={isPending || !purchase?.purchaseItems?.length}
                    >
                        {t('completePurchase')}
                    </Button>
                </FormGrid>
            </CardActions>
        </Card>
    );
};
