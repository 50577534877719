import { Box, Card, CardMedia, Skeleton, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormGrid, IProduct, ListModel, useProductsList, useSelectedOrganisation, useShop } from '../../../shared';

interface Props {
    shopCategoryId?: string;
    searchedProducts?: ListModel<IProduct>;
}

export const ProductImages: FC<Props> = ({ shopCategoryId, searchedProducts }) => {
    const { organisation } = useSelectedOrganisation();
    const { setSelectedProduct } = useShop();
    const { data: productList } = useProductsList(
        {
            organisationId: organisation?.id,
            shopCategoryId,
            pageSize: 1000,
        },
        { enabled: !!shopCategoryId },
    );
    const products = searchedProducts ? searchedProducts : productList;

    return (
        <>
            <Box sx={{ maxHeight: 'calc(100vh - 304px)', overflow: 'auto' }}>
                <FormGrid xs={12} sm={6} md={3} lg={2}>
                    {products?.data?.map((product) => (
                        <Card key={product.id} onClick={() => setSelectedProduct(product)} sx={{ border: 'none' }}>
                            {product.image ? (
                                <CardMedia
                                    component="img"
                                    height="90"
                                    src={`/shop-api${product.image.downloadUrl}`}
                                    alt={product.name}
                                    sx={{ objectFit: 'contain', border: 'none' }}
                                />
                            ) : (
                                <Skeleton variant="rectangular" width={'100%'} height={90} animation={false} />
                            )}

                            <Typography align="center" variant="body2" pt={1}>
                                {product.name}
                            </Typography>
                        </Card>
                    ))}
                </FormGrid>
            </Box>
        </>
    );
};
