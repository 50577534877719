import { Info } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentRegisterSession, useSelectedOrganisation, Widget } from '../../../shared';
import { RecountStartRegisterSession } from '../recount-start-register-session/recount-start-register-session.component';
import { StopRegisterSession } from '../stop-register-session/stop-register-session.component';

export const RegisterSessionInformation: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: currentRegisterSession } = useCurrentRegisterSession(organisation?.id);

    return (
        currentRegisterSession && (
            <Widget
                sx={{ maxWidth: 400, margin: 'auto', mt: 2, height: 290 }}
                title={t('registerInformation')}
                icon={<Info color="primary" />}
                variant="subtitle1"
                footer={
                    <Stack spacing={2} width="100%">
                        <RecountStartRegisterSession registerSession={currentRegisterSession} />
                        <StopRegisterSession registerSession={currentRegisterSession} />
                    </Stack>
                }
            >
                <Stack spacing={2}>
                    <Typography variant="caption">{t('registerSessionInformationDescription')}</Typography>
                </Stack>
            </Widget>
        )
    );
};
