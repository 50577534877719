import {
    ISaleQuotum,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../shared';
import { saleQuotaClient } from '../clients';

const client = saleQuotaClient;
const listKey = QueryKeys.SALE_QUOTA;
const itemKey = QueryKeys.SALE_QUOTUM;

export function useSaleQuotaList(...args: UseListParams<ISaleQuotum>) {
    return useList(client, listKey, ...args);
}

export function useSaleQuotum(...args: UseItemParams<ISaleQuotum>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveSaleQuotum() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteSaleQuotum() {
    return useDelete(client, listKey);
}
