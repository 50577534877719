import { MenuItem, Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledSelect, ICategory, IProductQuery, Options, OptionsToggle, useCategoriesList } from '../../../shared';

interface Props {
    filter: IProductQuery;
    onChange: (filter: IProductQuery) => void;
}

export const ProductsFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<IProductQuery>({ mode: 'onChange' });

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        categoryId: { label: t('category'), active: false },
    });

    const { data: categories } = useCategoriesList({ pageSize: 100 });
    const formValues = form.watch();

    useEffect(() => {
        const { categoryId } = options;
        if (!categoryId.active) {
            form.setValue('categoryId', undefined);
        }
    }, [form, options]);

    useEffect(() => {
        if (JSON.stringify(formValues) !== JSON.stringify(filter)) {
            onChange(formValues);
        }
    }, [formValues, filter, onChange]);

    return (
        <FormProvider {...form}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <OptionsToggle options={options} onChange={setOptions} />
                {options.categoryId.active && (
                    <ControlledSelect name="categoryId" className="filter" label={t('category')}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {(categories?.data || []).map((category: ICategory) => (
                            <MenuItem value={category.id} key={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}
            </Stack>
        </FormProvider>
    );
};
