import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { IEnvironment } from '../../shared';
import { createBaseTheme } from './base.theme';

export const createCarityTheme = (themeOptions: IEnvironment['theme']): Theme => {
    const theme = createBaseTheme(themeOptions);

    const typography = getTypography(theme);

    return deepmerge(
        createBaseTheme(themeOptions),
        createTheme(theme, {
            typography,
        }),
    );
};

function getTypography({ palette }: Theme): ThemeOptions['typography'] {
    return {
        description: {
            textDecoration: 'overline',
            display: 'block',
            fontWeight: 'bold',
            color: palette.text.secondary,
        },
    };
}
