import { shopApi } from '../../shared';
import { ISupportCaseInfo } from '../models';

class SupportCaseClient {
    public async getInfo(code?: string, organisation?: string): Promise<ISupportCaseInfo> {
        const { data } = await shopApi.get<Omit<ISupportCaseInfo, 'cardCode'>>('/support-cases/info', {
            params: { code, organisation },
        });
        return { ...data, cardCode: code as string };
    }
}

export const supportCaseClient = new SupportCaseClient();
