import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../shared';
import { purchaseClient } from '../clients';
import { ICreatePurchaseItem, IPurchase, IUpdatePurchaseItem } from '../models';

const client = purchaseClient;
const listKey = QueryKeys.PURCHASES;
const itemKey = QueryKeys.PURCHASE;

export function usePurchasesList(...args: UseListParams<IPurchase>) {
    return useList(client, listKey, ...args);
}

export function usePurchase(...args: UseItemParams<IPurchase>) {
    return useItem(client, itemKey, ...args);
}

export function useSavePurchase() {
    return useSave(client, listKey, itemKey);
}

export function useDeletePurchase() {
    return useDelete(client, listKey);
}

export function useCompletePurchase(): UseMutationResult<IPurchase, AxiosError, { id: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }) => client.completePurchase(id),
        onSuccess: async (purchase) => {
            await queryClient.setQueryData([itemKey, purchase.id], purchase);
            await queryClient.invalidateQueries({ queryKey: [listKey] });
        },
    });
}

export function useCreatePurchaseItem(): UseMutationResult<IPurchase, AxiosError, ICreatePurchaseItem> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (item) => client.createPurchaseItem(item),
        onSuccess: async (purchase) => {
            await queryClient.setQueryData([itemKey, purchase.id], purchase);
        },
    });
}

export function useUpdatePurchaseItem(): UseMutationResult<
    IPurchase,
    AxiosError,
    { id: string; purchaseItem: IUpdatePurchaseItem }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, purchaseItem }) => client.updatePurchaseItem(id, purchaseItem),
        onSuccess: async (purchase) => {
            await queryClient.setQueryData([itemKey, purchase.id], purchase);
        },
    });
}

export function useRemovePurchaseItem(): UseMutationResult<IPurchase, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (purchaseItemId) => client.removePurchaseItem(purchaseItemId),
        onSuccess: async (purchase) => {
            await queryClient.setQueryData([itemKey, purchase.id], purchase);
        },
    });
}
