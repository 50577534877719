import { ISaveShopCategory, IShopCategory } from '../models';
import { BaseClient } from './base.client';
import { shopApi } from './shop-api.client';

class ShopCategoriesClient extends BaseClient<IShopCategory, ISaveShopCategory> {
    constructor() {
        super(shopApi, '/shop-categories');
    }
}

export const shopCategoriesClient = new ShopCategoriesClient();
