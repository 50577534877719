import { useCsvExport } from './csv-export.util';
import { formatAmount, IProduct } from '../../shared';
import { TFunction } from 'i18next';

function mapProductsToCSV(products: IProduct[], t: TFunction) {
    return products?.map((product) => ({
        ...product,
        supplier: product.supplier?.name,
        storeOrder: product.storeOrder?.toString(),
        category: product.category?.name,
        stockCount: formatAmount(product.measurementUnit, product.stockCount || 0, t).replace(',', '.'),
        stockTarget: formatAmount(product.measurementUnit, product.stockTarget || 0, t).replace(',', '.'),
        standardOrderAmount: formatAmount(product.measurementUnit, product.standardOrderAmount || 0, t).replace(
            ',',
            '.',
        ),
        standardOrderAmountColli:
            product.amountPerColli && product.standardOrderAmount
                ? Math.ceil(product.standardOrderAmount / product.amountPerColli)
                      .toString()
                      .replace(',', '.')
                : '',
        amountPerColli: product.amountPerColli?.toString(),
    }));
}

export function useGenerateShoppingList(selected: string[] | undefined, data: IProduct[], t: TFunction) {
    const columns = [
        {
            field: 'supplier',
            headerName: t('supplier'),
        },
        {
            field: 'articleNumberSupplier',
            headerName: t('articleNumberSupplier'),
        },
        {
            field: 'name',
            headerName: t('product'),
        },
        {
            field: 'storeOrder',
            headerName: t('storeOrder'),
        },
        {
            field: 'category',
            headerName: t('category'),
        },
        {
            field: 'stockCount',
            headerName: t('stockCount'),
        },
        {
            field: 'stockTarget',
            headerName: t('minExpectedStockTarget'),
        },
        {
            field: 'standardOrderAmount',
            headerName: t('standardOrderAmount'),
        },
        { field: 'standardOrderAmountColli', headerName: t('standardOrderAmountColli') },
        {
            field: 'amountPerColli',
            headerName: t('amountPerColli'),
        },
    ];

    return useCsvExport(
        columns,
        selected?.length
            ? mapProductsToCSV(
                  data.filter((product) => selected.includes(product.id)),
                  t,
              )
            : [],
    );
}
