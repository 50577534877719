export function getDefaultCashInputs() {
    return {
        amount100Euro: 0,
        amount50Euro: 0,
        amount20Euro: 0,
        amount10Euro: 0,
        amount5Euro: 0,
        amount2Euro: 0,
        amount1Euro: 0,
        amount50Cents: 0,
        amount20Cents: 0,
        amount10Cents: 0,
        amount5Cents: 0,
        amount2Cents: 0,
        amount1Cents: 0,
    };
}
