import { useMemo } from 'react';
import { number, object, string } from 'yup';

export function useCategorySchema() {
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                tax: number()
                    .transform((value) => (value === '' || isNaN(value) ? null : value))
                    .optional()
                    .nullable(),
            }),
        [],
    );
}
