import { Backspace } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { getNumericValueFromNumpad } from '../../utils';
import { SxProps } from '@mui/system';

interface Props {
    value: string;
    onChange: (value: string) => void;
    disable: { disableSeparator: boolean; disableButtons: boolean };
    showNumpad?: boolean;
    sx?: SxProps;
}

const inputOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'remove', '0', ','];

export const InputNumpad: FC<Props> = ({ value, onChange, disable, showNumpad = true, sx }) => {
    const [isDirty, setDirty] = useState(false);

    const onClick = useCallback(
        (char: string) => {
            if (!isDirty) setDirty(true);
            const currentValue = isDirty ? value : '';
            return onChange(getNumericValueFromNumpad(char, currentValue));
        },
        [isDirty, onChange, value],
    );

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Backspace') {
                onClick('remove');
            }
            if (!disable.disableButtons) {
                if (event.key === ',' || event.key === '.') {
                    !disable.disableSeparator && onClick(',');
                } else if (inputOptions.includes(event.key)) {
                    onClick(event.key);
                }
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [disable.disableButtons, disable.disableSeparator, onClick]);

    if (!showNumpad) return <></>;
    return (
        <Box sx={{ ...sx, button: { height: '75px', width: '100%' } }}>
            <Grid container justifyContent="center" spacing={1}>
                {inputOptions.map((input) => (
                    <Grid item key={input} xs={4} sx={{ button: { p: 0 } }}>
                        {input === 'remove' && (
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => onClick(input)}
                                startIcon={<Backspace sx={{ ml: 1 }} />}
                            />
                        )}
                        {input === ',' && (
                            <Button
                                sx={{ fontSize: '20px' }}
                                variant="outlined"
                                onClick={() => onClick('.')}
                                disabled={disable.disableSeparator || disable.disableButtons}
                            >
                                {input}
                            </Button>
                        )}
                        {input !== 'remove' && input !== ',' && (
                            <Button
                                sx={{ fontSize: '20px' }}
                                variant="outlined"
                                key={input}
                                onClick={() => onClick(input)}
                                disabled={disable.disableButtons && isDirty}
                            >
                                {input}
                            </Button>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
