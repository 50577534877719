import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { adminSubRoutes } from '../../admin.routes';

export const AdminPage: FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pages.admin');
    }, [t]);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { key: 'products', text: t('products') },
                        { path: '/admin/products', text: t('products') },
                        { path: '/admin/sale-quota', text: t('saleQuota') },
                        { path: '/admin/shop-categories', text: t('shopCategories') },
                        { key: 'parties', text: t('parties') },
                        { path: '/admin/suppliers', text: t('suppliers') },
                        { key: 'organisation', text: t('organisation') },
                        { path: '/admin/budgets', text: t('budgets') },
                        { path: '/admin/settings', text: t('settings') },
                        { key: 'general', text: t('general') },
                        { path: '/admin/categories', text: t('categories') },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {adminSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    {<subRoute.component />}
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
