import { ICategory, ISaveCategory } from '../models';
import { BaseClient } from './base.client';
import { shopApi } from './shop-api.client';

class CategoriesClient extends BaseClient<ICategory, ISaveCategory> {
    constructor() {
        super(shopApi, '/categories');
    }
}

export const categoriesClient = new CategoriesClient();
