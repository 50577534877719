import React, { FC, useEffect } from 'react';
import { Box, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useSettings } from '../../../../../admin/hooks';
import {
    ControlledCheckbox,
    ControlledNumberInput,
    formatPrice,
    FormGrid,
    IProduct,
    ISupplier,
    useSelectedOrganisation,
} from '../../../../../shared';
import { useTranslation } from 'react-i18next';
import { IPurchaseItemForm } from '../../../../models';

interface Props {
    product: IProduct;
    supplier: ISupplier | undefined;
    form: UseFormReturn<IPurchaseItemForm>;
}

export const OverwritePriceInputs: FC<Props> = ({ product, supplier, form }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: settings } = useSettings(organisation?.id);
    const watchTaxIncluded = form.watch('taxIncluded');
    const watchUpdateProductPrice = form.watch('updateProductPrice');
    const watchPurchasePrice = form.watch('unitPurchasePrice');

    useEffect(() => {
        if (watchPurchasePrice) {
            const price = watchTaxIncluded ? watchPurchasePrice : watchPurchasePrice * (1 + product.tax / 100);
            form.setValue('newProductPrice', ((100 - (supplier?.discountPercentage || 0)) * price) / 100);
        }
    }, [form, supplier?.discountPercentage, watchPurchasePrice, watchUpdateProductPrice, watchTaxIncluded, product]);

    useEffect(() => {
        if (settings) {
            const price = watchTaxIncluded ? watchPurchasePrice : watchPurchasePrice * (1 + product.tax / 100);
            const value = settings?.solidarityPriceIsPurchasePrice
                ? price
                : (price || 0) * (1 + (settings?.additionalSolidarityCharge || 0) / 100);

            form.setValue('newSolidarityPrice', value);
        }
    }, [form, settings, watchPurchasePrice, watchTaxIncluded, product]);

    return (
        <FormGrid xs={12} sm={6} sx={{ '.MuiFormControl-root': { mb: 0 } }}>
            <>
                <Stack spacing={2}>
                    <Box>
                        <TextField label={t('currentProductPrice')} value={formatPrice(product?.price)} disabled />
                        {supplier && <Box sx={{ mt: 4 }}></Box>}
                    </Box>
                    <TextField
                        label={t('currentSolidarityPrice')}
                        value={formatPrice(product?.solidarityPrice)}
                        disabled
                    />
                    <ControlledCheckbox
                        name="updateProductPrice"
                        label={t('updateProductPrice')}
                        control={form.control}
                    />
                </Stack>
            </>
            {watchUpdateProductPrice && (
                <Stack spacing={2}>
                    <Box>
                        <ControlledNumberInput
                            name="newProductPrice"
                            label={t('newProductPrice')}
                            InputProps={{ startAdornment: <InputAdornment position="start" children={'€'} /> }}
                        />
                        {supplier && (
                            <Typography sx={{ pt: 1 }}>{`${t('supplierDiscount')} ${
                                supplier.discountPercentage.toString().replace('.', ',') || 0
                            } %`}</Typography>
                        )}
                    </Box>
                    <Box>
                        <ControlledNumberInput
                            name="newSolidarityPrice"
                            label={t('newSolidarityPrice')}
                            InputProps={{ startAdornment: <InputAdornment position="start" children={'€'} /> }}
                        />
                        {settings?.solidarityPriceIsPurchasePrice === false && (
                            <Typography sx={{ pt: 1 }}>
                                {t('additionalSolidarityChargeExplanation', {
                                    percentage: settings?.additionalSolidarityCharge,
                                })}
                            </Typography>
                        )}
                    </Box>
                </Stack>
            )}
        </FormGrid>
    );
};
