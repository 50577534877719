import { Stack } from '@mui/material';
import { add, isBefore, isSameWeek, set } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateString, Status, useBreakpoint, useSelectedOrganisation, Widget } from '../../../../shared';
import { ISupportCaseInfo, ISupportPeriod } from '../../../models';

interface Props {
    supportCase: ISupportCaseInfo;
    activeSupportPeriod?: ISupportPeriod;
}

type status = { value: string; status: 'success' | 'warning' | 'error' };

export const SupportCaseInfoWarningPanel: FC<Props> = ({ supportCase, activeSupportPeriod }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const lg = useBreakpoint('lg');

    const postalCode: status = useMemo(() => {
        if (supportCase?.postalCode) {
            return {
                value: supportCase?.postalCode,
                status:
                    supportCase?.postalCode === organisation?.address?.postalCode ||
                    organisation?.preferredPostalCodes?.includes(supportCase?.postalCode)
                        ? 'success'
                        : 'warning',
            };
        }
        return { value: t('notFound'), status: 'warning' };
    }, [t, supportCase, organisation]);

    const supportPeriod: status = useMemo(() => {
        if (activeSupportPeriod) {
            const supportPeriodAlmostOver = isBefore(
                new Date(activeSupportPeriod.endDate),
                add(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), { weeks: 6, days: 1 }),
            );

            return {
                value: `${t('active')} (${formatDateString(activeSupportPeriod.endDate)})`,
                status: supportPeriodAlmostOver ? 'warning' : 'success',
            };
        }
        return { value: t('notFound'), status: 'error' };
    }, [t, activeSupportPeriod]);

    const lastVisit: status = useMemo(() => {
        const filteredRegistrations = supportCase.lastRegistrations.filter(
            (reg) => reg.supportTypeId === window.carity.environment.supportTypeId,
        );
        if (filteredRegistrations.length > 0) {
            const visitDate = new Date(
                filteredRegistrations[filteredRegistrations.length - 1].dateOfRegistration.slice(0, 10),
            );

            return {
                value: formatDateString(
                    supportCase.lastRegistrations[filteredRegistrations.length - 1].dateOfRegistration,
                ),
                status: !isSameWeek(new Date(), visitDate, { weekStartsOn: 1 }) ? 'success' : 'error',
            };
        }
        return { value: t('notFound'), status: 'error' };
    }, [t, supportCase]);

    return (
        <Widget sx={{ minHeight: 'fit-content', mb: 0 }}>
            <Stack spacing={1} direction={lg ? 'column' : 'row'} width="100%" justifyContent="space-between">
                <Status label={t('postalCode')} value={postalCode.value} status={postalCode.status} />
                <Status label={t('supportPeriod')} value={supportPeriod.value} status={supportPeriod.status} />
                <Status label={t('lastVisit')} value={lastVisit.value} status={lastVisit.status} />
            </Stack>
        </Widget>
    );
};
