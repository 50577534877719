export default {
    purchase: 'Aankoop',
    purchases: 'Aankopen',
    newPurchase: 'Nieuwe aankoop',
    invoiceNumber: 'Factuurnummer',
    date: 'Datum',
    gift: 'Gift',
    status: 'Status',
    draft: 'Concept',
    completed: 'Voltooid',
    isGift: 'Aankoop is een gift',
    noSupplier: 'Geen leverancier',
    items: 'Producten',
    invoiceData: 'Factuurgegevens',
    addProduct: 'Voeg product toe',
    searchProducts: 'Zoek voor een product',
    loadingSearch: 'Laden...',
    startSearch: '...',
    noProductsFound: 'Geen producten gevonden',
    noProductsSelected: 'Nog geen producten toegevoegd',
    totalPurchasePrice: 'Totale prijs',
    unitPurchasePrice: 'Aankoopprijs per stuk/kg',
    unitPurchasePricePiece: 'Aankoopprijs per stuk',
    unitPurchasePriceWeight: 'Aankoopprijs per kg',
    taxIncluded: 'Inclusief {{amount}}% BTW',
    deletePurchase: 'Verwijder aankoop',
    deletePurchaseText: 'Bent u zeker dat u deze aankoop wilt verwijderen?',
    completePurchase: 'Aankoop afronden',
    purchaseDetail: 'Detail aankoop',
    purchaseInfo: 'Aankoopgegegevens',

    updateProductPrice: 'Verkoopprijs aanpassen',
    currentProductPrice: 'Vorige verkoopprijs (incl btw)',
    newProductPrice: 'Nieuwe verkoopprijs (incl btw)',
    supplierDiscount: 'Leverancierskorting',
    currentSolidarityPrice: 'Vorige solidaire verkoopprijs (incl btw)',
    newSolidarityPrice: 'Nieuwe solidaire verkoopprijs (incl btw)',
    additionalSolidarityChargeExplanation: 'Toegepaste meerprijs {{percentage}}%',

    createPurchaseItem: 'Voeg aankoop item toe voor product: {{product}}',
    savePurchaseItem: 'Bewaar aankoop item',
    createProduct: 'Bewaar product',
    createNewProduct: 'Maak een nieuw product aan',
    searchOrCreateProduct: 'Zoek een bestaand product of maak een nieuw product aan',

    stockMovement: 'Stock beweging',
    stockMovements: 'Stock bewegingen',
    deleteStockMovementTitle: 'Verwijder stock beweging',
    deleteStockMovementText: 'Bent u zeker dat u deze stock beweging wilt verwijderen?',
    type: 'Type',
    IN_STOCK: 'In stock',
    OUT_STOCK: 'Uit stock',
    reason: 'Reden',
    stockMovementSale: 'Verkoop',
    stockMovementPurchase: 'Aankoop',
    outStockWithReason: 'Product uitscannen',

    createShoppingList: 'Boodschappenlijsten aanmaken',
    shoppingLists: 'Boodschappenlijsten',
    printShoppingLists: 'Exporteer lijsten',
    stockToBuy: 'Aan te kopen aantal',
};
