import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, number, object, string } from 'yup';
import { useProductSchema } from '../../admin/validators';

export function usePurchaseItemSchema() {
    const { t } = useTranslation();
    const priceSchema = number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .typeError(t('invalidNumber'))
        .min(0, t('negativeNumber'))
        .when('updateProductPrice', {
            is: true,
            then: (schema) => schema.required(),
        })
        .optional();

    return useMemo(
        () =>
            object().shape({
                id: string().optional(),
                unitPurchasePrice: number().typeError(t('requiredField')).required(),
                amount: number().typeError(t('requiredField')).required(),
                taxIncluded: boolean().required(),
                updateProductPrice: boolean().required(),
                newProductPrice: priceSchema,
                newSolidarityPrice: priceSchema,
            }),
        [t, priceSchema],
    );
}

export function useNewProductPurchaseItemSchema() {
    const { t } = useTranslation();
    const productSchema = useProductSchema();
    return useMemo(
        () =>
            object().shape({
                unitPurchasePrice: number().typeError(t('requiredField')).required(),
                amount: number().typeError(t('requiredField')).required(),
                taxIncluded: boolean().required(),
                newProduct: productSchema,
            }),
        [t, productSchema],
    );
}
