import { Alert, Dialog } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean;
    onClose: () => void;
    text?: string;
}
export const WarningNoSupportCaseComponent: FC<Props> = ({ show, text, onClose }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={show} onClose={onClose} hideBackdrop sx={{ mb: '55px' }}>
            <Alert variant="filled" severity="error">
                {text ? text : t('supportCaseNotScanned')}
            </Alert>
        </Dialog>
    );
};
