import { List, ListItemButton, ListItemText, ListSubheader, styled } from '@mui/material';
import { transparentize } from 'polished';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IListItem {
    path: string;
    text: string;
}

interface ISubTitle {
    text: string;
    key: string;
}

interface Props {
    items: (ISubTitle | IListItem)[];
}

function isListItem(item: IListItem | ISubTitle): item is IListItem {
    return Boolean((item as IListItem).path);
}

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,

    '&:hover, &:focus': {
        background: transparentize(0.95, theme.palette.primary.main),
        color: theme.palette.primary.main,
    },

    '&.Mui-selected': {
        background: transparentize(0.9, theme.palette.primary.main),
        color: theme.palette.primary.main,

        '& .MuiListItemText-primary': {
            fontWeight: 'bold',
        },

        '&:hover, &:focus': {
            background: transparentize(0.9, theme.palette.primary.main),
        },
    },
})) as typeof ListItemButton;

export const SideNav: FC<Props> = ({ items }) => {
    const location = useLocation();

    return (
        <List component="div" sx={{ p: 3 }}>
            {items.map((item) =>
                isListItem(item) ? (
                    <StyledListItem
                        key={item.path}
                        selected={location.pathname.startsWith(item.path)}
                        component={Link}
                        to={item.path}
                    >
                        <ListItemText primary={item.text} />
                    </StyledListItem>
                ) : (
                    <ListSubheader key={item.key} sx={{ fontVariant: 'all-small-caps' }}>
                        {item.text}
                    </ListSubheader>
                ),
            )}
        </List>
    );
};
