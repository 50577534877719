import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount, LabelValue, MeasurementUnit, useShop } from '../../../shared';
import { useCreateSaleItem, useUpdateSaleItem } from '../../hooks';

export const QuotumExceeded: FC = () => {
    const { t } = useTranslation();
    const { quotumRuleExceeded, setQuotumRuleExceeded } = useShop();
    const { mutateAsync: createSaleItem } = useCreateSaleItem();
    const { mutateAsync: updateSaleItem } = useUpdateSaleItem();
    const allowedOverwrite = window.carity.environment.allowOverwriteSaleQuota;

    const onConfirm = () => {
        if (quotumRuleExceeded?.createSaleItem) {
            createSaleItem({ ...quotumRuleExceeded?.createSaleItem, ignoreQuotumRules: true });
        } else if (quotumRuleExceeded?.updateSaleItem?.saleItemId) {
            updateSaleItem({
                saleItemId: quotumRuleExceeded.updateSaleItem.saleItemId,
                saleItem: { ...quotumRuleExceeded?.updateSaleItem, ignoreQuotumRules: true },
            });
        }
        setQuotumRuleExceeded(undefined);
    };

    const measurementUnit = quotumRuleExceeded?.product.measurementUnit || MeasurementUnit.PIECE;

    return (
        <Dialog open={!!quotumRuleExceeded}>
            <DialogTitle>{t('saleQuotumExceededTitle')}</DialogTitle>
            {quotumRuleExceeded && (
                <DialogContent>
                    <Typography variant="subtitle2">
                        {t('saleQuotumExceededForProduct', {
                            product: quotumRuleExceeded.product.name,
                            period: t('saleQuotumExceeded.' + quotumRuleExceeded.product.saleQuotum?.period),
                        })}
                    </Typography>
                    <Stack direction="row" spacing={2} textAlign="center">
                        <LabelValue
                            label={t('saleQuotumAllowedAmount')}
                            value={formatAmount(measurementUnit, quotumRuleExceeded.rule.amount, t)}
                        />
                        <LabelValue
                            label={t('saleQuotumReceivedAmount')}
                            value={formatAmount(measurementUnit, quotumRuleExceeded.amount, t)}
                        />
                    </Stack>
                    {allowedOverwrite && <Typography mt={2}>{t('saleQuotumExceededConfirm')}</Typography>}
                </DialogContent>
            )}
            <DialogActions>
                <Stack direction="row" spacing={2}>
                    {allowedOverwrite && (
                        <Button variant="contained" onClick={onConfirm}>
                            {t('shared.confirm')}
                        </Button>
                    )}
                    <Button variant="outlined" onClick={() => setQuotumRuleExceeded(undefined)}>
                        {t(allowedOverwrite ? 'shared.cancel' : 'ok')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
