import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';

export function useSupplierSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                discountPercentage: number()
                    .typeError(t('invalidNumber'))
                    .min(0, t('discountMustBeBetween'))
                    .max(100, t('discountMustBeBetween'))
                    .required(),
            }),
        [t],
    );
}
