import { shopApi } from '../../shared';
import { ISettings } from '../models';

class SettingsClient {
    public async getSettings(organisationId?: string): Promise<ISettings> {
        const { data } = await shopApi.get(`/settings?organisationId=${organisationId}`);
        return data;
    }

    public async updateSettings(settings: ISettings): Promise<ISettings> {
        const { data } = await shopApi.post(`/settings`, settings);
        return data;
    }
}

export const settingsClient = new SettingsClient();
