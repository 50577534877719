import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    PurchaseDetailPage,
    PurchasesPage,
    PurchasesWizardPage,
    ShoppingListsPage,
    StockMovementsPage,
    StockPage,
} from './pages';

export const stockRoutes: IRouteConfig[] = [
    { path: '/stock', redirect: '/stock/purchases', requiredPermissions: [PermissionKeys.PURCHASES_READ] },
    { path: '/stock/*', component: StockPage },
];

export const stockSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/purchases', component: PurchasesPage, requiredPermissions: [PermissionKeys.PURCHASES_READ] },
    {
        path: '/purchases/:id/detail',
        component: PurchaseDetailPage,
        requiredPermissions: [PermissionKeys.PURCHASES_READ],
    },
    { path: '/purchases/new/*', component: PurchasesWizardPage, requiredPermissions: [PermissionKeys.PURCHASES_WRITE] },
    { path: '/purchases/:id/*', component: PurchasesWizardPage, requiredPermissions: [PermissionKeys.PURCHASES_WRITE] },
    {
        path: '/purchases/shopping-lists',
        component: ShoppingListsPage,
        requiredPermissions: [PermissionKeys.SHOPPING_LISTS_WRITE],
    },

    {
        path: '/stock-movements',
        component: StockMovementsPage,
        requiredPermissions: [PermissionKeys.STOCK_MOVEMENTS_READ],
    },
];
