import { Box, IconButton, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading, useDebouncedState, useProductsList, useSelectedOrganisation } from '../../../shared';
import { ProductImages } from '../product-images/product-images.component';
import { Clear } from '@mui/icons-material';

export const SearchProduct: FC = () => {
    const { t } = useTranslation();
    const [debouncedSearch, search, setSearch] = useDebouncedState('');
    const { organisation } = useSelectedOrganisation();
    const { data: products } = useProductsList(
        {
            page: 1,
            pageSize: 10,
            search: debouncedSearch,
            organisationId: organisation?.id,
        },
        {
            enabled: !!debouncedSearch,
        },
    );

    return (
        <Box>
            <TextField
                sx={{ mb: products ? 2 : 0 }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t('searchProduct')}
                InputProps={{
                    endAdornment: search && (
                        <IconButton onClick={() => setSearch('')}>
                            <Clear />
                        </IconButton>
                    ),
                }}
            />
            {debouncedSearch !== search ? (
                <Loading />
            ) : (
                <>
                    {products && products?.data?.length > 0 && <ProductImages searchedProducts={products} />}
                    {products && products?.data?.length <= 0 && (
                        <Typography variant="body2" sx={{ color: 'grey' }}>
                            {t('noProductsFound')}
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );
};
