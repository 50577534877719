import { useCallback, useContext, useMemo } from 'react';
import { PermissionsContext } from '../contexts';
import { PermissionKeys } from '../enums';

interface UseHasPermissionResult {
    hasPermission: (requiredPermission: PermissionKeys | PermissionKeys[], checkAllPermissions?: boolean) => boolean;
    isPending: boolean;
}

export const useHasPermission = (): UseHasPermissionResult => {
    const { permissions: userRoles } = useContext(PermissionsContext);

    const userPermissions = useMemo(() => {
        return userRoles?.flatMap((role) => role.roles).flatMap((role) => role.permissions as PermissionKeys[]);
    }, [userRoles]);

    const hasPermission = useCallback(
        (requiredPermission: PermissionKeys | PermissionKeys[], checkAllPermissions?: boolean): boolean =>
            permissionChecker(userPermissions || [], requiredPermission, checkAllPermissions),
        [userPermissions],
    );

    return { hasPermission, isPending: !userPermissions };
};

function permissionChecker(
    permissions: PermissionKeys[],
    requiredPermission: PermissionKeys | PermissionKeys[],
    checkAllPermissions = true,
) {
    if (Array.isArray(requiredPermission)) {
        return checkAllPermissions
            ? requiredPermission.every((perm) => permissions.includes(perm))
            : requiredPermission.some((perm) => permissions.includes(perm));
    }
    return permissions.includes(requiredPermission);
}
