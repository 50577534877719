import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    AdminPage,
    CategoriesPage,
    CategoryEditPage,
    ProductDetailPage,
    ProductEditPage,
    ProductsPage,
    SaleQuotaPage,
    SaleQuotumEditPage,
    SettingsPage,
    ShopCategoriesPage,
} from './pages';
import { BudgetPage } from './pages/budget/budget.page';
import { ShopCategoryEditPage } from './pages/shop-category-edit/shop-category-edit.page';
import { SupplierEditPage } from './pages/supplier-edit/supplier-edit.page';
import { SuppliersPage } from './pages/suppliers/suppliers.page';

export const adminRoutes: IRouteConfig[] = [
    { path: '/admin', redirect: '/admin/products', requiredPermissions: [PermissionKeys.PRODUCTS_READ] },
    { path: '/admin/*', component: AdminPage },
];

export const adminSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/products', component: ProductsPage, requiredPermissions: [PermissionKeys.PRODUCTS_READ] },
    {
        path: '/products/new',
        component: ProductEditPage,
        requiredPermissions: [PermissionKeys.PRODUCTS_WRITE],
    },
    {
        path: '/products/:id',
        component: ProductDetailPage,
        requiredPermissions: [PermissionKeys.PRODUCTS_READ],
    },
    {
        path: '/products/:id/edit',
        component: ProductEditPage,
        requiredPermissions: [PermissionKeys.PRODUCTS_WRITE],
    },
    { path: '/sale-quota', component: SaleQuotaPage, requiredPermissions: [PermissionKeys.SALE_QUOTA_READ] },
    {
        path: '/sale-quota/new',
        component: SaleQuotumEditPage,
        requiredPermissions: [PermissionKeys.SALE_QUOTA_WRITE],
    },
    {
        path: '/sale-quota/:id/edit',
        component: SaleQuotumEditPage,
        requiredPermissions: [PermissionKeys.SALE_QUOTA_WRITE],
    },
    { path: '/categories', component: CategoriesPage, requiredPermissions: [PermissionKeys.CATEGORIES_READ] },
    {
        path: '/categories/new',
        component: CategoryEditPage,
        requiredPermissions: [PermissionKeys.CATEGORIES_WRITE],
    },
    {
        path: '/categories/:id/edit',
        component: CategoryEditPage,
        requiredPermissions: [PermissionKeys.CATEGORIES_WRITE],
    },
    {
        path: '/shop-categories',
        component: ShopCategoriesPage,
        requiredPermissions: [PermissionKeys.SHOP_CATEGORIES_READ],
    },
    {
        path: '/shop-categories/new',
        component: ShopCategoryEditPage,
        requiredPermissions: [PermissionKeys.SHOP_CATEGORIES_WRITE],
    },
    {
        path: '/shop-categories/:id/edit',
        component: ShopCategoryEditPage,
        requiredPermissions: [PermissionKeys.SHOP_CATEGORIES_WRITE],
    },
    { path: '/suppliers', component: SuppliersPage, requiredPermissions: [PermissionKeys.SUPPLIERS_READ] },
    {
        path: '/suppliers/new',
        component: SupplierEditPage,
        requiredPermissions: [PermissionKeys.SUPPLIERS_WRITE],
    },
    {
        path: '/suppliers/:id/edit',
        component: SupplierEditPage,
        requiredPermissions: [PermissionKeys.SUPPLIERS_WRITE],
    },
    { path: '/settings', component: SettingsPage, requiredPermissions: [PermissionKeys.SETTINGS_WRITE] },
    { path: '/budgets', component: BudgetPage, requiredPermissions: [PermissionKeys.BUDGETS_WRITE] },
];
