import {
    GridFooterContainer,
    gridPageSelector,
    gridPageSizeSelector,
    GridPagination,
    gridRowCountSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { Pagination } from '@mui/material';
import React from 'react';

export function CustomTableFooter() {
    const apiRef = useGridApiContext();
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const page = useGridSelector(apiRef, gridPageSelector);
    const pages = Math.ceil(useGridSelector(apiRef, gridRowCountSelector) / pageSize);

    return (
        <GridFooterContainer sx={{ justifyContent: 'flex-end' }}>
            <GridPagination
                sx={{ '.MuiTablePagination-displayedRows, .MuiTablePagination-actions': { display: 'none' } }}
            />
            <Pagination
                count={pages == 0 ? 1 : pages}
                page={!pages || pages == 0 ? 1 : page + 1}
                onChange={(e, value) => apiRef.current.setPage(value - 1)}
            />
        </GridFooterContainer>
    );
}
