import { TextField } from '@mui/material';
import { ComponentProps, FC, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    externalOnBlur?: (fieldName: string) => void;
    className?: string;
};

export const ControlledInput: FC<Props> = ({
    name,
    externalOnBlur,
    defaultValue,
    inputProps,
    className,
    ...textFieldProps
}) => {
    const { t } = useTranslation();

    const handleBlur = useCallback(
        (internalOnBlur: any) => {
            internalOnBlur();
            externalOnBlur?.(name);
        },
        [externalOnBlur, name],
    );

    return (
        <Controller
            name={name}
            defaultValue={defaultValue || ''}
            render={({ field: { onChange, value, onBlur: internalOnBlur }, fieldState: { error } }) => (
                <TextField
                    className={className}
                    value={value}
                    onChange={onChange}
                    onBlur={() => handleBlur(internalOnBlur)}
                    error={!!error}
                    helperText={error ? error.message || t('shared.form.fallbackError') : null}
                    id={name}
                    inputProps={inputProps}
                    autoComplete="off"
                    {...textFieldProps}
                />
            )}
        />
    );
};
