import React, { FC } from 'react';
import { OnHoldList } from '../on-hold-list/on-hold-list.component';
import { RegisterSessionInformation } from '../register-session-information/register-session-information.component';
import { ScanSupportCase } from '../scan-support-case/scan-support-case.component';
import { FormGrid } from '../../../shared';
import { SolidaritySale } from '../solidarity-sale/solidarity-sale-component';

export const StartNewSale: FC = () => {
    return (
        <>
            <FormGrid
                xs={12}
                md={6}
                justifyContent="center"
                alignItems="flex-start"
                containerProps={{ sx: { maxWidth: 880, mx: 'auto' } }}
            >
                <ScanSupportCase />
                <SolidaritySale />
                <RegisterSessionInformation />
                <OnHoldList />
            </FormGrid>
        </>
    );
};
