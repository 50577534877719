import { MeasurementUnit, productMapper } from '../index';
import { IPurchaseItem } from '../../stock/models';

export const purchaseItemMapper = (item: IPurchaseItem): IPurchaseItem => {
    return {
        ...item,
        unitPurchasePrice:
            item.product.measurementUnit === MeasurementUnit.GRAM
                ? item.unitPurchasePrice * 1000
                : item.unitPurchasePrice,
        product: productMapper(item.product),
    };
};
