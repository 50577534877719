import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, MenuItem, Typography } from '@mui/material';
import {
    AutocompleteProducts,
    ControlledNumberInput,
    ControlledSelect,
    DialogTitleWithClose,
    IOutStockWithReasonMovementForm,
    MeasurementUnit,
    useSelectedOrganisation,
} from '../../../shared';
import { useReasons, useSaveStockMovement } from '../../hooks';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { outStockWithReasonFromFormMapper } from '../../mappers';
import { useOutStockWithReason } from '../../validators';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const OutStockWithReason: FC<Props> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const { mutateAsync: saveStockMovement } = useSaveStockMovement();
    const { data: reasons, isFetching } = useReasons();

    const form = useForm<IOutStockWithReasonMovementForm>({
        mode: 'all',
        resolver: yupResolver(useOutStockWithReason()),
    });
    const product = form.watch('product');
    const isGram = product?.measurementUnit === MeasurementUnit.GRAM;

    useEffect(() => {
        form.reset();
    }, [onClose, form]);

    const onSubmit = useCallback(
        async (item: IOutStockWithReasonMovementForm) => {
            if (organisation?.id) {
                await saveStockMovement({
                    item: outStockWithReasonFromFormMapper(item, product, organisation),
                });
                onClose();
            }
        },
        [onClose, organisation, product, saveStockMovement],
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                    <DialogTitleWithClose onClose={onClose}>{t('outStockWithReason')}</DialogTitleWithClose>
                    <DialogContent>
                        <Controller
                            name="product"
                            control={form.control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                    <Typography sx={{ color: error ? 'error.main' : 'inherit' }}>
                                        {t('product')} <span>*</span>
                                    </Typography>
                                    <AutocompleteProducts
                                        selectedProduct={value}
                                        setSelectedProduct={onChange}
                                        error={error}
                                    />
                                </>
                            )}
                        />

                        <ControlledNumberInput
                            sx={{ mt: 2 }}
                            name="amount"
                            label={isGram ? t('weight') : t('amount')}
                            required
                            decimalScale={product?.measurementUnit === MeasurementUnit.GRAM ? 3 : 0}
                        />

                        <ControlledSelect name="reasonId" label={t('reason')} required>
                            {(reasons || []).map((value) => (
                                <MenuItem value={value.id} key={value.id}>
                                    {value.text}
                                </MenuItem>
                            ))}
                        </ControlledSelect>

                        <Button variant="contained" onClick={form.handleSubmit(onSubmit)} disabled={isFetching}>
                            {t('save')}
                        </Button>
                    </DialogContent>
                </Dialog>
            </form>
        </FormProvider>
    );
};
