import React, { FC } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatAmount, formatPrice, RemoveModal } from '../../../shared';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { IPurchase, IPurchaseItem } from '../../models';
import { useTranslation } from 'react-i18next';
import { useRemovePurchaseItem } from '../../hooks';

interface Props {
    purchase: IPurchase;
    setOpen: (open: boolean) => void;
    setEditPurchaseItem: (editPurchaseItem: IPurchaseItem) => void;
}

export const PurchaseItemsTable: FC<Props> = ({ purchase, setOpen, setEditPurchaseItem }) => {
    const { t } = useTranslation();
    const { mutateAsync: removePurchaseItem } = useRemovePurchaseItem();

    return (
        <TableContainer>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('name')}</TableCell>
                        <TableCell>{t('unitPurchasePrice')}</TableCell>
                        <TableCell>{t('amount')}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {purchase?.purchaseItems.length == 0 ? (
                        <TableRow sx={{ borderBottom: '1px solid lightgrey' }}>
                            <TableCell sx={{ fontStyle: 'italic', borderBottom: 'none' }}>
                                {t('noProductsSelected')}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {purchase?.purchaseItems.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.product?.name}</TableCell>
                                    <TableCell>{formatPrice(row.unitPurchasePrice)}</TableCell>
                                    <TableCell>{formatAmount(row.product.measurementUnit, row.amount, t)}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => {
                                                setEditPurchaseItem(row);
                                                setOpen(true);
                                            }}
                                        >
                                            <EditOutlined />
                                        </IconButton>
                                        <RemoveModal
                                            handleDelete={() => removePurchaseItem(row.id)}
                                            button={
                                                <IconButton>
                                                    <DeleteOutlined />
                                                </IconButton>
                                            }
                                            title={t('removeSaleItem')}
                                            text={t('removeSaleItemWarning')}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
