import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatAmount,
    formatDateTimeString,
    IOutStockReason,
    IProduct,
    Page,
    PermissionKeys,
    RemoveModal,
    SortOrder,
    StockMovementType,
    TableComponent,
    useHasPermission,
    useQueryParams,
    useSelectedOrganisation,
} from '../../../shared';
import { useDeleteStockMovement, useStockMovementList } from '../../hooks';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Button, IconButton } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { OutStockWithReason } from '../../components';

export const StockMovementsPage: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.STOCK_MOVEMENTS_WRITE);
    const [open, setOpen] = useState(false);

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });

    const { mutateAsync: deleteStockMovement } = useDeleteStockMovement();
    const { data: stockMovements, isPending } = useStockMovementList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: string) => formatDateTimeString(value),
        },
        {
            field: 'type',
            headerName: t('type'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: StockMovementType) => t(value),
        },
        {
            field: 'product',
            headerName: t('product'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: IProduct) => value?.name,
        },
        {
            field: 'amount',
            headerName: t('amount'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number, row) => formatAmount(row.product?.measurementUnit, value, t),
        },
        {
            field: 'reason',
            headerName: t('reason'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: IOutStockReason, row) => {
                if (!value) {
                    if (row?.sale) return t('stockMovementSale');
                    if (row?.purchase) return t('stockMovementPurchase');
                } else {
                    return value.text;
                }
            },
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => {
                return (
                    <>
                        {hasWritePermission && row.type === StockMovementType.OUT_STOCK && !!row.reason && (
                            <RemoveModal
                                handleDelete={() => deleteStockMovement(row.id)}
                                button={
                                    <IconButton>
                                        <DeleteOutlined />
                                    </IconButton>
                                }
                                title={t('deleteStockMovementTitle')}
                                text={t('deleteStockMovementText')}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Page
            title={t('stockMovements')}
            actions={[
                <Button onClick={() => setOpen(true)} color="primary" variant="contained">
                    {t('outStockWithReason')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={stockMovements?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={stockMovements?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
            />

            <OutStockWithReason open={open} onClose={() => setOpen(false)} />
        </Page>
    );
};
