import { Home, Inventory, Settings, ShoppingBasket } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Box, Tab, Tabs } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { PermissionKeys, SelectOrganisation, useBreakpoint, useHasPermission } from '../../../shared';
import { Account } from '../account/account.component';
import { AppMenu } from '../app-menu/app-menu.component';

export const Navigation: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const selectedTab = useMemo(() => location.pathname.split('/')[1] || 'shop', [location]);
    const { hasPermission } = useHasPermission();

    const navItems = [
        { label: t('nav.shop'), value: 'shop', to: '/shop', icon: <Home />, hasPermission: true },
        {
            label: t('nav.history'),
            value: 'history',
            to: '/history',
            icon: <Inventory />,
            hasPermission: hasPermission([PermissionKeys.REGISTER_SESSIONS_READ], false),
        },
        {
            label: t('nav.stock'),
            value: 'stock',
            to: '/stock',
            icon: <ShoppingBasket />,
            hasPermission: hasPermission([PermissionKeys.PURCHASES_WRITE, PermissionKeys.STOCK_MOVEMENTS_WRITE], false),
        },
        {
            label: t('nav.admin'),
            value: 'admin',
            to: '/admin',
            icon: <Settings />,
            hasPermission: hasPermission(
                [
                    PermissionKeys.PRODUCTS_WRITE,
                    PermissionKeys.SALE_QUOTA_WRITE,
                    PermissionKeys.CATEGORIES_WRITE,
                    PermissionKeys.SHOP_CATEGORIES_WRITE,
                    PermissionKeys.SUPPLIERS_WRITE,
                    PermissionKeys.SETTINGS_WRITE,
                    PermissionKeys.BUDGETS_WRITE,
                ],
                false,
            ),
        },
    ];

    return useBreakpoint('md') ? (
        <>
            <Tabs indicatorColor="primary" textColor="primary" sx={{ flexGrow: 1 }} value={selectedTab}>
                {navItems.map(
                    ({ label, value, to, hasPermission }) =>
                        hasPermission && <Tab key={value} label={label} value={value} component={Link} to={to} />,
                )}
            </Tabs>
            <SelectOrganisation />
            <Account />
            <AppMenu />
        </>
    ) : (
        <>
            <Box sx={{ flexGrow: 1 }} />
            <SelectOrganisation />
            <Account />
            <AppMenu />
            <BottomNavigation showLabels value={selectedTab}>
                {navItems?.map(
                    ({ label, value, to, icon, hasPermission }) =>
                        hasPermission && (
                            <BottomNavigationAction
                                key={value}
                                label={label}
                                value={value}
                                component={Link}
                                to={to}
                                icon={icon}
                            />
                        ),
                )}
            </BottomNavigation>
        </>
    );
};
