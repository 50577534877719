import { AxiosError } from 'axios';
import { StatusCode } from '../enums';
export function isAxiosError(err: unknown): err is AxiosError {
    return (err as AxiosError).isAxiosError;
}

export function isBadRequest(err: unknown): boolean {
    return isAxiosError(err) && err.response?.status === StatusCode.BAD_REQUEST;
}

export function isNotFound(err: unknown): boolean {
    return isAxiosError(err) && err.response?.status === StatusCode.NOT_FOUND;
}

export function isConflict(err: unknown): boolean {
    return isAxiosError(err) && err.response?.status === StatusCode.CONFLICT;
}
