import { ChevronRight } from '@mui/icons-material';
import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../shared';
import { useGetProviders } from '../../hooks';
import { IProvider } from '../../models';

export const Login: React.FC = () => {
    const { data: providers } = useGetProviders();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pages.login');
    }, [t]);

    const onClickProvider = useCallback((provider: IProvider) => {
        window.location.href = provider.url;
    }, []);

    useEffect(() => {
        if (providers?.length === 1) {
            onClickProvider(providers[0]);
        }
    }, [onClickProvider, providers]);

    return (
        <Paper
            sx={{
                maxWidth: 420,
                width: '100%',
                mt: '75px',
                mx: 'auto',
                textAlign: 'center',
            }}
        >
            <Box sx={{ p: 4 }}>
                <img src={window.carity.environment.theme.logo} alt="Carity" />
                <Typography mt={2} variant="title" display="block">
                    {t('loginTitle')}
                </Typography>
                <Typography component="div">{t('selectLoginMethod')}</Typography>
            </Box>
            {(providers?.length || 0) <= 1 ? (
                <Box pb={3}>
                    <Loading />
                </Box>
            ) : (
                <List>
                    {providers?.map((provider: IProvider) => (
                        <Fragment key={provider.name}>
                            <Divider component="li" />
                            <ListItem>
                                <ListItemButton onClick={() => onClickProvider(provider)}>
                                    <ListItemText primary={provider.name} />
                                    <ChevronRight />
                                </ListItemButton>
                            </ListItem>
                        </Fragment>
                    ))}
                </List>
            )}
        </Paper>
    );
};
