import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { QueryKeys } from '../../shared';
import { settingsClient } from '../clients';
import { ISettings } from '../models';
import { AxiosError } from 'axios';

export function useSettings(organisationId?: string): UseQueryResult<ISettings> {
    return useQuery({
        queryKey: [QueryKeys.SETTINGS, organisationId],
        queryFn: () => settingsClient.getSettings(organisationId),
        enabled: !!organisationId,
    });
}

export function useUpdateSettings(): UseMutationResult<ISettings, AxiosError, ISettings> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (settings: ISettings) => settingsClient.updateSettings(settings),
        onSuccess: async (result: ISettings) =>
            await queryClient.setQueryData([QueryKeys.SETTINGS, result.organisationId], result),
    });
}
