import { Box, Button, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '../../../shared';

interface Props {
    paymentMethod: (arg: PaymentMethod) => void;
}

export const PaymentMethods: FC<Props> = ({ paymentMethod }) => {
    const { t } = useTranslation();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CASH);

    useEffect(() => {
        selectedPaymentMethod && paymentMethod(selectedPaymentMethod);
    }, [paymentMethod, selectedPaymentMethod]);

    return (
        <Box>
            <Typography variant="subtitle2">{t('checkoutMethod')}</Typography>
            <Stack direction="row" spacing={2}>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => setSelectedPaymentMethod(PaymentMethod.CASH)}
                    sx={{
                        backgroundColor: selectedPaymentMethod === PaymentMethod.CASH ? 'primary' : 'grey',
                    }}
                >
                    {t('cash')}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => setSelectedPaymentMethod(PaymentMethod.DIGITAL)}
                    sx={{
                        backgroundColor: selectedPaymentMethod === PaymentMethod.DIGITAL ? 'primary' : 'grey',
                    }}
                >
                    {t('digital')}
                </Button>
            </Stack>
        </Box>
    );
};
