import { Dialog } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, ISale, QueryKeys, SaleStatus } from '../../../shared';
import { ISavePayment, ISupportCaseInfo } from '../../models';
import { CashAndDigitalPayment } from '../cash-and-digital-payment/cash-and-digital-payment.component';
import { CreditPayment } from '../credit-payment/credit-payment.component';
import { PaymentSuccessful } from '../payment-successful/payment-successful.component';

enum screenType {
    credit = 'credit',
    cash_digital = 'cash/digital',
}
interface Props {
    supportCase?: ISupportCaseInfo;
    open: boolean;
    onClose: () => void;
    currentSale: ISale;
    onCompleteSale: () => void;
}

export const CompleteCurrentSale: FC<Props> = ({ supportCase, open, onClose, currentSale, onCompleteSale }) => {
    const { t } = useTranslation();
    const [creditPayment, setCreditPayment] = useState<ISavePayment | null>();
    const [paymentScreen, setPaymentScreen] = useState<screenType>();

    const creditSupportTypesInfo = useMemo(
        () =>
            supportCase?.activeSupportPeriods
                .flatMap(({ supportTypesInfo }) => supportTypesInfo)
                .filter(({ credit, spendCredit }) => (credit || 0) > (spendCredit || 0)) || [],
        [supportCase],
    );

    useEffect(() => {
        if (creditSupportTypesInfo.length) {
            setPaymentScreen(screenType.credit);
        } else {
            setPaymentScreen(screenType.cash_digital);
        }
    }, [creditSupportTypesInfo]);

    const onHandleSetCredit = (creditPayment: ISavePayment | null) => {
        setCreditPayment(creditPayment);
        setPaymentScreen(screenType.cash_digital);
    };

    const queryClient = useQueryClient();
    const onClosePaymentSuccessful = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.CURRENT_SALE, currentSale.organisationId] });
        onCompleteSale();
        await onClose();
    }, [queryClient, onCompleteSale, onClose, currentSale]);

    return (
        <>
            <Dialog open={open && currentSale.status === SaleStatus.CREATED} fullWidth maxWidth="lg" onClose={onClose}>
                <DialogTitleWithClose onClose={onClose}>{t('checkoutPayment')}</DialogTitleWithClose>
                {paymentScreen === screenType.credit && (
                    <CreditPayment
                        creditPayment={creditPayment}
                        setCreditPayment={onHandleSetCredit}
                        handleCancel={onClose}
                        supportTypesInfo={creditSupportTypesInfo}
                        totalPrice={currentSale.totalPrice}
                    />
                )}
                {paymentScreen === screenType.cash_digital && (
                    <CashAndDigitalPayment
                        payment={creditPayment}
                        currentSale={currentSale}
                        onClose={onClose}
                        hasCreditPayment={creditSupportTypesInfo.length > 0}
                        handlePrevious={() => setPaymentScreen(screenType.credit)}
                    />
                )}
            </Dialog>

            <PaymentSuccessful
                currentSale={currentSale}
                open={open && currentSale.status === SaleStatus.COMPLETED}
                onClose={onClosePaymentSuccessful}
            />
        </>
    );
};
