import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Page } from '../../../shared';
import { PurchaseDataPage } from '../purchase-data/purchase-data.page';
import { PurchaseItemsPage } from '../purchase-items/purchase-items.page';

export const PurchasesWizardPage: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const activeStep = useMemo(() => {
        const activeStepPath = location.pathname.split('/').pop();
        return Math.max(['', 'items'].indexOf(`${activeStepPath}`), 0);
    }, [location]);

    return (
        <Page>
            <Box sx={{ p: 3, pt: 0 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                        <StepLabel>{t('invoiceData')}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{t('items')}</StepLabel>
                    </Step>
                </Stepper>
            </Box>
            <Routes>
                <Route path="/" element={<PurchaseDataPage />} />
                <Route path="/items" element={<PurchaseItemsPage />} />
            </Routes>
        </Page>
    );
};
