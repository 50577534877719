import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, number, object, string } from 'yup';

export function usePriceProduct() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                productName: string().optional(),
                productPrice: number().typeError(t('invalidNumber')).min(0, t('negativeNumber')).required(),
                tax: number().typeError(t('invalidNumber')).min(0, t('negativeNumber')).required(),
                amount: number()
                    .integer(t('invalidInteger'))
                    .typeError(t('invalidNumber'))
                    .min(1, t('negativeNumber'))
                    .required(),
                excludeFromBudget: boolean().required(),
            }),
        [t],
    );
}
