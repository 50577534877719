import { Box, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { useTheme } from '../../../main';

interface Props {
    label: ReactElement | string;
    value?: ReactElement | string;
    sx?: SxProps<Theme>;
}

export const LabelValue: FC<Props> = ({ label, value, sx }) => {
    const theme = useTheme();

    return (
        <Stack sx={{ mb: 1, ...sx }}>
            <Typography variant="description">{label}</Typography>
            {value ? (
                <>
                    {value instanceof String ? (
                        <Typography variant="caption">{value || '-'}</Typography>
                    ) : (
                        <Box
                            sx={{
                                fontSize: theme.typography.caption.fontSize,
                                fontFamily: theme.typography.caption.fontFamily,
                            }}
                        >
                            {value}
                        </Box>
                    )}
                </>
            ) : (
                <Typography>{'-'}</Typography>
            )}
        </Stack>
    );
};
