import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, mixed, number, object, string } from 'yup';
import { BudgetPeriod, IBudgetRule } from '../../shared';

export function useBudgetSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                period: mixed<BudgetPeriod>().oneOf(Object.values(BudgetPeriod), t('requiredField')).required(),
                rules: array()
                    .of(
                        object<IBudgetRule>()
                            .shape({
                                id: string().optional(),
                                amount: number().required().optional().typeError(t('invalidNumber')),
                                amountOfPeople: number().required().optional().typeError(t('invalidNumber')),
                            })
                            .required(),
                    )
                    .required(),
            }),
        [t],
    );
}
