import { Box, Link, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const LogoutPage: FC = () => {
    const { t } = useTranslation();

    return (
        <Paper
            sx={{
                maxWidth: 420,
                width: '100%',
                mt: 8,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
                pb: 4,
            }}
        >
            <Box sx={{ p: 4 }}>
                <img src={window.carity.environment.theme.logo} alt="Carity" />
                <Typography sx={{ mt: 2 }} variant="h5">
                    {t('logoutTitle')}
                </Typography>
                <Link href="/">{t('goToLogin')}</Link>
            </Box>
        </Paper>
    );
};
