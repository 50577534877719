import React, { FC, useMemo } from 'react';
import {
    ControlledCheckbox,
    ControlledNumberInput,
    formatPrice,
    FormGrid,
    IProduct,
    MeasurementUnit,
} from '../../../../../shared';
import { InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

interface Props {
    product?: IProduct;
}

export const PurchaseItemInputs: FC<Props> = ({ product }) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const watch = form.watch();
    const isGram = product
        ? product?.measurementUnit === MeasurementUnit.GRAM
        : watch.newProduct?.measurementUnit === MeasurementUnit.GRAM;

    const totalPrice = useMemo(
        () => (watch.unitPurchasePrice && watch.amount ? formatPrice(watch.unitPurchasePrice * watch.amount) : ''),
        [watch.unitPurchasePrice, watch.amount],
    );

    return (
        <FormGrid xs={12} md={4} sx={{ '.MuiFormControl-root': { mb: 0 }, mb: 2 }}>
            <>
                <ControlledNumberInput
                    name="unitPurchasePrice"
                    label={
                        !watch.newProduct?.measurementUnit && !product
                            ? t('unitPurchasePrice')
                            : isGram
                              ? t('unitPurchasePriceWeight')
                              : t('unitPurchasePricePiece')
                    }
                    InputProps={{ startAdornment: <InputAdornment position="start" children={'€'} /> }}
                    required
                />
                <ControlledCheckbox
                    name="taxIncluded"
                    label={t('taxIncluded', { amount: product?.tax || watch.newProduct?.tax })}
                    control={form.control}
                />
            </>
            <ControlledNumberInput
                name="amount"
                label={isGram ? t('weight') : t('amount')}
                decimalScale={isGram ? 3 : 0}
                required
            />
            <TextField label={t('totalPurchasePrice')} value={totalPrice} disabled />
        </FormGrid>
    );
};
