import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    formatPrice,
    ICash,
    PermissionKeys,
    useHasPermission,
    useSelectedOrganisation,
    useStartRegistrationSession,
} from '../../../shared';
import { calculateCashTotal, getDefaultCashInputs } from '../../utils';
import { useRegisterSession } from '../../validators';
import { RegisterSessionInputs } from '../register-session-inputs/register-session-inputs.component';

export const StartRegisterSession: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<keyof ICash>('amount100Euro');
    const [total, setTotal] = useState(0);

    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.REGISTER_SESSIONS_WRITE);

    const { mutateAsync: startRegisterSession } = useStartRegistrationSession();
    const form = useForm<ICash>({
        resolver: yupResolver(useRegisterSession()),
        mode: 'onSubmit',
        defaultValues: getDefaultCashInputs(),
    });

    const handleSubmit = async (cash: ICash) => {
        if (organisation) {
            await startRegisterSession({
                organisationId: organisation.id,
                cashAtStart: cash,
                totalCashAtStart: calculateCashTotal(cash),
            });
            setOpen(false);
        }
    };

    const values = form.watch();
    useEffect(() => {
        setTotal(calculateCashTotal(values));
    }, [values]);

    return (
        <Stack
            direction="column"
            sx={{ justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 164px)' }}
        >
            <Button
                variant="contained"
                sx={{ minHeight: '15%', minWidth: '35%' }}
                onClick={() => setOpen(true)}
                disabled={!hasWritePermission}
            >
                <Typography variant="title" m={4}>
                    {t('startRegisterSession')}
                </Typography>
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
                <FormProvider {...form}>
                    <form
                        onSubmit={form.handleSubmit(handleSubmit)}
                        onSelect={(event) => setSelected((event.target as HTMLInputElement).id as keyof ICash)}
                    >
                        <DialogTitleWithClose onClose={() => setOpen(false)}>
                            {t('countCashRegisterSession')}
                        </DialogTitleWithClose>
                        <RegisterSessionInputs selected={selected} />
                        <DialogContent sx={{ backgroundColor: 'background.default', py: 0 }}>
                            <Box sx={{ my: 2 }}>
                                <Typography variant="subtitle1">
                                    {t('totalInRegister')}: {formatPrice(total)}
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" fullWidth variant="contained">
                                {t('start')}
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </Stack>
    );
};
