import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useBudget } from '../../../admin/hooks';
import { formatPrice, LabelValue, useSelectedOrganisation, useShop } from '../../../shared';
import { useCreateManualSaleItem, useCreateSaleItem, useUpdateSaleItem } from '../../hooks';

export const BudgetExceeded: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: budget } = useBudget(organisation?.id);
    const { budgetExceeded, setBudgetExceeded } = useShop();
    const { mutateAsync: createSaleItem } = useCreateSaleItem();
    const { mutateAsync: updateSaleItem } = useUpdateSaleItem();
    const { mutateAsync: createManualSaleItem } = useCreateManualSaleItem();
    const allowedOverwrite = window.carity.environment.allowOverwriteCostumerBudget;

    const onConfirm = () => {
        if (budgetExceeded?.createSaleItem) {
            createSaleItem({ ...budgetExceeded?.createSaleItem, ignoreBudgetRules: true });
        } else if (budgetExceeded?.updateSaleItem?.saleItemId) {
            updateSaleItem({
                saleItemId: budgetExceeded.updateSaleItem.saleItemId,
                saleItem: { ...budgetExceeded?.updateSaleItem, ignoreBudgetRules: true },
            });
        } else if (budgetExceeded?.manualSaleItem) {
            createManualSaleItem({ ...budgetExceeded.manualSaleItem, ignoreBudgetRules: true });
        }
        setBudgetExceeded(undefined);
    };

    return (
        <Dialog open={!!budgetExceeded}>
            <DialogTitle>{budget?.period ? t('budgetExceededTitle', { period: t(budget.period) }) : ''}</DialogTitle>
            {budgetExceeded && (
                <DialogContent>
                    <Stack direction="row" spacing={2} textAlign="center">
                        <LabelValue label={t('allowedBudget')} value={formatPrice(budgetExceeded.rule.amount)} />
                        <LabelValue label={t('spentBudget')} value={formatPrice(budgetExceeded.spentBudget)} />
                    </Stack>
                    {allowedOverwrite && <Typography mt={2}>{t('budgetExceededConfirm')}</Typography>}
                </DialogContent>
            )}

            <DialogActions>
                <Stack direction="row" spacing={2}>
                    {allowedOverwrite && (
                        <Button variant="contained" onClick={onConfirm}>
                            {t('shared.confirm')}
                        </Button>
                    )}
                    <Button variant="outlined" onClick={() => setBudgetExceeded(undefined)}>
                        {t(allowedOverwrite ? 'shared.cancel' : 'ok')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
