import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    ControlledDatePicker,
    ControlledInput,
    ControlledSelect,
    FormGrid,
    IParams,
    useSelectedOrganisation,
    useSuppliersList,
} from '../../../shared';
import { Button, Card, CardActions, CardContent, ListItemText, MenuItem, Typography } from '@mui/material';
import { usePurchase, useSavePurchase } from '../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { IPurchaseForm } from '../../models';
import { usePurchaseSchema } from '../../validators';
import { purchaseFromFormMapper, purchaseToFormMapper } from '../../mappers';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export const PurchaseDataPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();

    const { data: purchase } = usePurchase(id);
    const { data: suppliers } = useSuppliersList({
        pageSize: 1000,
        organisationId: organisation?.id,
    });
    const { mutateAsync: savePurchase, isPending: isSaving } = useSavePurchase();

    const form = useForm<IPurchaseForm>({
        mode: 'all',
        resolver: yupResolver(usePurchaseSchema()),
    });

    useEffect(() => {
        if (purchase) {
            form.reset(purchaseToFormMapper(purchase));
        }
    }, [purchase, form]);

    const watchGift = form.watch('gift');

    const onSubmit = async (item: IPurchaseForm) => {
        if (organisation?.id) {
            const result = await savePurchase({ id, item: purchaseFromFormMapper(item, organisation.id) });
            navigate(`/stock/purchases/${result.id}/items`);
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="subtitle1" pt={2}>
                            {t('invoiceData')}
                        </Typography>
                        <ControlledCheckbox name={`gift`} label={t('isGift')} control={form.control} />
                        <FormGrid xs={6} md={4}>
                            <ControlledDatePicker name={`date`} label={t('date')} required />
                        </FormGrid>
                        <FormGrid xs={6} md={4}>
                            <ControlledInput name={`invoiceNumber`} label={t('invoiceNumber')} required={!watchGift} />
                            <ControlledSelect name={`supplierId`} label={t('supplier')}>
                                <MenuItem>
                                    <ListItemText sx={{ minHeight: '20px' }} />
                                </MenuItem>
                                {suppliers?.data.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </FormGrid>
                    </CardContent>

                    <CardActions sx={{ backgroundColor: 'background.default' }}>
                        <FormGrid containerProps={{ justifyContent: 'space-between' }}>
                            <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                {t('cancel')}
                            </Button>
                            <Button
                                endIcon={<ChevronRight />}
                                variant="contained"
                                color="primary"
                                onClick={form.handleSubmit(onSubmit)}
                                disabled={isSaving}
                            >
                                {t('shared.next')}
                            </Button>
                        </FormGrid>
                    </CardActions>
                </Card>
            </form>
        </FormProvider>
    );
};
