import { ICash } from '../../shared';

const cashAmount = {
    amount100Euro: 100,
    amount50Euro: 50,
    amount20Euro: 20,
    amount10Euro: 10,
    amount5Euro: 5,
    amount2Euro: 2,
    amount1Euro: 1,
    amount50Cents: 0.5,
    amount20Cents: 0.2,
    amount10Cents: 0.1,
    amount5Cents: 0.05,
    amount2Cents: 0.02,
    amount1Cents: 0.01,
};

export function calculateCashTotal(cashInput: ICash): number {
    return Object.entries(cashInput).reduce((counted, [name, amount]) => {
        return counted + amount * cashAmount[name as keyof ICash];
    }, 0);
}

export function getCashAmounts(cashInput: ICash | undefined): [string, number, number][] {
    return cashInput
        ? Object.entries(cashInput)
              .reverse()
              .map(([name, amount]) => {
                  return [name, amount, amount * cashAmount[name as keyof ICash]];
              }, 0)
        : [];
}
