import React, { FC } from 'react';
import { FormGrid } from '../form-grid/form-grid.component';
import { ControlledInput } from '../controlled-input/controlled-input.component';
import { ControlledAutocomplete } from '../controlled-autocomplete/controlled-autocomplete.component';
import { ControlledSelect } from '../controlled-select/controlled-select.component';
import { ICategory } from '../../models';
import { IconButton, MenuItem, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { ProductTaxes } from '../../../admin/enums';
import { MeasurementUnit } from '../../enums';
import { Section } from '../section/section.component';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

interface Props {
    prefixName: string;
    categories: ICategory[];
}

export const ProductDetailInputs: FC<Props> = ({ prefixName, categories }) => {
    const { t } = useTranslation();
    const form = useFormContext();

    const onChangeCategory = (event: SelectChangeEvent<unknown>) => {
        const tax = categories?.find((cat) => cat.id === event.target.value)?.tax;
        if (tax !== undefined && tax !== null) {
            form.setValue(`${prefixName}tax`, tax.toString() as ProductTaxes);
        } else {
            form.setValue(`${prefixName}tax`, '');
        }
    };

    return (
        <Section title={t('productDetails')}>
            <FormGrid xs={12} md={6}>
                <ControlledInput name={`${prefixName}name`} label={t('productName')} required />

                <ControlledAutocomplete
                    name={`${prefixName}barcodes`}
                    label={t('barcodes')}
                    placeholder={t('barcodesLabel')}
                    defaultValue={[]}
                    options={[]}
                    multiple
                    freeSolo
                />

                <ControlledSelect name={`${prefixName}categoryId`} label={t('category')} onChange={onChangeCategory}>
                    {categories.map((category: ICategory) => (
                        <MenuItem value={category.id} key={category.id}>
                            {category.name}
                        </MenuItem>
                    ))}
                </ControlledSelect>

                <ControlledSelect
                    name={`${prefixName}tax`}
                    label={
                        <>
                            {t('productTax')}
                            <Tooltip
                                title={
                                    <Typography color="inherit" sx={{ fontSize: '13px' }}>
                                        {t('productTaxInfo')}
                                    </Typography>
                                }
                                placement="right"
                                arrow
                            >
                                <IconButton sx={{ px: 1, py: 0 }}>
                                    <InfoOutlined sx={{ fontSize: '18px', color: 'primary.main' }} />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    minWidth={100}
                    required
                >
                    {Object.values(ProductTaxes)?.map((value) => (
                        <MenuItem value={value} key={value}>
                            {value}
                        </MenuItem>
                    ))}
                </ControlledSelect>

                <ControlledSelect label={t('measurementUnit')} name={`${prefixName}measurementUnit`} required>
                    <MenuItem value={MeasurementUnit.GRAM}>{t('measurementWeight')}</MenuItem>
                    <MenuItem value={MeasurementUnit.PIECE}>{t(MeasurementUnit.PIECE)}</MenuItem>
                </ControlledSelect>
            </FormGrid>
        </Section>
    );
};
