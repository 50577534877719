import { FormControl, FormHelperText, InputLabel, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { ComponentProps, FC, ReactNode } from 'react';
import { Controller } from 'react-hook-form';

type Props = ComponentProps<typeof Select> & {
    name: string;
    label?: string | ReactNode;
    minWidth?: number;
    items?: string[];
    className?: string;
};

export const ControlledSelect: FC<Props> = ({
    name,
    required,
    label,
    children,
    minWidth = 195,
    items = [],
    className,
    ...selectProps
}) => {
    const handleOnChangeSelect = (e: SelectChangeEvent<any>, c: ReactNode, setValue: (event: any) => void) => {
        if (selectProps.multiple) {
            if (e.target.value.includes('all')) {
                if (e.target.value.length === items.length + 1) {
                    e.target.value = [];
                } else {
                    e.target.value = items;
                }
            }
        } else {
            if (selectProps.onChange) {
                selectProps.onChange(e, c);
            }
        }

        setValue(e);
        return e;
    };
    return (
        <Controller
            name={name}
            defaultValue={selectProps.multiple ? [] : ''}
            render={({ field: { onChange: setValue, value }, fieldState: { error } }) => (
                <FormControl sx={{ minWidth }} error={!!error} className={className}>
                    {label && (
                        <InputLabel id={name} required={required}>
                            {label}
                        </InputLabel>
                    )}
                    <Select
                        sx={{ minWidth }}
                        value={value}
                        id={`${name}-select`}
                        required={required}
                        input={<OutlinedInput notched label={label} />}
                        {...selectProps}
                        onChange={(e, c) => handleOnChangeSelect(e, c, setValue)}
                    >
                        {children}
                    </Select>
                    {error && (
                        <FormHelperText id={`${name}-field-helper-text`}>{error ? error.message : null}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};
