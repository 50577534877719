import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CarityTheme, Main, Statusbar } from './main';
import './translations/i18n';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export function App() {
    return (
        <CarityTheme>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <QueryClientProvider client={queryClient}>
                    <Main />
                    <Statusbar />
                </QueryClientProvider>
            </LocalizationProvider>
        </CarityTheme>
    );
}
