import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { FC, useCallback } from 'react';
import { useSelectedOrganisation } from '../../contexts';

export const SelectOrganisation: FC = () => {
    const { organisation, organisations, setOrganisation } = useSelectedOrganisation();

    const handleChange = useCallback(
        (e: any) => setOrganisation(organisations.find(({ id }) => id === e.target.value)),
        [setOrganisation, organisations],
    );

    return organisations?.length > 1 ? (
        <FormControl
            variant="filled"
            sx={{
                width: 250,
                mb: 0,
                mr: 2,
            }}
        >
            <Box
                sx={{
                    height: '55px',
                    border: 'none !important',
                    color: 'grey',
                    '& .MuiSvgIcon-root': {
                        color: 'grey',
                    },
                    '#select-organisation': { height: 'auto', p: '20px', background: 'none' },
                    '&::before': {
                        border: 'none !important',
                    },
                }}
                component={Select}
                value={organisation?.id || ''}
                onChange={handleChange}
                id={'select-organisation'}
            >
                {organisations.map(({ name, id }) => (
                    <MenuItem key={id} value={id}>
                        {name}
                    </MenuItem>
                ))}
            </Box>
        </FormControl>
    ) : null;
};
