import { TFunction } from 'i18next';
import { MeasurementUnit } from '../enums';

export const formatToFixed = (amount: number | string) => {
    return typeof amount !== 'number'
        ? parseFloat(amount).toFixed(2).replace('.', ',')
        : amount.toFixed(2).replace('.', ',');
};

export const formatAmount = (measurementUnit: MeasurementUnit, amount: number, t: TFunction) => {
    return measurementUnit === MeasurementUnit.GRAM
        ? t('displayGram', {
              amount: (amount / 1000).toString().replace('.', ','),
          })
        : t('displayPiece', { count: amount });
};

export const formatPrice = (price: number | undefined | null) => {
    return `€ ${price ? formatToFixed(price) : 0}`;
};

export const formatPercentage = (percentage: number | string | undefined) => {
    return `${percentage ? Number(percentage).toFixed(2).replace('.', ',') : 0} %`;
};

export const formatProductPrice = (measurementUnit: MeasurementUnit, price: number, t: TFunction) => {
    return measurementUnit === MeasurementUnit.GRAM
        ? t('pricePerKg', { price: formatToFixed(price) })
        : t('pricePerPiece', { price: formatToFixed(price) });
};
