import { ISaleQuotum, PermissionKeys, useHasPermission, Widget } from '../../../shared';
import { ListAltTwoTone } from '@mui/icons-material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
    saleQuotum: ISaleQuotum;
}

export const ProductRules: FC<Props> = ({ saleQuotum }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SALE_QUOTA_WRITE);

    const columns: GridColDef[] = [
        {
            field: 'amountOfPeople',
            headerName: t('amountOfPeople'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'amount',
            headerName: t('saleQuotumAllowedAmount'),
            flex: 1,
            sortable: false,
        },
    ];

    return (
        <Widget
            hasTable
            title={t('saleQuotumRules')}
            actions={
                hasWritePermission && (
                    <Link
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/admin/sale-quota/${saleQuotum.id}/edit`);
                        }}
                    >
                        <Typography variant="caption" fontWeight="bold">
                            {t('updateSaleQuotum')}
                        </Typography>
                    </Link>
                )
            }
            icon={<ListAltTwoTone color="primary" />}
        >
            <DataGrid
                rows={saleQuotum.rules || []}
                columns={columns}
                sx={{ '.MuiDataGrid-cell': { px: 2 } }}
                hideFooter
                autoHeight
            />
        </Widget>
    );
};
