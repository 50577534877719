import React, { FC } from 'react';
import { formatDateTimeString, formatPrice, FormGrid, ISale, LabelValue, SaleStatus } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

interface Props {
    sale: ISale;
}

export const SaleDetailInformation: FC<Props> = ({ sale }) => {
    const { t } = useTranslation();

    return (
        <FormGrid xs={12} sm={6}>
            <Stack direction="column">
                <LabelValue label={t('status')} value={t(`${sale.status}`)} />
                <LabelValue label={t('totalPrice')} value={formatPrice(sale.totalPrice)} />
            </Stack>
            <Stack direction="column">
                <LabelValue
                    label={t('saleEndDate')}
                    value={sale.status === SaleStatus.COMPLETED ? formatDateTimeString(sale?.updatedDate) : '-'}
                />
                <LabelValue
                    label={t('saleEndedBy')}
                    value={sale.status === SaleStatus.COMPLETED ? sale?.updatedBy : '-'}
                />
            </Stack>
        </FormGrid>
    );
};
