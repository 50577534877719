import { IRegisterSession, IStartRegisterSession, IUpdateRegisterSession } from '../models';
import { BaseClient } from './base.client';
import { shopApi } from './shop-api.client';

class RegisterSessionClient extends BaseClient<IRegisterSession, IStartRegisterSession | IUpdateRegisterSession> {
    constructor() {
        super(shopApi, '/register-sessions');
    }
    public async getCurrentRegisterSession(organisationId?: string): Promise<IRegisterSession> {
        const { data } = await shopApi.get<IRegisterSession>(`/register-sessions/current`, {
            params: { organisationId },
        });
        return data;
    }

    public async stopRegisterSession(id: string): Promise<IRegisterSession> {
        const { data } = await shopApi.put<IRegisterSession>(`/register-sessions/${id}/complete`);
        return data;
    }
}

export const registerSessionClient = new RegisterSessionClient();
