import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { DialogTitleWithClose } from '../dialog-title-with-close/dialog-title-with-close.component';
import { useTranslation } from 'react-i18next';

interface Props {
    showWarning: boolean;
    setShowWarning: (showWarning: boolean) => void;
    onSubmit: () => void;
}

export const BarcodeWarning: FC<Props> = ({ showWarning, setShowWarning, onSubmit }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={showWarning} onClose={() => setShowWarning(false)}>
            <DialogTitleWithClose onClose={() => setShowWarning(false)}>{t('noBarcodeTitle')}</DialogTitleWithClose>
            <DialogContent>{t('noBarcodeText')}</DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={onSubmit}>
                        {t('shared.save')}
                    </Button>
                    <Button variant="outlined" onClick={() => setShowWarning(false)}>
                        {t('shared.cancel')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
