export default {
    getProvidersError: 'Er is een probleem opgetreden bij het ophalen van de providers.',
    getUserError: 'Er is een probleem opgetreden bij het ophalen van de gebruiker.',
    selectLoginMethod: 'Selecteer hieronder je login methode.',
    loginTitle: 'Welkom terug!',
    logIn: 'Inloggen',
    logOut: 'Uitloggen',
    logoutTitle: 'Afmelden succesvol',
    goToLogin: 'Log terug in',
    notFoundTitle: 'Pagina niet gevonden',
    notFoundText: 'Deze pagina is niet gevonden of is momenteel niet beschikbaar.',
    forbiddenTitle: 'Geen toegang',
    forbiddenText: 'U heeft geen toegang tot deze pagina',
    backButton: 'Ga terug naar vorige pagina',
    getUsersError: 'Er is een probleem opgetreden bij het ophalen van de gebruikers.',
    getManagersError: 'Er is een probleem opgetreden bij het ophalen van de beheerders.',
    getUserRolesError: 'Er is een probleem opgetreden bij het ophalen van de rollen van de gebruiker.',
    unauthenticatedTitle: 'Geen toegang',
    unauthenticatedText: 'Je gebruiker heeft momenteel geen toegang tot deze applicatie.',
    loginAgain: 'Opnieuw inloggen',
};
