export default {
    sales: 'Verkopen',
    cardCode: 'Klantenkaart',
    totalPrice: 'Totaal bedrag',
    CREATED: 'Aangemaakt',
    COMPLETED: 'Voltooid',
    ON_HOLD: 'In wacht',
    customerType: 'Klanttype',
    socialCustomer: 'Sociale klant',
    solidarityCustomer: 'Solidaire klant',
    registerSessions: 'Kassa sessies',
    startedDate: 'Startdatum',
    stoppedDate: 'Einddatum',
    startedBy: 'Kassaverantwoordelijke start',
    stoppedBy: 'Kassaverantwoordelijke stop',
    totalCashAtStart: 'In kassa bij start',
    totalCashAtStop: 'In kassa bij stop',
    totalEarnings: 'Totale verkopen',
    expectedTotalCashAtStop: 'Verwacht in kassa bij stop',

    saleDetails: 'Verkoop details',
    supportCaseInfo: 'Klant informatie',
    saleInfo: 'Verkoop informatie',
    saleItems: 'Artikelen',
    payments: 'Betalingen',
    customerCode: 'Klantenkaart',
    saleEndDate: 'Afgerond op',
    saleStartedBy: 'Aangemaakt door',
    saleEndedBy: 'Afgerond door',
    CASH: 'Contant',
    DIGITAL: 'Digitaal',
    CREDIT: 'Tegoed',
    paymentMethod: 'Betaalmethode',
    receivedAmount: 'Ontvangen',
    returnedAmount: 'Teruggegeven',
    paidAmount: 'Betaald',

    registerSessionDetails: 'Kassa sessie details',
    registerSessionInformation: 'Kassa sessie informatie',
    registerSessionAmounts: 'Kassa sessie bedragen',
    cashInRegisterAtStart: 'In kassa bij osptarten',
    cashInRegisterAtStop: 'In kassa bij afsluiten',
    totalCashIncome: 'Totaal contante verkopen',
    totalDigitalIncome: 'Totaal digitale verkopen',
    differenceInExpectedCash: 'Kassa verschil',
    cashName: 'Bedrag',
    cashAmount: 'Aantal eenheden',
    cashTotal: 'Totaal bedrag per eenheid',
    amount100Euro: 'Briefjes van €100',
    amount50Euro: 'Briefjes van €50',
    amount20Euro: 'Briefjes van €20',
    amount10Euro: 'Briefjes van €10',
    amount5Euro: 'Briefjes van €5',
    amount2Euro: 'Munten van €2',
    amount1Euro: 'Munten van €1',
    amount50Cents: 'Munten van €0,5',
    amount20Cents: 'Munten van €0,2',
    amount10Cents: 'Munten van €0,1',
    amount5Cents: 'Munten van €0,05',
    amount2Cents: 'Munten van €0,02',
    amount1Cents: 'Munten van €0,01',
};
