import { IProduct, MeasurementUnit, productFromFormMapper } from '../../shared';
import {
    ICreatePurchaseItem,
    INewProductPurchaseItemForm,
    IPurchase,
    IPurchaseItem,
    IPurchaseItemForm,
} from '../models';

export const purchaseItemToFormMapper = (purchaseItem: IPurchaseItem): IPurchaseItemForm => {
    return {
        ...purchaseItem,
        taxIncluded: true,
        amount:
            purchaseItem.product.measurementUnit === MeasurementUnit.GRAM
                ? purchaseItem.amount / 1000
                : purchaseItem.amount,
        updateProductPrice: true,
        newProductPrice: undefined,
    };
};

export const purchaseItemFromFormMapper = (
    purchaseId: string,
    purchaseItem: IPurchaseItemForm,
    product: IProduct,
): ICreatePurchaseItem => {
    const isGram = product?.measurementUnit === MeasurementUnit.GRAM;
    return {
        purchaseId,
        ...purchaseItem,
        productId: product?.id || '',
        unitPurchasePrice: isGram ? purchaseItem.unitPurchasePrice / 1000 : purchaseItem.unitPurchasePrice,
        amount: isGram ? purchaseItem.amount * 1000 : parseFloat(purchaseItem.amount.toFixed(0)),
        newProductPrice:
            purchaseItem.newProductPrice && isGram ? purchaseItem.newProductPrice / 1000 : purchaseItem.newProductPrice,
        newSolidarityPrice:
            purchaseItem.newSolidarityPrice && isGram
                ? purchaseItem.newSolidarityPrice / 1000
                : purchaseItem.newSolidarityPrice,
    };
};

export const purchaseNewProductItemFromFormMapper = (
    purchase: IPurchase,
    item: INewProductPurchaseItemForm,
): ICreatePurchaseItem => {
    const isGram = item.newProduct?.measurementUnit === MeasurementUnit.GRAM;
    return {
        purchaseId: purchase.id,
        ...item,
        unitPurchasePrice: isGram ? item.unitPurchasePrice / 1000 : item.unitPurchasePrice,
        amount: isGram ? item.amount * 1000 : parseFloat(item.amount.toFixed(0)),
        newProduct: productFromFormMapper(item.newProduct, purchase.organisationId),
    };
};
