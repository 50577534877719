import { BaseClient, purchaseItemMapper, shopApi } from '../../shared';
import { ICreatePurchaseItem, IPurchase, ISavePurchase, IUpdatePurchaseItem } from '../models';

class PurchaseClient extends BaseClient<IPurchase, ISavePurchase> {
    constructor() {
        super(shopApi, '/purchases');
    }

    public async getOne(id?: string): Promise<IPurchase> {
        const { data } = await shopApi.get<IPurchase>(`/purchases/${id}`);
        return {
            ...data,
            purchaseItems: data.purchaseItems?.map((item) => purchaseItemMapper(item)),
        };
    }

    public async createPurchaseItem(purchaseItem: ICreatePurchaseItem): Promise<IPurchase> {
        const { data } = await shopApi.post<IPurchase>('/purchases/items', purchaseItem);
        return { ...data, purchaseItems: data.purchaseItems?.map((item) => purchaseItemMapper(item)) };
    }

    public async updatePurchaseItem(id: string, purchaseItem: IUpdatePurchaseItem): Promise<IPurchase> {
        const { data } = await shopApi.put<IPurchase>(`/purchases/items/${id}`, purchaseItem);
        return { ...data, purchaseItems: data.purchaseItems?.map((item) => purchaseItemMapper(item)) };
    }

    public async removePurchaseItem(purchaseItemId: string): Promise<IPurchase> {
        const { data } = await shopApi.delete(`/purchases/items/${purchaseItemId}`);
        return data;
    }

    public async removePurchase(id: string): Promise<IPurchase> {
        const { data } = await shopApi.delete(`/purchases/${id}`);
        return data;
    }

    public async completePurchase(id: string): Promise<IPurchase> {
        const { data } = await shopApi.put<IPurchase>(`/purchases/${id}/complete`);
        return data;
    }
}

export const purchaseClient = new PurchaseClient();
