import {
    ICreateSaleItem,
    ISale,
    ISaveManualSaleItem,
    IUpdateSale,
    IUpdateSaleItem,
    ReadOnlyClient,
    shopApi,
} from '../../shared';
import { ICompleteSale } from '../models';

class SalesClient extends ReadOnlyClient<ISale> {
    constructor() {
        super(shopApi, '/sales');
    }
    public async createSaleItem(saleItem: ICreateSaleItem): Promise<ISale> {
        const { data } = await shopApi.post<ISale>('/sales/items', saleItem);
        return data;
    }

    public async createManualSaleItem(saleItem: ISaveManualSaleItem): Promise<ISale> {
        const { data } = await shopApi.post<ISale>('/sales/items/manual', saleItem);
        return data;
    }

    public async getCurrentSale(organisationId?: string): Promise<ISale> {
        const { data } = await shopApi.get<ISale>(`/sales/current`, {
            params: { organisationId },
        });
        return data;
    }

    public async updateSale(id: string, item: IUpdateSale): Promise<ISale> {
        const { data } = await shopApi.put<ISale>(`/sales/${id}`, item);
        return data;
    }

    public async completeSale(saleId: string, completeSale: ICompleteSale): Promise<ISale> {
        const { data } = await shopApi.put(`/sales/${saleId}/complete`, completeSale);
        return data;
    }

    public async cancelSale(saleId: string): Promise<ISale> {
        const { data } = await shopApi.delete(`/sales/${saleId}`);
        return data;
    }

    public async updateSaleItem(saleItemId: string, saleItem: IUpdateSaleItem): Promise<ISale> {
        const { data } = await shopApi.put(`/sales/items/${saleItemId}`, saleItem);
        return data;
    }

    public async removeSaleItem(saleItemId: string): Promise<ISale> {
        const { data } = await shopApi.delete(`/sales/items/${saleItemId}`);
        return data;
    }
}

export const salesClient = new SalesClient();
