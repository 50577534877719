import { InputAdornment, Stack, TextField } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../../../shared';
import { validateCurrentAmount } from '../../utils';
import { InputNumpad } from '../input-numpad/input-numpad.component';

interface Props {
    showNumpad: boolean;
    unitPrice: string;
    setUnitPrice: (arg: string) => void;
    totalPrice: string;
    setTotalPrice: (arg: string) => void;
    amount: string;
}

export const SaveProductPrice: FC<Props> = ({
    showNumpad = false,
    unitPrice,
    setUnitPrice,
    totalPrice,
    setTotalPrice,
    amount,
}) => {
    const { t } = useTranslation();
    const [selectedField, setSelectedField] = useState<string>('unitPrice');

    const onChangeUnitPrice = useCallback(
        (value: string) => {
            setUnitPrice(value);

            if (selectedField === 'unitPrice' && unitPrice.slice(-1) !== '.') {
                setTotalPrice((parseFloat(amount) * parseFloat(value)).toString());
            }
        },
        [setUnitPrice, selectedField, unitPrice, setTotalPrice, amount],
    );

    const onChangeTotalPrice = useCallback(
        (value: string) => {
            setTotalPrice(value);

            if (selectedField === 'totalPrice' && totalPrice.slice(-1) !== '.') {
                setUnitPrice((parseFloat(value) / parseFloat(amount)).toString());
            }
        },
        [setTotalPrice, selectedField, totalPrice, setUnitPrice, amount],
    );

    const onNumpadInput = useCallback(
        (input: string) => {
            if (selectedField === 'unitPrice') return onChangeUnitPrice(input);
            if (selectedField === 'totalPrice') return onChangeTotalPrice(input);
        },
        [onChangeTotalPrice, onChangeUnitPrice, selectedField],
    );

    return (
        <>
            <Stack direction="row" spacing={1} mb={1}>
                <TextField
                    value={unitPrice}
                    onChange={(input) => onChangeUnitPrice(input.target.value)}
                    label={t('unitPrice')}
                    InputProps={{
                        inputComponent: NumberInput as any,
                        startAdornment: (
                            <InputAdornment position="start" children="€" style={{ pointerEvents: 'none' }} />
                        ),
                    }}
                    inputProps={{ readOnly: true }}
                    onSelect={() => setSelectedField('unitPrice')}
                    inputRef={(input) => input && selectedField === 'unitPrice' && input.focus()}
                />
                <TextField
                    value={totalPrice}
                    onChange={(input) => onChangeTotalPrice(input.target.value)}
                    label={t('totalPrice')}
                    InputProps={{
                        inputComponent: NumberInput as any,
                        startAdornment: <InputAdornment position="start" children="€" />,
                    }}
                    inputProps={{ readOnly: true }}
                    onSelect={() => setSelectedField('totalPrice')}
                    inputRef={(input) => input && selectedField === 'totalPrice' && input.focus()}
                />
            </Stack>

            <InputNumpad
                value={selectedField === 'unitPrice' ? unitPrice.toString() : totalPrice.toString()}
                onChange={onNumpadInput}
                disable={
                    selectedField === 'unitPrice'
                        ? validateCurrentAmount(unitPrice, undefined, true)
                        : validateCurrentAmount(totalPrice, undefined, true)
                }
                showNumpad={showNumpad}
            />
        </>
    );
};
