import { PendingActions } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    SaleStatus,
    TableComponent,
    useQueryParams,
    useSelectedOrganisation,
    Widget,
} from '../../../shared';
import { useGetSalesList, useUpdateSale } from '../../hooks';

export const OnHoldList: FC = () => {
    const { t } = useTranslation();
    const [showModel, setShowModel] = useState(false);
    const { organisation } = useSelectedOrganisation();
    const { page, setPage, pageSize, setPageSize } = useQueryParams();
    const { data } = useGetSalesList({
        organisationId: organisation?.id,
        status: SaleStatus.ON_HOLD,
        page,
        pageSize,
    });
    const { mutateAsync: updateSale } = useUpdateSale();
    const columns: GridColDef[] = [
        {
            field: 'cardCode',
            headerName: t('cardCode'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: string) => value || t('solidarityCustomer'),
        },
        {
            field: ' ',
            headerName: ' ',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <Button onClick={() => updateSale({ id: row.id, item: { status: SaleStatus.CREATED } })} size={'small'}>
                    {t('outOfHold')}
                </Button>
            ),
        },
    ];
    return (
        <>
            <Widget
                title={t('onHoldSales')}
                icon={<PendingActions color="primary" />}
                sx={{ width: 400, margin: 'auto', mt: 2, height: 290 }}
                variant="subtitle1"
                footer={
                    <Button
                        onClick={() => setShowModel(true)}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        disabled={data?.pagination.size === 0}
                    >
                        {data?.pagination.size === 0 ? t('noOnHoldSales') : t('showOnHoldSales')}
                    </Button>
                }
            >
                <Stack spacing={2}>
                    <Typography variant="caption">
                        {data?.pagination.size === 0 ? t('noOnHoldSalesHelpText') : t('onHoldSalesHelpText')}
                    </Typography>
                </Stack>
            </Widget>

            <Dialog open={showModel} fullWidth maxWidth="sm">
                <DialogTitleWithClose onClose={() => setShowModel(false)}>{t('onHold')}</DialogTitleWithClose>
                <DialogContent>
                    <TableComponent
                        hideFooter={(data?.pagination?.pages || 0) <= 1}
                        rows={data?.data || []}
                        rowCount={data?.pagination.size || 0}
                        columns={columns}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
