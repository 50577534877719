import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../../../shared';

interface Props {
    amount: string;
    setAmount: (amount: string) => void;
}

export const ReceivedAmount: FC<Props> = ({ amount, setAmount }) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="subtitle2" mb={0}>
                {t('paid')}
            </Typography>
            <TextField
                InputProps={{
                    inputComponent: NumberInput as any,
                    startAdornment: <InputAdornment position="start" children={'€'} />,
                }}
                inputProps={{ decimalScale: 2, readOnly: true }}
                value={amount}
                onChange={(input) => setAmount(input.target.value)}
                focused
                sx={{
                    mb: 0,
                    '.MuiInputAdornment-root': { ml: 1 },
                    '.MuiInputBase-input': { py: 1 },
                    '.MuiInputBase-root': {
                        height: 'auto',
                        width: '220px',
                        mb: 0,
                        p: 0,
                    },
                }}
            />
        </Box>
    );
};
