import { ISupplier, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../index';
import { suppliersClient } from '../clients';
import { QueryKeys } from '../enums';

const client = suppliersClient;
const listKey = QueryKeys.SUPPLIERS;
const itemKey = QueryKeys.SUPPLIER;

export function useSuppliersList(...args: UseListParams<ISupplier>) {
    return useList(client, listKey, ...args);
}

export function useSupplier(...args: UseItemParams<ISupplier>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveSupplier() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteSupplier() {
    return useDelete(client, listKey);
}
