import { Box, ListItemText, MenuItem, Typography } from '@mui/material';
import React, { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ControlledSelect, ListModel } from '../../../shared';

type Props = ComponentProps<typeof ControlledSelect> & {
    options: ListModel<any> | undefined;
    createLabel?: string;
    linkLabel?: string;
    url?: string;
};

export const SelectMultipleCategories: FC<Props> = ({
    options,
    createLabel,
    linkLabel,
    url,
    ...controlledSelectProps
}) => {
    const { t } = useTranslation();
    const noOptions = options?.pagination?.size === 0;

    return (
        <Box sx={{ '& .MuiFormControl-root': { mb: 1 }, mb: 2 }}>
            <ControlledSelect
                name={controlledSelectProps.name}
                label={controlledSelectProps.label}
                renderValue={(selected) =>
                    (selected as string[])
                        .map((value: string) => options?.data?.find((item) => item.id === value))
                        .map((item) => (item ? item.name : t('unknown')))
                        .join(', ')
                }
                multiple
                disabled={noOptions}
            >
                {options?.data.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))}
            </ControlledSelect>

            {noOptions && (
                <Typography variant="caption" sx={{ color: 'grey', lineHeight: 0.8, pb: 2 }}>
                    {t(`${createLabel}`)}
                    <Link to={`${url}`}>{t(`${linkLabel}`)}</Link>
                </Typography>
            )}
        </Box>
    );
};
