import React, { FC } from 'react';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useShop, Widget } from '../../../shared';

export const SolidaritySale: FC = () => {
    const { t } = useTranslation();
    const { setIsSolidaritySale } = useShop();

    return (
        <>
            <Widget
                sx={{ width: 400, margin: 'auto', mt: 2, height: 290 }}
                title={t('solidaritySale')}
                icon={<LoyaltyIcon color="primary" />}
                variant="subtitle1"
                footer={
                    <Button
                        variant="outlined"
                        onClick={() => setIsSolidaritySale(true)}
                        size="large"
                        sx={{ width: '100%' }}
                    >
                        {t('solidaritySale')}
                    </Button>
                }
            >
                <Stack spacing={2}>
                    <Typography variant="caption">{t('solidaritySaleCaption')}</Typography>
                </Stack>
            </Widget>
        </>
    );
};
