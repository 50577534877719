import { DownloadOutlined } from '@mui/icons-material';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    productId: string;
    variant?: 'icon' | 'default';
}

export const DownloadLabels: FC<Props> = ({ productId, variant = 'default' }) => {
    const { t } = useTranslation();

    const downloadLabels: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            window.open(`/shop-api/labels/${productId}`, '__blank');
            e.stopPropagation();
        },
        [productId],
    );

    return variant === 'icon' ? (
        <Tooltip title={<Typography sx={{ fontSize: '13px' }}>{t('downloadLabels')}</Typography>} arrow>
            <IconButton onClick={downloadLabels}>
                <DownloadOutlined />
            </IconButton>
        </Tooltip>
    ) : (
        <Button color="secondary" variant="outlined" onClick={downloadLabels}>
            {t('downloadLabels')}
        </Button>
    );
};
