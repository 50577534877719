import React, { FC } from 'react';
import {
    formatDateTimeString,
    formatPrice,
    formatToFixed,
    FormGrid,
    IRegisterSession,
    LabelValue,
    Widget,
} from '../../../shared';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

interface Props {
    session: IRegisterSession;
}

export const RegisterSessionDetailInformation: FC<Props> = ({ session }) => {
    const { t } = useTranslation();
    const registerDifference = (session?.totalCashAtStop || 0) - (session?.expectedTotalCashAtStop || 0);

    return (
        <FormGrid xs={12} sm={6} mb={2}>
            <Widget title={t('registerSessionInformation')}>
                <FormGrid xs={12} sm={6}>
                    <Stack direction="column">
                        <LabelValue label={t('startedDate')} value={formatDateTimeString(session.startedDate)} />
                        <LabelValue label={t('startedBy')} value={session.startedBy} />
                    </Stack>
                    <Stack direction="column">
                        <LabelValue label={t('stoppedDate')} value={formatDateTimeString(session.stoppedDate)} />
                        <LabelValue label={t('stoppedBy')} value={session.stoppedBy} />
                    </Stack>
                </FormGrid>
            </Widget>

            <Widget title={t('registerSessionAmounts')}>
                <FormGrid xs={12} sm={6}>
                    <Stack direction="column">
                        <LabelValue label={t('totalCashAtStart')} value={formatPrice(session.totalCashAtStart)} />
                        <LabelValue label={t('totalCashAtStop')} value={formatPrice(session.totalCashAtStop)} />
                        <LabelValue
                            label={t('expectedTotalCashAtStop')}
                            value={formatPrice(session.expectedTotalCashAtStop)}
                        />
                        <LabelValue
                            label={t('differenceInExpectedCash')}
                            value={
                                registerDifference > 0
                                    ? `€ + ${formatToFixed(registerDifference)}`
                                    : formatPrice(registerDifference)
                            }
                        />
                    </Stack>

                    <Stack direction="column">
                        <Stack direction="column">
                            <LabelValue label={t('totalCashIncome')} value={formatPrice(session.totalCashIncome)} />
                            <LabelValue
                                label={t('totalDigitalIncome')}
                                value={formatPrice(session.totalDigitalIncome)}
                            />
                        </Stack>
                        <LabelValue label={t('totalEarnings')} value={formatPrice(session.totalEarnings)} />
                    </Stack>
                </FormGrid>
            </Widget>
        </FormGrid>
    );
};
