import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InputAdornment, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledCheckbox, ControlledInput, Page, useSelectedOrganisation, Widget } from '../../../shared';
import { useSettings, useUpdateSettings } from '../../hooks';
import { ISettingsForm } from '../../models';
import { useSettingsSchema } from '../../validators';

export const SettingsPage: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const { data: settings, isFetching } = useSettings(organisation?.id);
    const { mutateAsync: updateSettings, isPending: isUpdating } = useUpdateSettings();

    const form = useForm<ISettingsForm>({
        mode: 'all',
        resolver: yupResolver(useSettingsSchema()),
    });

    const watchSolidarityPriceIsPurchasePrice = form.watch('solidarityPriceIsPurchasePrice');

    useEffect(() => {
        if (settings) {
            form.reset({ ...settings, additionalSolidarityCharge: settings.additionalSolidarityCharge || null });
        }
    }, [settings, form]);

    const onSubmit = useCallback(
        async (item: ISettingsForm) => {
            if (organisation?.id) {
                await updateSettings({
                    ...item,
                    additionalSolidarityCharge: item.solidarityPriceIsPurchasePrice
                        ? null
                        : item.additionalSolidarityCharge,
                    organisationId: organisation.id,
                    saleTicketMessage: item.saleTicketMessage || '',
                });
            }
        },
        [organisation?.id, updateSettings],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                key="save"
                disabled={isFetching || isUpdating}
            >
                {t('save')}
            </Button>,
        ],
        [form, onSubmit, isFetching, isUpdating, t],
    );

    return (
        <Page title={t('settings')} actions={actions} loading={isFetching || isUpdating}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Widget footer={actions}>
                        <ControlledInput
                            label={t('saleTicketMessage')}
                            name={'saleTicketMessage'}
                            sx={{ '.MuiInputBase-root': { height: 'fit-content' } }}
                            rows={6}
                            multiline
                        />
                        <ControlledCheckbox control={form.control} name="showNumpad" label={t('showNumpadMessage')} />
                        <Typography variant="subtitle2">{t('solidarity')}</Typography>
                        <ControlledCheckbox
                            control={form.control}
                            name="solidarityPriceIsPurchasePrice"
                            label={t('solidarityPriceIsPurchasePrice')}
                        />
                        {!watchSolidarityPriceIsPurchasePrice && (
                            <ControlledInput
                                type="number"
                                label={t('additionalSolidarityCharge')}
                                name="additionalSolidarityCharge"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end" children="%" />,
                                }}
                                sx={{
                                    display: watchSolidarityPriceIsPurchasePrice ? 'none' : 'block',
                                    maxWidth: '250px',
                                }}
                                required={!watchSolidarityPriceIsPurchasePrice}
                            />
                        )}
                    </Widget>
                </form>
            </FormProvider>
        </Page>
    );
};
