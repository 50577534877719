import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IBudget, QueryKeys } from '../../shared';
import { budgetsClient } from '../clients';

export function useBudget(organisationId?: string): UseQueryResult<IBudget> {
    return useQuery({
        queryKey: [QueryKeys.BUDGET, organisationId],
        queryFn: () => budgetsClient.getBudget(organisationId),
        enabled: !!organisationId,
    });
}

export function useUpdateBudget(): UseMutationResult<IBudget, AxiosError, IBudget> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (budget: IBudget) => budgetsClient.updateBudget(budget),
        onSuccess: async (result: IBudget) =>
            await queryClient.setQueryData([QueryKeys.BUDGET, result.organisationId], result),
    });
}
