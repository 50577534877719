import { InputAdornment, TextField } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading, MeasurementUnit, NumberInput } from '../../../shared';
import { validateCurrentAmount } from '../../utils';
import { InputNumpad } from '../input-numpad/input-numpad.component';

interface Props {
    showNumpad: boolean;
    amount: string;
    setAmount: (arg: string) => void;
    unitPrice: string;
    setTotalPrice: (arg: string) => void;
    measurementUnit?: MeasurementUnit;
}

export const SaveProductAmount: FC<Props> = ({
    showNumpad = false,
    amount,
    setAmount,
    unitPrice,
    setTotalPrice,
    measurementUnit,
}) => {
    const { t } = useTranslation();
    const isGram = measurementUnit === MeasurementUnit.GRAM;

    const onChangeAmount = useCallback(
        (value: string) => {
            setAmount(value);
            setTotalPrice((parseFloat(value) * parseFloat(unitPrice)).toString());
        },
        [setAmount, setTotalPrice, unitPrice],
    );

    const onNumpadInput = useCallback(
        (input: string) => {
            return onChangeAmount(input);
        },
        [onChangeAmount],
    );

    return (
        <>
            {measurementUnit ? (
                <TextField
                    InputProps={{
                        inputComponent: NumberInput as any,
                        endAdornment: (
                            <InputAdornment position="end" children={isGram ? t('abbrevKilogram') : t('abbrevPiece')} />
                        ),
                    }}
                    inputProps={{
                        decimalScale: isGram ? 3 : 0,
                        readOnly: true,
                    }}
                    value={amount}
                    required
                    onChange={(input) => onChangeAmount(input.target.value)}
                    sx={{
                        mb: 0,
                        '.MuiInputBase-root': { border: 'none', p: 0 },
                        '.MuiInputAdornment-root, input': { width: '50%' },
                        input: { textAlign: 'right', fontSize: '18px', pl: 0 },
                    }}
                />
            ) : (
                <Loading />
            )}

            <InputNumpad
                value={amount}
                onChange={onNumpadInput}
                disable={validateCurrentAmount(amount, measurementUnit)}
                showNumpad={showNumpad}
            />
        </>
    );
};
