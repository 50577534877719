import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    Page,
    RowActions,
    SortOrder,
    TableComponent,
    useDeleteShopCategory,
    useQueryParams,
    useSelectedOrganisation,
    useShopCategoriesList,
} from '../../../shared';

export const ShopCategoriesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'name', sort: 'asc' }],
    });
    const { organisation } = useSelectedOrganisation();

    const { mutateAsync: deleteShopCategory } = useDeleteShopCategory();
    const { data: shopCategories, isPending } = useShopCategoriesList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('name'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'show',
            headerName: t('shopCategoryShow'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueFormatter: (value: boolean) => (value ? t('yes') : t('no')),
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <RowActions
                    onEdit={() => navigate(`${row?.id}/edit`)}
                    onDelete={() => deleteShopCategory(row.id)}
                    deleteWarningTitle={t('deleteShopCategory')}
                    deleteWarningText={t('deleteShopCategoryText')}
                />
            ),
        },
    ];

    return (
        <Page
            title={t('shopCategories')}
            actions={[
                <Button component={Link} to="new" color="primary" variant="contained">
                    {t('newShopCategory')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={shopCategories?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={shopCategories?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
            />
        </Page>
    );
};
