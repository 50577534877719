import { yupResolver } from '@hookform/resolvers/yup';
import { Delete } from '@mui/icons-material';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    AutocompleteProducts,
    ControlledNumberInput,
    ControlledSelect,
    IParams,
    ISaleQuotumForm,
    ISaveSaleQuotumRule,
    isConflict,
    MeasurementUnit,
    Page,
    RemoveModal,
    SaleQuotumPeriod,
    useSelectedOrganisation,
} from '../../../shared';
import { useDeleteSaleQuotum, useSaleQuotum, useSaveSaleQuotum } from '../../hooks';
import { useSaleQuotumSchema } from '../../validators';

export const SaleQuotumEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();

    const { data: saleQuotum, isFetching } = useSaleQuotum(id);
    const { mutateAsync: saveSaleQuotum, isPending: isSaving } = useSaveSaleQuotum();
    const { mutateAsync: deleteSaleQuotum } = useDeleteSaleQuotum();

    const form = useForm<ISaleQuotumForm>({
        mode: 'all',
        resolver: yupResolver(useSaleQuotumSchema()),
    });

    const rules = useFieldArray({ name: 'rules', control: form.control });
    const isGram = form.watch('product')?.measurementUnit === MeasurementUnit.GRAM;

    useEffect(() => {
        if (saleQuotum) {
            form.reset({
                ...saleQuotum,
                rules: saleQuotum.rules.map((x) => ({
                    ...x,
                    amount: saleQuotum.product.measurementUnit === MeasurementUnit.GRAM ? x.amount / 1000 : x.amount,
                })),
            });
        } else if (location?.state?.product) {
            form.reset({ product: location.state.product, rules: [{ amount: undefined, amountOfPeople: undefined }] });
        } else {
            form.reset({ rules: [{ amount: undefined, amountOfPeople: undefined }] });
        }
    }, [saleQuotum, form.reset, form, location?.state?.product]);

    const onSubmit = useCallback(
        async (item: ISaleQuotumForm) => {
            if (organisation?.id) {
                try {
                    await saveSaleQuotum({
                        id,
                        item: {
                            ...item,
                            productId: item.product.id,
                            organisationId: organisation?.id,
                            rules: item.rules.map(
                                (x) =>
                                    ({
                                        ...x,
                                        amount: isGram ? (x.amount || 1) * 1000 : x.amount,
                                    }) as ISaveSaleQuotumRule,
                            ),
                        },
                    });
                    location?.state?.product
                        ? navigate(`/admin/products/${location.state.product?.id}`)
                        : navigate('/admin/sale-quota');
                } catch (e) {
                    if (isConflict(e)) {
                        form.setError('product', {
                            type: 'custom',
                            message: t('saleQuotumProductConflict'),
                        });
                    }
                }
            }
        },
        [organisation?.id, isGram, saveSaleQuotum, id, location?.state?.product, navigate, form, t],
    );

    const onDelete = useCallback(async () => {
        await deleteSaleQuotum(id);
        navigate(-1);
    }, [deleteSaleQuotum, navigate, id]);

    const actions = useMemo(
        () => [
            <Button
                key={'submit'}
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isFetching}
            >
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={
                            <Button color="primary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('deleteSaleQuotumTitle')}
                        text={t('deleteSaleQuotumText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate(-1)} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isFetching, t, id, onDelete, navigate],
    );

    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={saleQuotum?.id ? t('updateSaleQuotum') : t('newSaleQuotum')}
            loading={isSaving}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Card variant="outlined">
                        <CardContent>
                            <Stack spacing={2} maxWidth={'550px'} sx={{ '.MuiAutocomplete-root': { mt: 1 } }}>
                                <Controller
                                    name="product"
                                    control={form.control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                            <Typography sx={{ color: error ? 'error.main' : 'inherit' }}>
                                                {t('product')} <span>*</span>
                                            </Typography>

                                            {location?.state?.product ? (
                                                <TextField
                                                    disabled
                                                    value={
                                                        value?.barcodes.length
                                                            ? `${value?.name} (${value?.barcodes.join(', ')})`
                                                            : `${value?.name}`
                                                    }
                                                />
                                            ) : (
                                                <AutocompleteProducts
                                                    selectedProduct={value}
                                                    setSelectedProduct={onChange}
                                                    error={error}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                <ControlledSelect label={t('period')} name="period" required>
                                    {Object.values(SaleQuotumPeriod).map((period) => (
                                        <MenuItem value={period}>{t(period)}</MenuItem>
                                    ))}
                                </ControlledSelect>
                                {rules.fields.map((field, index) => (
                                    <Stack spacing={2} direction="row" key={field.id} alignItems="flex-start">
                                        <ControlledNumberInput
                                            name={`rules.${index}.amountOfPeople`}
                                            label={t('amountOfPeople')}
                                            required
                                        />
                                        <ControlledNumberInput
                                            name={`rules.${index}.amount`}
                                            label={isGram ? t('saleQuotaAmountKg') : t('saleQuotaAmountPiece')}
                                            decimalScale={isGram ? 3 : 0}
                                            required
                                        />
                                        <IconButton onClick={() => rules.remove(index)}>
                                            <Delete sx={{ mt: 4 }} />
                                        </IconButton>
                                    </Stack>
                                ))}

                                <Button
                                    sx={{
                                        px: 0,
                                        maxWidth: 'fit-content',
                                        height: 'fit-content',
                                        textDecoration: 'underline',
                                    }}
                                    size="small"
                                    onClick={() => rules.append({ amountOfPeople: undefined, amount: undefined })}
                                >
                                    {t('addRule')}
                                </Button>
                            </Stack>
                        </CardContent>
                        <CardActions sx={{ backgroundColor: 'background.default' }} children={actions} />
                    </Card>
                </form>
            </FormProvider>
        </Page>
    );
};
