import { IPurchase, IPurchaseForm, ISavePurchase } from '../models';
import { mapDateString } from '../../shared';

export const purchaseToFormMapper = (purchase: IPurchase): IPurchaseForm => {
    return {
        ...purchase,
        supplierId: purchase.supplier?.id || '',
        invoiceNumber: purchase.invoiceNumber || '',
    };
};

export const purchaseFromFormMapper = (purchase: IPurchaseForm, organisationId: string): ISavePurchase => {
    return {
        ...purchase,
        date: mapDateString(purchase.date) || '',
        supplierId: purchase.supplierId || null,
        organisationId: organisationId,
    };
};
