import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import {
    ICreateSaleItem,
    ISale,
    ISalesQuery,
    ISaveManualSaleItem,
    isBadRequest,
    IUpdateSale,
    IUpdateSaleItem,
    ListModel,
    QueryKeys,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useShop,
} from '../../shared';
import { salesClient } from '../clients';
import { SaleError } from '../enums';
import { ICompleteSale } from '../models';

const client = salesClient;
const listKey = QueryKeys.SALES;
const itemKey = QueryKeys.CURRENT_SALE;

export function useGetSalesList(...args: UseListParams<ISale, ISalesQuery>): UseQueryResult<ListModel<ISale>> {
    return useList(client, listKey, ...args);
}

export function useGetSale(...args: UseItemParams<ISale>): UseQueryResult<ISale> {
    return useItem(client, itemKey, ...args);
}

export function useCreateSaleItem(): UseMutationResult<ISale | undefined, AxiosError, ICreateSaleItem> {
    const queryClient = useQueryClient();
    const { setQuotumRuleExceeded, setBudgetExceeded } = useShop();
    return useMutation({
        mutationFn: async (item) => {
            try {
                return await client.createSaleItem(item);
            } catch (e) {
                if (isAxiosError(e) && isBadRequest(e) && e.response?.data.type === SaleError.AMOUNT_EXCEEDED) {
                    setQuotumRuleExceeded({
                        rule: e.response?.data?.rule,
                        amount: e.response?.data?.amount,
                        product: e.response.data.product,
                        createSaleItem: item,
                    });
                } else if (isAxiosError(e) && isBadRequest(e) && e.response?.data.type === SaleError.BUDGET_EXCEEDED) {
                    setBudgetExceeded({
                        rule: e.response?.data?.rule,
                        spentBudget: e.response?.data?.spentBudget,
                        createSaleItem: item,
                    });
                } else {
                    throw e;
                }
            }
        },

        onSuccess: async (currentSale, request) => {
            if (currentSale) {
                await queryClient.setQueryData([itemKey, request.organisationId], currentSale);
            }
        },
    });
}

export function useCreateManualSaleItem(): UseMutationResult<ISale | undefined, AxiosError, ISaveManualSaleItem> {
    const queryClient = useQueryClient();
    const { setBudgetExceeded } = useShop();
    return useMutation({
        mutationFn: async (item) => {
            try {
                return await client.createManualSaleItem(item);
            } catch (e) {
                if (isAxiosError(e) && isBadRequest(e) && e.response?.data.type === SaleError.BUDGET_EXCEEDED) {
                    setBudgetExceeded({
                        rule: e.response?.data?.rule,
                        spentBudget: e.response?.data?.spentBudget,
                        manualSaleItem: item,
                    });
                } else {
                    throw e;
                }
            }
        },

        onSuccess: async (currentSale) => {
            if (currentSale) {
                await queryClient.setQueryData([itemKey, currentSale.organisationId], currentSale);
            }
        },
    });
}

export function useUpdateSaleItem(): UseMutationResult<
    ISale | undefined,
    AxiosError,
    { saleItemId: string; saleItem: IUpdateSaleItem }
> {
    const queryClient = useQueryClient();
    const { setQuotumRuleExceeded, setBudgetExceeded } = useShop();
    return useMutation({
        mutationFn: async ({ saleItemId, saleItem }) => {
            try {
                return await client.updateSaleItem(saleItemId, saleItem);
            } catch (e) {
                if (isAxiosError(e) && isBadRequest(e) && e.response?.data.type === SaleError.AMOUNT_EXCEEDED) {
                    setQuotumRuleExceeded({
                        rule: e.response?.data?.rule,
                        amount: e.response?.data?.amount,
                        product: e.response.data.product,
                        updateSaleItem: { saleItemId, ...saleItem },
                    });
                } else if (isAxiosError(e) && isBadRequest(e) && e.response?.data.type === SaleError.BUDGET_EXCEEDED) {
                    setBudgetExceeded({
                        rule: e.response?.data?.rule,
                        spentBudget: e.response?.data?.spentBudget,
                        updateSaleItem: { saleItemId, ...saleItem },
                    });
                } else {
                    throw e;
                }
            }
        },

        onSuccess: async (currentSale) => {
            if (currentSale) {
                await queryClient.setQueryData([itemKey, currentSale.organisationId], currentSale);
            }
        },
    });
}

export function useCurrentSale(organisationId?: string) {
    return useQuery<ISale, AxiosError>({
        queryKey: [itemKey, organisationId],
        queryFn: () => client.getCurrentSale(organisationId),
        enabled: !!organisationId,
    });
}

export function useCompleteCurrentSale(): UseMutationResult<
    ISale,
    AxiosError,
    { saleId: string; completeSale: ICompleteSale }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ saleId, completeSale }) => client.completeSale(saleId, completeSale),
        onSuccess: async (result) => {
            await queryClient.setQueryData([itemKey, result.organisationId], result);
        },
    });
}

export function useCancelCurrentSale(): UseMutationResult<ISale, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (saleId) => client.cancelSale(saleId),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, result.organisationId] });
        },
    });
}

export function useUpdateSale(): UseMutationResult<ISale, AxiosError, { id: string; item: IUpdateSale }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, item }) => client.updateSale(id, item),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({ queryKey: [itemKey, result.organisationId] });
        },
    });
}

export function useRemoveSaleItem(): UseMutationResult<ISale, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (saleItemId) => client.removeSaleItem(saleItemId),
        onSuccess: async (currentSale) => {
            await queryClient.setQueryData([itemKey, currentSale.organisationId], currentSale);
        },
    });
}
