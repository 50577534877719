import {
    IOutStockReason,
    IStockMovement,
    QueryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../shared';
import { stockMovementsClient } from '../clients';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

const client = stockMovementsClient;
const listKey = QueryKeys.STOCK_MOVEMENTS;
const itemKey = QueryKeys.STOCK_MOVEMENT;

export function useStockMovementList(...args: UseListParams<IStockMovement>) {
    return useList(client, listKey, ...args);
}

export function useStockMovement(...args: UseItemParams<IStockMovement>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveStockMovement() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteStockMovement() {
    return useDelete(client, listKey);
}

export function useReasons(): UseQueryResult<IOutStockReason[]> {
    return useQuery({ queryKey: [QueryKeys.STOCK_MOVEMENTS], queryFn: () => stockMovementsClient.getReasons() });
}
