import { IProduct, IProductForm, ISaveProduct, MeasurementUnit } from '../index';
import { ProductTaxes } from '../../admin/enums';

export const productToFormMapper = (product: IProduct): IProductForm => {
    return {
        ...product,
        categoryId: product.category?.id,
        shopCategoryIds: product.shopCategories?.map(({ id }) => id),
        tax: product.tax?.toString() as ProductTaxes,
        price: product.price,
        supplierId: product.supplier?.id,
        articleNumberSupplier: product.articleNumberSupplier || '',
        stockTarget:
            product.stockTarget &&
            (product.measurementUnit === MeasurementUnit.GRAM ? product.stockTarget / 1000 : product.stockTarget),
        standardOrderAmount:
            product.standardOrderAmount &&
            (product.measurementUnit === MeasurementUnit.GRAM
                ? product.standardOrderAmount / 1000
                : product.standardOrderAmount),
    };
};

export const productFromFormMapper = (product: IProductForm, organisationId: string): ISaveProduct => {
    return {
        ...product,
        organisationId: organisationId,
        price:
            product.price && (product.measurementUnit === MeasurementUnit.GRAM ? product.price / 1000 : product.price),
        solidarityPrice:
            product.solidarityPrice &&
            (product.measurementUnit === MeasurementUnit.GRAM
                ? product.solidarityPrice / 1000
                : product.solidarityPrice),
        tax: parseInt(product.tax),
        categoryId: !!product.categoryId ? product.categoryId : undefined,
        stockTarget:
            product.stockTarget &&
            (product.measurementUnit === MeasurementUnit.GRAM ? product.stockTarget * 1000 : product.stockTarget),
        standardOrderAmount:
            product.standardOrderAmount &&
            (product.measurementUnit === MeasurementUnit.GRAM
                ? product.standardOrderAmount * 1000
                : product.standardOrderAmount),
        supplierId: product.supplierId || undefined,
    };
};

export const productMapper = (product: IProduct): IProduct => {
    return {
        ...product,
        price:
            product.price && (product.measurementUnit === MeasurementUnit.GRAM ? product.price * 1000 : product.price),
        solidarityPrice:
            product.solidarityPrice &&
            (product.measurementUnit === MeasurementUnit.GRAM
                ? product.solidarityPrice * 1000
                : product.solidarityPrice),
    };
};
