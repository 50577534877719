import { InputAdornment, Typography } from '@mui/material';
import { FC, forwardRef } from 'react';
import { InputAttributes, NumericFormat } from 'react-number-format';
import { ControlledInput } from '../controlled-input/controlled-input.component';

type Props = {
    name: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
};

interface NumberFormatProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = forwardRef<typeof NumericFormat<InputAttributes>, NumberFormatProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator=" "
                decimalSeparator=","
                valueIsNumericString
                allowNegative={false}
                decimalScale={2}
            />
        );
    },
);

export const MoneyInput: FC<Props> = ({ name, label, disabled, required }) => {
    return (
        <ControlledInput
            InputProps={{
                sx: { maxWidth: '200px' },
                startAdornment: (
                    <InputAdornment position="start">
                        <Typography sx={{ color: 'black' }}>€</Typography>
                    </InputAdornment>
                ),
                inputComponent: NumberFormatCustom as any,
            }}
            name={name}
            label={label}
            disabled={disabled}
            required={required}
        />
    );
};
