import { yupResolver } from '@hookform/resolvers/yup';
import { QrCodeScanner } from '@mui/icons-material';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { useShop, Widget } from '../../../shared';
import { WarningNoSupportCaseComponent } from '../warning-no-support-case/warning-no-support-case.component';
import ReactInputMask from 'react-input-mask';

export const ScanSupportCase: FC = () => {
    const { t } = useTranslation();
    const { setCardCode, showWarningNoSupportCase, setShowWarningNoSupportCase } = useShop();
    const mask = window.carity.environment.cardCodeMask;

    const schema = object().shape({
        code: string().required(t('requiredField')),
    });

    const form = useForm<{ code: string }>({
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(
        (data: { code: string }) => {
            setCardCode(data.code);
        },
        [setCardCode],
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Widget
                    sx={{ maxWidth: 400, margin: 'auto', mt: 2, height: 290 }}
                    title={t('scanCustomerCardTitle')}
                    icon={<QrCodeScanner color="primary" />}
                    variant="subtitle1"
                    footer={
                        <Button type="submit" variant="contained" size="large" sx={{ width: '100%' }}>
                            {t('search')}
                        </Button>
                    }
                >
                    <Stack spacing={2}>
                        <Typography variant="caption">{t('scanCustomerCard')}</Typography>
                        <Controller
                            name="code"
                            control={form.control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <ReactInputMask mask={mask} value={value} onChange={onChange}>
                                    {
                                        (() => (
                                            <TextField
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                                placeholder={mask.replaceAll('*', 'X')}
                                                label={t('code')}
                                                variant="outlined"
                                            />
                                        )) as any
                                    }
                                </ReactInputMask>
                            )}
                        />
                    </Stack>

                    <WarningNoSupportCaseComponent
                        show={!!showWarningNoSupportCase}
                        onClose={() => {
                            setShowWarningNoSupportCase(false);
                            setCardCode(undefined);
                        }}
                        text={t('customerNotFound')}
                    />
                </Widget>
            </form>
        </FormProvider>
    );
};
