import { alpha, createTheme, Theme, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { IEnvironment } from '../../shared';
import { createBaseTheme } from './base.theme';

const errorColor = '#aa1409';

export const createAntwerpTheme = (themeOptions: IEnvironment['theme']): Theme => {
    const palette = getPalette();
    const theme = createTheme({ palette });

    const typography = getTypography();
    const components = getComponents(theme);

    return deepmerge(
        createBaseTheme(themeOptions, palette),
        createTheme(theme, {
            shape: {
                borderRadius: 0,
            },
            palette,
            typography,
            components,
        }),
    );
};

function getComponents({ breakpoints }: Theme): ThemeOptions['components'] {
    return {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    [breakpoints.up('xs')]: {
                        paddingLeft: 0,
                    },
                    ['& .MuiTypography-appTitle']: {
                        width: '244px',
                    },
                    ['& .app-logo img']: {
                        height: 55,
                        width: 55,
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.filter .MuiInputBase-root': {
                        height: 35,
                    },
                    '&.filter .MuiFormLabel-root': {
                        fontSize: 14,
                        marginBottom: 2,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 48,
                    [':focus']: {
                        outlineWidth: '2px',
                        outlineStyle: 'solid',
                        outlineColor: '#0057b7',
                        outlineOffset: '1px',
                    },
                },
                sizeSmall: {
                    height: 30,
                },
            },
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#141414',
                    marginBottom: 6,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    transform: 'none',
                    color: '#141414',
                    marginBottom: 6,
                    position: 'relative',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: { '.MuiInputBase-root': { padding: '6px' } },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginBottom: 6,
                    '&.Mui-error': {
                        marginLeft: 0,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&::after': {
                        display: 'none',
                    },
                    '&::before': {
                        display: 'none',
                    },
                },
                input: {
                    borderStyle: 'solid',
                    borderColor: '#6f6f6f',
                    borderWidth: 1,
                    height: 48,
                    boxSizing: 'border-box',
                    padding: '0 1.5rem',

                    '&::placeholder': {
                        opacity: 0.6,
                        color: '#141414',
                    },
                    '&:focus': {
                        borderColor: '#0057b7',
                        boxShadow: `${alpha('#0057b7', 0.25)} 0 0 0 2px`,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&::after': {
                        display: 'none',
                    },
                    '&::before': {
                        display: 'none',
                    },
                    fieldset: {
                        display: 'none',
                    },
                    borderStyle: 'solid',
                    borderColor: '#6f6f6f',
                    borderWidth: 1,
                    boxSizing: 'border-box',
                    height: 48,
                    '&.Mui-focused': {
                        borderStyle: 'none',
                        borderColor: '#0057b7',
                        boxShadow: `${alpha('#0057b7', 0.25)} 0 0 0 2px`,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    marginTop: '0px !important',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    height: 48,
                    paddingLeft: 12,
                    display: 'flex',
                    alignItems: 'center',
                    '&.MuiInputBase-root .filter': {
                        height: 35,
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardInfo: {
                    border: '1px solid #0064B4',
                    color: '#0064B4',
                    '>.MuiAlert-icon': {
                        color: '#0064B4',
                    },
                },
                standardError: {
                    border: `1px solid ${errorColor}`,
                    color: errorColor,
                    '>.MuiAlert-icon': {
                        color: errorColor,
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    border: '1px solid rgba(0,0,0,0.12)',
                    '&::before': {
                        display: 'none',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    margin: '20px 0',
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                lineHeight: 1.5,
            },
        },
    };
}

function getPalette(): ThemeOptions['palette'] {
    return {
        primary: {
            main: '#0057b7',
            shade: '#E0EBF6',
        },
        secondary: {
            main: '#0057b7',
            shade: '#E0EBF6',
        },
        background: {
            default: '#FAFAFA',
            paper: '#FFF',
        },
        error: {
            main: errorColor,
        },
    };
}

function getTypography(): ThemeOptions['typography'] {
    return {
        fontFamily: '"Sun Antwerpen", sans-serif',
        button: {
            fontFamily: '"Sun Antwerpen", sans-serif',
            textTransform: 'none',
            fontWeight: 700,
        },
        title: {
            fontFamily: '"Antwerpen Regular", sans-serif',
        },
        subtitle1: {
            fontFamily: '"Antwerpen Regular", sans-serif',
        },
        subtitle2: {
            fontFamily: '"Antwerpen Regular", sans-serif',
        },
        description: {
            fontFamily: '"Sun Antwerpen", sans-serif',
            fontSize: '12px',
        },
        caption: {
            fontFamily: '"Sun Antwerpen", sans-serif',
            fontSize: '14px',
        },
    };
}
