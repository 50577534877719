import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormGrid, IParams, Page, useGetRegisterSession, Widget } from '../../../shared';
import { getCashAmounts } from '../../../shop/utils';
import { RegisterSessionDetailCash, RegisterSessionDetailInformation } from '../../components';

export const RegisterSessionDetail: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: session, isPending } = useGetRegisterSession(id);

    const cashAtStart = getCashAmounts(session?.cashAtStart);
    const cashAtStop = getCashAmounts(session?.cashAtStop);

    return (
        <Page
            title={t('registerSessionDetails')}
            onBack={() => navigate('/history/register-sessions')}
            loading={isPending}
        >
            {session && (
                <>
                    {session && <RegisterSessionDetailInformation session={session} />}
                    <FormGrid xs={12} sm={6}>
                        <Widget title={t('cashInRegisterAtStart')} hasTable>
                            {cashAtStart && <RegisterSessionDetailCash cash={cashAtStart} />}
                        </Widget>
                        <Widget title={t('cashInRegisterAtStop')} hasTable>
                            {cashAtStop && <RegisterSessionDetailCash cash={cashAtStop} />}
                        </Widget>
                    </FormGrid>
                </>
            )}
        </Page>
    );
};
