import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelValue } from '../../../../shared';
import { ISupportCaseInfo } from '../../../models';
import { useCountFamilyMembers } from '../../../utils';

interface Props {
    supportCase: ISupportCaseInfo;
}

export const FamilySituationInfo: FC<Props> = ({ supportCase }) => {
    const { t } = useTranslation();
    const { adults, children, unknown } = useCountFamilyMembers(supportCase);
    return (
        <LabelValue
            label={t('familySituation')}
            value={
                supportCase && (
                    <Stack sx={{ '.MuiTypography-root': { mb: 0 } }}>
                        {adults > 0 && <Typography variant="caption">{t('adults', { count: adults })}</Typography>}
                        {children > 0 && (
                            <Typography variant="caption">{t('children', { count: children })} </Typography>
                        )}
                        {unknown > 0 && (
                            <Typography variant="caption">{t('unknownFamilyMember', { count: unknown })} </Typography>
                        )}
                    </Stack>
                )
            }
        />
    );
};
