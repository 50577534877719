import { BaseClient, IOutStockReason, ISaveStockMovement, IStockMovement, shopApi } from '../../shared';

class StockMovementClient extends BaseClient<IStockMovement, ISaveStockMovement> {
    constructor() {
        super(shopApi, '/stock-movements');
    }

    public async getReasons(): Promise<IOutStockReason[]> {
        const { data } = await shopApi.get<IOutStockReason[]>(`/stock-movements/reasons`);
        return data;
    }
}

export const stockMovementsClient = new StockMovementClient();
