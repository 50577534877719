import { useMemo } from 'react';
import { boolean, number, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export function useSettingsSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                saleTicketMessage: string().optional(),
                showNumpad: boolean().required(),
                solidarityPriceIsPurchasePrice: boolean().required(),
                additionalSolidarityCharge: number()
                    .typeError(t('invalidNumber'))
                    .min(0, t('negativeNumber'))
                    .when('solidarityPriceIsPurchasePrice', {
                        is: false,
                        then: (schema) => schema.required(),
                        otherwise: (schema) => schema.nullable(),
                    })
                    .nullable()
                    .optional(),
            }),
        [t],
    );
}
