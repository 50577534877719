import { Button, DialogActions, DialogContent } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteProducts, DialogTitleWithClose, FormGrid, IProduct } from '../../../../shared';
import { IPurchase } from '../../../models';
import { PurchaseItemNewProductForm } from '../purchase-item-new-product-form/purchase-item-new-product-form.component';

interface Props {
    purchase: IPurchase;
    setProduct: (product: { product: IProduct; isNewProduct?: boolean } | undefined) => void;
    onClose: () => void;
}

export const SelectProduct: FC<Props> = ({ purchase, setProduct, onClose }) => {
    const { t } = useTranslation();
    const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined | null>();
    const [showNewProduct, setShowNewProduct] = useState(false);

    const handleNext = useCallback(() => {
        if (selectedProduct) {
            setProduct({ product: selectedProduct });
        }
    }, [selectedProduct, setProduct]);

    return (
        <>
            {showNewProduct ? (
                <PurchaseItemNewProductForm
                    onClose={onClose}
                    setShowNewProduct={setShowNewProduct}
                    purchase={purchase}
                />
            ) : (
                <>
                    <DialogTitleWithClose onClose={onClose} children={t('searchOrCreateProduct')} />
                    <DialogContent>
                        <AutocompleteProducts
                            selectedProduct={selectedProduct}
                            setSelectedProduct={setSelectedProduct}
                        />
                    </DialogContent>
                    <DialogActions>
                        <FormGrid xs={12} sm={6} justifyContent="space-between">
                            <Button variant="outlined" onClick={() => setShowNewProduct(true)} sx={{ width: '100%' }}>
                                {t('newProduct')}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ width: '100%' }}
                                disabled={!selectedProduct}
                            >
                                {t('shared.next')}
                            </Button>
                        </FormGrid>
                    </DialogActions>
                </>
            )}
        </>
    );
};
