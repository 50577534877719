import React, { FC } from 'react';
import { FormGrid, IParams, Page, useSelectedOrganisation, Widget } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSale, useSupportCaseInfo } from '../../../shop/hooks';
import { Link, Typography } from '@mui/material';
import {
    SaleDetailInformation,
    SaleDetailPayments,
    SaleDetailSaleItems,
    SaleDetailSupportCase,
} from '../../components';
import { useHasSupportCaseAccess } from '../../../shop/utils';

export const SaleDetail: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();
    const { data: sale, isPending } = useGetSale(id);
    const { data: supportCase } = useSupportCaseInfo(sale?.cardCode, sale?.organisationId);
    const hasSupportCaseAccess = useHasSupportCaseAccess(supportCase, organisation);

    return (
        <Page title={t('saleDetails')} onBack={() => navigate('/history/sales')} loading={isPending}>
            <FormGrid xs={12} sm={6}>
                <Widget
                    title={t('supportCaseInfo')}
                    actions={
                        <>
                            {hasSupportCaseAccess && (
                                <Link
                                    target="_blank"
                                    href={`${window.carity.environment.supportUrl}/support/support-cases/${supportCase?.supportCaseId}/detail`}
                                >
                                    <Typography variant="caption" fontWeight="bold">
                                        {t('goToDetailSupportCase')}
                                    </Typography>
                                </Link>
                            )}
                        </>
                    }
                >
                    <SaleDetailSupportCase supportCase={supportCase} sale={sale} />
                </Widget>

                <Widget title={t('saleInfo')}>{sale && <SaleDetailInformation sale={sale} />}</Widget>

                <Widget title={t('saleItems')} hasTable>
                    {sale?.saleItems && <SaleDetailSaleItems saleItems={sale.saleItems} />}
                </Widget>

                <Widget title={t('payments')} hasTable>
                    {sale?.payments && <SaleDetailPayments payments={sale.payments} />}
                </Widget>
            </FormGrid>
        </Page>
    );
};
