import React, { FC } from 'react';
import { ErrorOutlineOutlined, HighlightOffOutlined, TaskAltOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { LabelValue } from '../label-value/label-value.component';

interface Props {
    label: string;
    value: string;
    status: keyof typeof icons;
}

const icons = {
    success: (
        <Box className="icon" sx={{ backgroundColor: '#4caf50' }}>
            <TaskAltOutlined />
        </Box>
    ),
    warning: (
        <Box className="icon" sx={{ backgroundColor: '#ff9800' }}>
            <ErrorOutlineOutlined />
        </Box>
    ),
    error: (
        <Box className="icon" sx={{ backgroundColor: '#ef5350' }}>
            <HighlightOffOutlined />
        </Box>
    ),
};

export const Status: FC<Props> = ({ label, value, status }) => {
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                '.MuiBox-root.icon': {
                    px: '8px',
                    py: '2px',
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    '.MuiSvgIcon-root': { color: '#FFF', mt: 0, fontSize: '1.5em' },
                },
            }}
        >
            {icons[status]}
            <LabelValue label={label} value={value} />
        </Stack>
    );
};
