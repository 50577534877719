import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../shared';

export const ReturnAmount: FC<{ amount: number }> = ({ amount }) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="subtitle2" mb={0}>
                {t('returnAmount')}
            </Typography>
            <Typography>{formatPrice(Math.max(amount, 0))}</Typography>
        </Box>
    );
};
