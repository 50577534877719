import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, FormGrid, IProduct } from '../../../../shared';
import { useCreatePurchaseItem, useUpdatePurchaseItem } from '../../../hooks';
import { purchaseItemFromFormMapper, purchaseItemToFormMapper } from '../../../mappers';
import { IPurchase, IPurchaseItem, IPurchaseItemForm } from '../../../models';
import { usePurchaseItemSchema } from '../../../validators';
import { OverwritePriceInputs } from './overwrite-price-inputs/overwrite-price-inputs.component';
import { PurchaseItemInputs } from './purchase-item-inputs/purchase-item-inputs.component';

interface Props {
    purchase: IPurchase;
    purchaseItem?: IPurchaseItem;
    product?: IProduct;
    isNewProduct?: boolean;
    onClose: () => void;
    setProduct: (product: { product: IProduct; isNewProduct?: boolean } | undefined) => void;
}

export const PurchaseItemForm: FC<Props> = ({
    purchase,
    purchaseItem,
    product,
    isNewProduct = false,
    onClose,
    setProduct,
}) => {
    const { t } = useTranslation();
    const { mutateAsync: createPurchaseItem, isPending: isCreating } = useCreatePurchaseItem();
    const { mutateAsync: updatePurchaseItem, isPending: isUpdating } = useUpdatePurchaseItem();

    const currentProduct = useMemo(() => product || purchaseItem?.product, [product, purchaseItem]);

    const form = useForm<IPurchaseItemForm>({
        mode: 'all',
        resolver: yupResolver(usePurchaseItemSchema()),
    });

    useEffect(() => {
        if (purchaseItem) {
            form.reset(purchaseItemToFormMapper(purchaseItem));
        } else {
            form.reset({ taxIncluded: true, updateProductPrice: !isNewProduct });
        }
    }, [form, purchaseItem, product, isNewProduct]);

    const onSubmit = async (item: IPurchaseItemForm) => {
        if (purchaseItem?.id) {
            await updatePurchaseItem({
                id: purchaseItem.id,
                purchaseItem: purchaseItemFromFormMapper(purchase.id, item, purchaseItem.product),
            });
        } else if (product) {
            await createPurchaseItem(purchaseItemFromFormMapper(purchase.id, item, product));
        }
        onClose();
    };

    return (
        <>
            {currentProduct && (
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                        <DialogTitleWithClose
                            onClose={onClose}
                            children={t('createPurchaseItem', { product: currentProduct.name })}
                        />
                        <DialogContent>
                            <PurchaseItemInputs product={currentProduct} />
                            {!isNewProduct && (
                                <OverwritePriceInputs
                                    product={currentProduct}
                                    supplier={purchase.supplier}
                                    form={form}
                                />
                            )}
                        </DialogContent>
                        <DialogActions>
                            <FormGrid
                                xs={12}
                                sm={purchaseItem || isNewProduct ? 12 : 6}
                                justifyContent="space-between"
                                sx={{ button: { width: '100%' } }}
                            >
                                {!purchaseItem && !isNewProduct && (
                                    <Button
                                        variant="outlined"
                                        disabled={isCreating || isUpdating}
                                        onClick={() => setProduct(undefined)}
                                    >
                                        {t('shared.previous')}
                                    </Button>
                                )}
                                <Button variant="contained" type="submit" disabled={isCreating || isUpdating}>
                                    {t('savePurchaseItem')}
                                </Button>
                            </FormGrid>
                        </DialogActions>
                    </form>
                </FormProvider>
            )}
        </>
    );
};
