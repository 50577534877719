import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    ControlledInput,
    IParams,
    IShopCategoryForm,
    Page,
    RemoveModal,
    Section,
    useDeleteShopCategory,
    useSaveShopCategory,
    useSelectedOrganisation,
    useShopCategory,
} from '../../../shared';
import { useShopCategorySchema } from '../../validators';

export const ShopCategoryEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();

    const { data: shopCategory, isFetching } = useShopCategory(id);
    const { mutateAsync: saveShopCategory, isPending: isSaving } = useSaveShopCategory();
    const { mutateAsync: deleteShopCategory } = useDeleteShopCategory();

    const form = useForm<IShopCategoryForm>({
        mode: 'all',
        resolver: yupResolver(useShopCategorySchema()),
    });

    useEffect(() => {
        if (shopCategory) {
            form.reset(shopCategory);
        }
    }, [shopCategory, form.reset, form]);

    const onSubmit = useCallback(
        async (item: IShopCategoryForm) => {
            if (organisation?.id) {
                await saveShopCategory({
                    id,
                    item: {
                        ...item,
                        organisationId: organisation?.id,
                    },
                });
                navigate('/admin/shop-categories');
            }
        },
        [id, navigate, organisation?.id, saveShopCategory],
    );

    const onDelete = useCallback(async () => {
        await deleteShopCategory(id);
        navigate('/admin/shop-categories');
    }, [deleteShopCategory, navigate, id]);

    const actions = useMemo(
        () => [
            <Button
                key={'submit'}
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isFetching}
            >
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={
                            <Button color="primary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('deleteShopCategory')}
                        text={t('deleteShopCategoryText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate('/admin/shop-categories')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isFetching, t, id, onDelete, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={shopCategory?.id ? t('updateShopCategory') : t('newShopCategory')}
            loading={isSaving}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Card variant="outlined">
                        <CardContent>
                            <Section>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <ControlledInput name={`name`} label={t('name')} required />
                                        <ControlledCheckbox
                                            name={`show`}
                                            control={form.control}
                                            label={t('shopCategoryShow')}
                                            initialValue={true}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Section>
                        </CardContent>
                        <CardActions sx={{ backgroundColor: 'background.default' }} children={actions} />
                    </Card>
                </form>
            </FormProvider>
        </Page>
    );
};
