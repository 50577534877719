import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app.component';
import { IEnvironment } from './shared';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';

import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const initializeApp = async () => {
    const { data: environment } = await axios.get<IEnvironment>(`/environment`);

    window.carity = {
        environment,
    };

    if (environment.theme.externalStylesheets) {
        environment.theme.externalStylesheets.forEach((stylesheet) => {
            const linkTag = document.createElement('link');
            linkTag.setAttribute('rel', 'stylesheet');
            linkTag.setAttribute('href', stylesheet);
            document.head.append(linkTag);
        });
    }

    if (environment.sentryEnabled) {
        Sentry.init({
            dsn: environment.sentryUrl,
            integrations: [browserTracingIntegration()],
            environment: environment.environment,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,
        });
    }
};

initializeApp().then(() => {
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>,
    );
});
