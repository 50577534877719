import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatDateTimeString,
    formatPrice,
    Page,
    SortOrder,
    TableComponent,
    useGetRegisterSessionsList,
    useQueryParams,
    useSelectedOrganisation,
} from '../../../shared';
import { IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const RegisterSessionsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'startedDate', sort: 'desc' }],
    });

    const { data: registerSessions, isPending } = useGetRegisterSessionsList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'startedDate',
            headerName: t('startedDate'),
            flex: 1,
            minWidth: 150,
            valueFormatter: (value: string) => formatDateTimeString(value),
        },
        {
            field: 'stoppedDate',
            headerName: t('stoppedDate'),
            flex: 1,
            minWidth: 150,
            valueFormatter: (value: string) => formatDateTimeString(value) || '-',
            sortable: false,
        },
        {
            field: 'startedBy',
            headerName: t('startedBy'),
            flex: 1,
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'stoppedBy',
            headerName: t('stoppedBy'),
            flex: 1,
            minWidth: 150,
            valueFormatter: (value: string) => value || '-',
            sortable: false,
        },

        {
            field: 'totalCashAtStart',
            headerName: t('totalCashAtStart'),
            flex: 1,
            minWidth: 100,
            valueFormatter: (value: number) => formatPrice(value),
            sortable: false,
        },
        {
            field: 'totalCashAtStop',
            headerName: t('totalCashAtStop'),
            flex: 1,
            minWidth: 100,
            valueFormatter: (value: number) => (value ? formatPrice(value) : '-'),
        },
        {
            field: 'totalEarnings',
            headerName: t('totalEarnings'),
            flex: 1,
            minWidth: 100,
            valueFormatter: (value: number) => (value ? formatPrice(value) : '-'),
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 50,
            flex: 0.3,
            align: 'center',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <IconButton
                    onClick={(event) => {
                        event.stopPropagation();
                        navigate(`${row?.id}`);
                    }}
                >
                    <Search />
                </IconButton>
            ),
        },
    ];

    return (
        <Page title={t('registerSessions')}>
            <TableComponent
                rows={registerSessions?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={registerSessions?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={sort}
            />
        </Page>
    );
};
