import { Dialog } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { IProduct } from '../../../shared';
import { IPurchase, IPurchaseItem } from '../../models';
import { SelectProduct } from './select-product/select-product.component';
import { PurchaseItemForm } from './purchase-item-form/purchase-item-form.component';

interface Props {
    purchase: IPurchase;
    open: boolean;
    onClose: () => void;
    purchaseItem?: IPurchaseItem;
}
export const SavePurchaseItem: FC<Props> = ({ purchase, open, onClose, purchaseItem }) => {
    const [product, setProduct] = useState<{ product: IProduct; isNewProduct?: boolean } | undefined>();

    useEffect(() => {
        setProduct(undefined);
    }, [open]);

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
                {product || purchaseItem ? (
                    <PurchaseItemForm
                        product={product?.product}
                        isNewProduct={product?.isNewProduct}
                        purchaseItem={purchaseItem}
                        purchase={purchase}
                        onClose={onClose}
                        setProduct={setProduct}
                    />
                ) : (
                    <SelectProduct setProduct={setProduct} onClose={onClose} purchase={purchase} />
                )}
            </Dialog>
        </>
    );
};
