import { FormGrid } from '../form-grid/form-grid.component';
import { SelectMultipleCategories } from '../../../admin/components';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedOrganisation } from '../../contexts';
import { Loading } from '../loading/loading.component';
import { useCategoriesList, useShopCategoriesList, useSuppliersList } from '../../hooks';
import { Section } from '../section/section.component';
import { ProductDetailInputs } from './product-detail-inputs.component';
import { ProductPricingInputs } from './product-pricing-inputs.component';
import { ProductStockInputs } from './product-stock-inputs.component';

interface Props {
    prefixName?: string;
    slot?: ReactElement;
}

export const SaveProductInputs: FC<Props> = ({ prefixName = '', slot }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const { data: categories, isPending: isPendingCategories } = useCategoriesList({
        pageSize: 100,
    });

    const { data: shopCategories, isPending: isPendingShopCategories } = useShopCategoriesList({
        pageSize: 100,
        organisationId: organisation?.id,
    });

    const { data: suppliers, isPending: isPendingSuppliers } = useSuppliersList({
        pageSize: 100,
        organisationId: organisation?.id,
    });

    return (
        <>
            {isPendingCategories || isPendingShopCategories || isPendingSuppliers ? (
                <Loading />
            ) : (
                <>
                    <ProductDetailInputs categories={categories?.data || []} prefixName={prefixName} />
                    {slot}
                    <ProductPricingInputs prefixName={prefixName} />
                    <Section title={t('productDisplayDetails')}>
                        <FormGrid sm={12} md={6}>
                            <SelectMultipleCategories
                                options={shopCategories}
                                name={`${prefixName}shopCategoryIds`}
                                label={t('shopCategories')}
                                createLabel={'createdNewShopCategory'}
                                linkLabel={'createdNewShopCategoryLink'}
                                url={'/admin/shop-categories/new'}
                            />
                        </FormGrid>
                    </Section>
                    <ProductStockInputs suppliers={suppliers?.data || []} prefixName={prefixName} />
                </>
            )}
        </>
    );
};
