import { yupResolver } from '@hookform/resolvers/yup';
import { Delete } from '@mui/icons-material';
import { Button, IconButton, MenuItem, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    BudgetPeriod,
    ControlledNumberInput,
    ControlledSelect,
    IBudgetForm,
    IBudgetRule,
    MoneyInput,
    Page,
    useSelectedOrganisation,
    Widget,
} from '../../../shared';
import { useBudget, useUpdateBudget } from '../../hooks';
import { useBudgetSchema } from '../../validators';

export const BudgetPage: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const { data: budget, isFetching } = useBudget(organisation?.id);
    const { mutateAsync: updateBudget, isPending: isUpdating } = useUpdateBudget();

    const form = useForm<IBudgetForm>({
        mode: 'all',
        resolver: yupResolver(useBudgetSchema()),
    });
    const rules = useFieldArray({ name: 'rules', control: form.control });

    useEffect(() => {
        if (budget) {
            form.reset(budget);
        }
    }, [budget, form]);

    const onSubmit = useCallback(
        async (item: IBudgetForm) => {
            if (organisation?.id) {
                await updateBudget({
                    ...item,
                    organisationId: organisation.id,
                    rules: item.rules.map((x) => x as IBudgetRule),
                });
            }
        },
        [organisation?.id, updateBudget],
    );

    const actions = useMemo(
        () => [
            <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                key="save"
                disabled={isFetching || isUpdating}
            >
                {t('save')}
            </Button>,
        ],
        [form, onSubmit, isFetching, isUpdating, t],
    );

    return (
        <Page title={t('budgets')} actions={actions} loading={isFetching || isUpdating}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Widget footer={actions}>
                        <ControlledSelect label={t('period')} name="period" required>
                            {Object.values(BudgetPeriod).map((period) => (
                                <MenuItem key={period} value={period}>
                                    {t(period)}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                        <Stack spacing={2} maxWidth={'550px'}>
                            {rules.fields.map((field, index) => (
                                <Stack spacing={2} direction="row" key={field.id} alignItems="flex-start">
                                    <ControlledNumberInput
                                        name={`rules.${index}.amountOfPeople`}
                                        label={t('amountOfPeople')}
                                        required
                                    />
                                    <MoneyInput name={`rules.${index}.amount`} label={t('budget')} required />
                                    <IconButton onClick={() => rules.remove(index)}>
                                        <Delete sx={{ mt: 4 }} />
                                    </IconButton>
                                </Stack>
                            ))}
                            <Button
                                sx={{
                                    px: 0,
                                    maxWidth: 'fit-content',
                                    height: 'fit-content',
                                    textDecoration: 'underline',
                                }}
                                size="small"
                                onClick={() => rules.append({ amountOfPeople: undefined, amount: undefined })}
                            >
                                {t('addBudget')}
                            </Button>
                        </Stack>
                    </Widget>
                </form>
            </FormProvider>
        </Page>
    );
};
