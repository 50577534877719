import { CloudUploadTwoTone, DeleteOutline } from '@mui/icons-material';
import { Alert, Card, CardMedia, IconButton, Skeleton, Stack, styled, Typography } from '@mui/material';
import { transparentize } from 'polished';
import React, {
    ChangeEvent,
    DragEventHandler,
    FC,
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IProduct, RemoveModal, useDeleteProductImage, useUploadProductImage } from '../../../shared';
import { ImageUploadStatus } from '../../enums';

const FilePickerLabel = styled('label')(({ theme: { palette, spacing } }) => ({
    borderStyle: 'dashed',
    borderColor: palette.divider,
    borderWidth: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: spacing(4),
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
        borderColor: palette.primary.main,
        backgroundColor: transparentize(0.9, palette.primary.main),
    },
}));
const AccessibilitySpan = styled('span')(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    outlineOffset: '2px',
}));

const MAX_FILE_SIZE = 4 * 1024 * 1024; // 4MB
const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg'];

interface Props {
    product: IProduct;
}

export const UploadProductImage: FC<Props> = ({ product }) => {
    const { t } = useTranslation();
    const [uploadError, setUploadError] = useState<ImageUploadStatus>(ImageUploadStatus.OK);

    const { mutateAsync: uploadImage, isPending: uploading } = useUploadProductImage();
    const { mutateAsync: deleteImage, isPending: isDeleting } = useDeleteProductImage();

    const uniqueFileUploadLabelId = `file-upload-label`;
    const uniqueInputElementId = `hidden-input`;

    const onUploadAsset = useCallback(
        async (file?: File) => {
            if (file) {
                if (file.size > MAX_FILE_SIZE && !ALLOWED_FILE_TYPES.includes(file.type)) {
                    console.error(
                        `File size bigger than 4MB ==> ${file.size} and file type is not allowed ==> ${file.type}`,
                    );
                    return setUploadError(ImageUploadStatus.FILE_TOO_BIG_AND_WRONG_FILE_TYPE);
                }

                if (file.size > MAX_FILE_SIZE) {
                    console.error(`File size bigger than 4MB ==> ${file.size}`);
                    return setUploadError(ImageUploadStatus.FILE_TOO_BIG);
                }

                if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                    console.error(`File type is not allowed ==> ${file.type}`);
                    return setUploadError(ImageUploadStatus.WRONG_FILE_TYPE);
                }

                try {
                    await uploadImage({ productId: product.id, file });
                    setUploadError(ImageUploadStatus.OK);
                } catch (err) {
                    setUploadError(ImageUploadStatus.GENERIC_ERROR);
                }
            }
        },
        [product.id, uploadImage],
    );

    const onDeleteAsset = useCallback(async () => {
        await deleteImage(product.id);
    }, [deleteImage, product.id]);

    // Disable dragover event from the window to prevent loading the image in the browser when dropping
    useEffect(() => {
        const preventDefaultFunction = (event: DragEvent) => {
            event.preventDefault();
        };

        window.addEventListener('dragover', preventDefaultFunction);

        return () => window.removeEventListener('dragover', preventDefaultFunction);
    }, []);

    const handleDrop: DragEventHandler = useCallback(
        async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                await onUploadAsset(e.dataTransfer.files[0]);
            }
        },
        [onUploadAsset],
    );

    // Function used to make the file-upload accessible via keyboard events
    const onEnter: KeyboardEventHandler = useCallback(
        (event) => {
            if (event.code === 'Enter') {
                const el = document.getElementById(uniqueFileUploadLabelId);
                el?.click();
            }
        },
        [uniqueFileUploadLabelId],
    );

    return (
        <>
            <Typography variant="description">{t('imageForCheckoutSystem')}</Typography>

            {product.image ? (
                <Stack direction="row">
                    <Card sx={{ width: 150 }}>
                        <CardMedia
                            component="img"
                            height="100"
                            src={`/shop-api${product.image.downloadUrl}`}
                            alt="picture of product"
                            sx={{ width: '100%', objectFit: 'cover' }}
                        />
                    </Card>
                    <RemoveModal
                        handleDelete={onDeleteAsset}
                        button={
                            <IconButton
                                aria-label="delete"
                                disabled={isDeleting}
                                sx={{ height: 'fit-content', m: 'auto 2px' }}
                            >
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('deleteImageTitle')}
                        text={t('deleteImageDescription')}
                    />
                </Stack>
            ) : uploading ? (
                <Skeleton variant="rectangular" width={150} height={100} />
            ) : (
                <>
                    <FilePickerLabel htmlFor={uniqueInputElementId} onDrop={handleDrop} id={uniqueFileUploadLabelId}>
                        <AccessibilitySpan aria-controls={uniqueFileUploadLabelId} tabIndex={0} onKeyDown={onEnter} />

                        <CloudUploadTwoTone color="primary" fontSize="large" sx={{ mb: 1 }} />
                        <Typography>{t('uploadImage')}</Typography>
                    </FilePickerLabel>
                    <input
                        id={uniqueInputElementId}
                        type="file"
                        hidden
                        accept={ALLOWED_FILE_TYPES.join(',')}
                        disabled={uploading}
                        multiple={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onUploadAsset(e.target.files?.[0])}
                    />
                    <Typography variant="caption" sx={{ mt: '8px!important' }}>
                        {t('maxFileSize')} 4MB
                    </Typography>
                    {uploadError !== ImageUploadStatus.OK && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {t(`uploadFailed.${uploadError}`)}
                        </Alert>
                    )}
                </>
            )}
        </>
    );
};
