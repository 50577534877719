import { ISupportCaseInfo } from '../models';
import { useMemo } from 'react';

export function useCountFamilyMembers(supportCase: ISupportCaseInfo | undefined): {
    adults: number;
    children: number;
    unknown: number;
} {
    return useMemo(() => {
        return (supportCase?.supportees || []).reduce(
            (counter, supportee) => {
                if (!supportee.age) {
                    !supportee?.relation
                        ? counter.unknown++
                        : supportee.relation.toLowerCase() === 'child'
                          ? counter.children++
                          : counter.adults++;
                } else if (supportee.age >= 18) {
                    counter.adults++;
                } else if (supportee.age < 18) {
                    counter.children++;
                }
                return counter;
            },
            { adults: 0, children: 0, unknown: 0 },
        );
    }, [supportCase]);
}
