import { ICreateSaleItem, IProduct, MeasurementUnit, SelectedProductType } from '../../shared';
import { ISupportCaseInfo } from '../models';

export const saleItemFromFormMapper = (
    product: IProduct | SelectedProductType,
    organisationId: string,
    amount: number,
    price: number,
    supportCase?: ISupportCaseInfo,
): ICreateSaleItem => {
    const isGram = product.measurementUnit === MeasurementUnit.GRAM;
    const mappedPrice = isGram ? price / 1000 : price;
    const productPrice = supportCase?.cardCode ? product.price : product.solidarityPrice;

    return {
        productId: product.id || undefined,
        barcode: ('barcode' in product && product?.barcode) || undefined,
        organisationId: organisationId,
        cardCode: supportCase?.cardCode,
        supportCaseId: supportCase?.supportCaseId || undefined,
        amountOfPeople: supportCase?.supportees.length || undefined,
        amount: isGram ? amount * 1000 : amount,
        alteredProductPrice: !product.hasPrice ? mappedPrice : productPrice !== price ? mappedPrice : undefined,
    };
};
