import { MeasurementUnit } from '../../shared';

export function validateCurrentAmount(
    currentAmount: number | string,
    measurementUnit?: MeasurementUnit | undefined,
    isPrice = false,
) {
    const amount = currentAmount.toString();

    if (amount && isPrice) {
        return {
            disableSeparator: amount.includes('.'),
            disableButtons: amount.includes('.') && amount.slice(0, -2).endsWith('.'),
        };
    } else if (amount && measurementUnit === MeasurementUnit.GRAM) {
        return {
            disableSeparator: amount.includes('.'),
            disableButtons: amount.includes('.') && amount.slice(0, -3).endsWith('.'),
        };
    } else {
        return { disableSeparator: true, disableButtons: false };
    }
}

export function getNumericValueFromNumpad(input: string, currentValue: string | number) {
    const value = currentValue.toString();

    if (value) {
        if (input === 'remove') {
            if (value.length === 1) return '0';
            return value.slice(0, -1).endsWith('.') ? value.slice(0, -2) : value.slice(0, -1);
        }

        if ([',', '.'].includes(input)) return value.concat('.');

        if (value === '0') return input;

        return value.concat(input);
    } else {
        if (input === 'remove') return '0';

        return input;
    }
}
