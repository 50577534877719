import {
    IOrganisation,
    IOutStockWithReasonMovementForm,
    IProduct,
    ISaveStockMovement,
    MeasurementUnit,
    StockMovementType,
} from '../../shared';

export const outStockWithReasonFromFormMapper = (
    item: IOutStockWithReasonMovementForm,
    product: IProduct,
    organisation: IOrganisation,
): ISaveStockMovement => {
    return {
        ...item,
        productId: item.product.id,
        amount: product?.measurementUnit === MeasurementUnit.GRAM ? item.amount * 1000 : item.amount,
        organisationId: organisation?.id,
        type: StockMovementType.OUT_STOCK,
    };
};
