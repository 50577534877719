import { Button, CircularProgress, DialogContent, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../admin/hooks';
import { FormGrid, ISale, PaymentMethod, useSelectedOrganisation } from '../../../shared';
import { useCompleteCurrentSale } from '../../hooks';
import { ISavePayment } from '../../models';
import { roundReturnAmount, validateCurrentAmount } from '../../utils';
import { InputNumpad } from '../input-numpad/input-numpad.component';
import { PaymentMethods } from '../payment-methods/payment-methods.component';
import { ReceivedAmount } from '../received-amount/received-amount.component';
import { ReturnAmount } from '../return-amount/return-amount.component';
import { TotalPrice } from '../total-price/total-price.component';

interface Props {
    currentSale: ISale;
    onClose: () => void;
    payment?: ISavePayment | null;
    hasCreditPayment: boolean;
    handlePrevious: () => void;
}

export const CashAndDigitalPayment: FC<Props> = ({
    currentSale,
    onClose,
    payment,
    hasCreditPayment,
    handlePrevious,
}) => {
    const { t } = useTranslation();
    const { mutateAsync: completeCurrentSale, isPending } = useCompleteCurrentSale();
    const { organisation } = useSelectedOrganisation();
    const { data: settings } = useSettings(organisation?.id);

    const [totalPrice, setTotalPrice] = useState<number>(currentSale.totalPrice);
    const [receivedAmount, setReceivedAmount] = useState<string>('0');
    const [returnAmount, setReturnAmount] = useState<number>(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CASH);
    useEffect(() => {
        if (receivedAmount && currentSale.totalPrice > 0) {
            const difference = parseFloat((parseFloat(receivedAmount) - totalPrice).toFixed(2));
            if (selectedPaymentMethod !== PaymentMethod.CASH) {
                setReturnAmount(difference);
            } else {
                setReturnAmount(roundReturnAmount(difference));
            }
        } else {
            setReturnAmount(roundReturnAmount(-totalPrice));
        }
    }, [currentSale.totalPrice, receivedAmount, selectedPaymentMethod, totalPrice]);

    const isCashPayment = useMemo(() => {
        return selectedPaymentMethod === PaymentMethod.CASH;
    }, [selectedPaymentMethod]);

    const handleClose = useCallback(async () => {
        onClose();
        setReceivedAmount('0');
        setReturnAmount(0);
    }, [onClose]);

    const onSubmit = useCallback(async () => {
        if (currentSale) {
            await completeCurrentSale({
                saleId: currentSale.id,
                completeSale: {
                    payments: [
                        ...(payment ? [payment] : []),
                        {
                            method: selectedPaymentMethod,
                            paidAmount: totalPrice,
                            receivedAmount: isCashPayment ? parseFloat(receivedAmount) : totalPrice,
                            returnedAmount: isCashPayment ? returnAmount : 0,
                        },
                    ],
                },
            });
        }
    }, [
        payment,
        currentSale,
        completeCurrentSale,
        totalPrice,
        selectedPaymentMethod,
        receivedAmount,
        isCashPayment,
        returnAmount,
    ]);

    return (
        <DialogContent>
            <FormGrid xs={6}>
                <Stack direction="column" justifyContent="space-between" spacing={2}>
                    <PaymentMethods paymentMethod={(method) => setSelectedPaymentMethod(method)} />
                    <TotalPrice
                        creditPayment={payment}
                        currentSale={currentSale}
                        isCashPayment={isCashPayment}
                        isReturnPayment={currentSale.totalPrice <= 0}
                        setTotalPrice={setTotalPrice}
                    />

                    {isCashPayment && totalPrice !== 0 && (
                        <>
                            {totalPrice > 0 && <ReceivedAmount amount={receivedAmount} setAmount={setReceivedAmount} />}
                            <ReturnAmount amount={returnAmount} />
                        </>
                    )}
                </Stack>
                {isCashPayment && totalPrice > 0 && (
                    <InputNumpad
                        value={receivedAmount}
                        onChange={setReceivedAmount}
                        disable={validateCurrentAmount(receivedAmount, undefined, true)}
                        showNumpad={settings?.showNumpad}
                    />
                )}
            </FormGrid>

            <Stack direction="row" mt={3} spacing={2} pr={4}>
                {hasCreditPayment ? (
                    <Button variant="outlined" onClick={handlePrevious} disabled={isPending} fullWidth>
                        {t('previous')}
                    </Button>
                ) : (
                    <Button variant="outlined" onClick={handleClose} disabled={isPending} fullWidth>
                        {t('cancel')}
                    </Button>
                )}
                <Button
                    variant="contained"
                    disabled={isPending || (totalPrice > parseFloat(receivedAmount) && isCashPayment)}
                    onClick={onSubmit}
                    startIcon={isPending && <CircularProgress color="inherit" size={25} />}
                    fullWidth
                >
                    {t('confirm')}
                </Button>
            </Stack>
        </DialogContent>
    );
};
