import { MenuItem, Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledDatePicker,
    ControlledSelect,
    ISalesQuery,
    Options,
    OptionsToggle,
    SaleStatus,
} from '../../../shared';

interface Props {
    filter: ISalesQuery;
    onChange: (filter: ISalesQuery) => void;
}

export const HistoryFilterSales: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<ISalesQuery>({ mode: 'onChange' });

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        updatedDate: { label: t('date'), active: false },
        status: { label: t('status'), active: false },
        hasSupportCase: { label: t('customerType'), active: false },
    });

    const formValues = form.watch();

    useEffect(() => {
        const { updatedDate, status, hasSupportCase } = options;
        if (!updatedDate.active) form.setValue('updatedDate', undefined);
        if (!status.active) form.setValue('status', undefined);
        if (!hasSupportCase.active) form.setValue('hasSupportCase', undefined);
    }, [form, options]);

    useEffect(() => {
        if (JSON.stringify(formValues) !== JSON.stringify(filter)) {
            onChange(formValues);
        }
    }, [formValues, filter, onChange]);

    return (
        <FormProvider {...form}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <OptionsToggle options={options} onChange={setOptions} />
                {options.updatedDate.active && (
                    <ControlledDatePicker name="updatedDate" label={t('date')} className="filter" />
                )}
                {options.status.active && (
                    <ControlledSelect name="status" label={t('status')} className="filter">
                        {Object.values(SaleStatus)?.map((status) => (
                            <MenuItem value={status} key={status}>
                                {t(status)}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}
                {options.hasSupportCase.active && (
                    <ControlledSelect name="hasSupportCase" label={t('customerType')} className="filter">
                        <MenuItem value={'true'} key={'socialCustomer'}>
                            {t('socialCustomer')}
                        </MenuItem>
                        <MenuItem value={'false'} key={'solidarityCustomer'}>
                            {t('solidarityCustomer')}
                        </MenuItem>
                    </ControlledSelect>
                )}
            </Stack>
        </FormProvider>
    );
};
