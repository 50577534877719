import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, mixed, number, object, string } from 'yup';
import { IProduct } from '../../shared';

export function useReturnProduct() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                product: mixed<IProduct>(),
                productName: string(),
                productPrice: number().min(0, t('negativeNumber')).typeError(t('invalidNumber')).required(),
                amount: number().min(0, t('negativeNumber')).typeError(t('invalidNumber')).required(),
                tax: number().min(0, t('negativeNumber')).typeError(t('invalidNumber')).required(),
                excludeFromBudget: boolean().required(),
            }),
        [t],
    );
}
