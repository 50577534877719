import { Button, Dialog } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IRegisterSession,
    PermissionKeys,
    QueryKeys,
    SaleStatus,
    useHasPermission,
    useSelectedOrganisation,
} from '../../../shared';
import { useGetSalesList } from '../../hooks';
import { StopRegisterSessionForm } from '../stop-register-session-form/stop-register-session-form.component';
import { StopRegisterSessionOverview } from '../stop-register-session-overview/stop-register-session-overview.component';

interface Props {
    registerSession: IRegisterSession;
}
export const StopRegisterSession: FC<Props> = ({ registerSession }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [showOverview, setShowOverview] = useState<boolean>(false);

    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.REGISTER_SESSIONS_WRITE);

    const { organisation } = useSelectedOrganisation();
    const { data: waitingCustomers } = useGetSalesList({
        organisationId: organisation?.id,
        status: SaleStatus.ON_HOLD,
    });

    const queryClient = useQueryClient();
    const handleOpen = async () => {
        setOpen(true);
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.REGISTER_SESSION, registerSession.organisationId] });
    };

    useEffect(() => {
        setShowOverview(false);
    }, [open]);

    const goToOverview = () => {
        setShowOverview(true);
    };

    return (
        <>
            <Button
                variant="contained"
                sx={{ width: '100%' }}
                onClick={handleOpen}
                disabled={!hasWritePermission || (waitingCustomers?.pagination.size || 0) > 0}
            >
                {t('stopRegisterSession')}
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
                {!showOverview ? (
                    <StopRegisterSessionForm
                        registerSession={registerSession}
                        onClose={() => setOpen(false)}
                        onSubmit={goToOverview}
                    />
                ) : (
                    <StopRegisterSessionOverview
                        registerSession={registerSession}
                        goBack={() => setShowOverview(false)}
                        onClose={() => setOpen(false)}
                    />
                )}
            </Dialog>
        </>
    );
};
