import {
    IShopCategory,
    IShopCategoryQueryParam,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../index';
import { shopCategoriesClient } from '../clients';
import { QueryKeys } from '../enums';

const client = shopCategoriesClient;
const listKey = QueryKeys.SHOP_CATEGORIES;
const itemKey = QueryKeys.SHOP_CATEGORY;

export function useShopCategoriesList(...args: UseListParams<IShopCategory, IShopCategoryQueryParam>) {
    return useList(client, listKey, ...args);
}

export function useShopCategory(...args: UseItemParams<IShopCategory>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveShopCategory() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteShopCategory() {
    return useDelete(client, listKey);
}
