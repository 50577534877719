export enum PermissionKeys {
    PRODUCTS_READ = 'products-read',
    PRODUCTS_WRITE = 'products-write',
    CATEGORIES_READ = 'categories-read',
    CATEGORIES_WRITE = 'categories-write',
    SHOP_CATEGORIES_READ = 'shop-categories-read',
    SHOP_CATEGORIES_WRITE = 'shop-categories-write',
    SUPPLIERS_READ = 'suppliers-read',
    SUPPLIERS_WRITE = 'suppliers-write',
    PURCHASES_READ = 'purchases-read',
    PURCHASES_WRITE = 'purchases-write',
    SETTINGS_WRITE = 'settings-write',
    REGISTER_SESSIONS_READ = 'register-sessions-read',
    REGISTER_SESSIONS_WRITE = 'register-sessions-write',
    SALES_READ = 'sales-read',
    SALE_QUOTA_READ = 'sale-quota-read',
    SALE_QUOTA_WRITE = 'sale-quota-write',
    STOCK_MOVEMENTS_READ = 'stock-movements-read',
    STOCK_MOVEMENTS_WRITE = 'stock-movements-write',
    BUDGETS_WRITE = 'budgets-write',
    SHOPPING_LISTS_WRITE = 'shopping-lists-write',
    ALTER_PRODUCT_PRICE = 'alter-product-price',
    SUPPORT_APP = 'support-app',
    SUPPORT_CASES_READ = 'support-cases-read',
}
