import scanner from 'onscan.js';
import React, { FC, useEffect } from 'react';
import { Page, useCurrentRegisterSession, useSelectedOrganisation, useShop } from '../../../shared';
import { Register, StartNewSale, StartRegisterSession } from '../../components';
import { useCurrentSale, useOnScan, useSupportCaseInfo } from '../../hooks';

export const ShopPage: FC = () => {
    const { organisation } = useSelectedOrganisation();
    const { data: currentRegisterSession, isPending: currentRegisterSessionIsPending } = useCurrentRegisterSession(
        organisation?.id,
    );
    const { data: currentSale } = useCurrentSale(organisation?.id);
    const { cardCode, setCardCode, setShowWarningNoSupportCase, isSolidaritySale } = useShop();
    const {
        data: supportCase,
        isPending,
        isError: supportCaseFailedToLoad,
    } = useSupportCaseInfo(cardCode, organisation?.id);

    const onScan = useOnScan(supportCase);
    const supportCaseIsPending = isPending && !!cardCode && !!organisation;

    useEffect(() => {
        setShowWarningNoSupportCase(supportCaseFailedToLoad);
    }, [supportCaseFailedToLoad, setShowWarningNoSupportCase]);

    useEffect(() => {
        if (currentSale) {
            setCardCode(currentSale.cardCode);
        }
    }, [currentSale, setCardCode]);

    useEffect(() => {
        scanner.attachTo(document, { onScan, minLength: 4 });
        return () => scanner.detachFrom(document);
    }, [onScan]);

    return (
        <Page loading={currentRegisterSessionIsPending || supportCaseIsPending}>
            {!currentRegisterSession ? (
                <StartRegisterSession />
            ) : (
                <>
                    {supportCase || isSolidaritySale || currentSale ? (
                        <Register supportCase={supportCase} currentSale={currentSale} />
                    ) : (
                        <StartNewSale />
                    )}
                </>
            )}
        </Page>
    );
};
