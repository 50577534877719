import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose } from '../../../shared';

interface Props {
    show: boolean;
    barcode?: string;
    onClose: () => void;
}
export const WarningProductNotFound: FC<Props> = ({ show, onClose, barcode }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={show}>
            <DialogTitleWithClose onClose={onClose}>{t('productNotFoundTitle', { barcode })}</DialogTitleWithClose>
            <DialogContent>
                <Typography sx={{ mb: 1, whiteSpace: 'pre-line' }}>{t('productNotFoundDescription')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
