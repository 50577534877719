import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, mixed, number, object, string } from 'yup';
import { IProduct, ISaleQuotumRuleForm, SaleQuotumPeriod } from '../../shared';

export function useSaleQuotumSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                product: mixed<IProduct>().required(),
                period: mixed<SaleQuotumPeriod>().oneOf(Object.values(SaleQuotumPeriod), t('requiredField')).required(),
                rules: array()
                    .min(1)
                    .of(
                        object<ISaleQuotumRuleForm>()
                            .shape({
                                id: string().optional(),
                                amount: number().required().optional().typeError(t('invalidNumber')),
                                amountOfPeople: number().required().optional().typeError(t('invalidNumber')),
                            })
                            .required(),
                    )
                    .required(),
            }),
        [t],
    );
}
