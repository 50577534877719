import { IProduct, IProductQuery, ISaveProduct, ListModel, productMapper } from '../index';
import { BaseClient } from './base.client';
import { shopApi } from './shop-api.client';

class ProductsClient extends BaseClient<IProduct, ISaveProduct, IProductQuery> {
    constructor() {
        super(shopApi, '/products');
    }

    public async getList({ pageSize = 10, ...query }: IProductQuery): Promise<ListModel<IProduct>> {
        const products = await super.getList({ pageSize, ...query });
        products.data = products.data.map((product) => productMapper(product));
        return products;
    }

    public async getOne(id?: string): Promise<IProduct> {
        return productMapper(await super.getOne(id));
    }

    public async uploadImage(productId: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('image', file);
        await shopApi.post(`/products/${productId}/image`, formData);
    }

    public async deleteImage(productId: string): Promise<void> {
        await shopApi.delete(`/products/${productId}/image`);
    }
}

export const productsClient = new ProductsClient();
