import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import { HistoryPage, RegisterSessionDetail, RegisterSessionsPage, SaleDetail, SalesPage } from './pages';

export const historyRoutes: IRouteConfig[] = [
    { path: '/history', redirect: '/history/sales', requiredPermissions: [PermissionKeys.SALES_READ] },
    { path: '/history/*', component: HistoryPage },
];

export const historySubRoutes: IRouteConfigWithComponent[] = [
    { path: '/sales', component: SalesPage, requiredPermissions: [PermissionKeys.SALES_READ] },
    { path: '/sales/:id', component: SaleDetail, requiredPermissions: [PermissionKeys.SALES_READ] },

    {
        path: '/register-sessions',
        component: RegisterSessionsPage,
        requiredPermissions: [PermissionKeys.REGISTER_SESSIONS_READ],
    },
    {
        path: '/register-sessions/:id',
        component: RegisterSessionDetail,
        requiredPermissions: [PermissionKeys.REGISTER_SESSIONS_WRITE],
    },
];
