import { ISupplier, ISaveSupplier } from '../models';
import { BaseClient } from './base.client';
import { shopApi } from './shop-api.client';

class SuppliersClient extends BaseClient<ISupplier, ISaveSupplier> {
    constructor() {
        super(shopApi, '/suppliers');
    }
}

export const suppliersClient = new SuppliersClient();
