import { CheckCircleOutline } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import printJS from 'print-js';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ISale } from '../../../shared';

interface Props {
    currentSale: ISale;
    open: boolean;
    onClose: () => void;
}

export const PaymentSuccessful: FC<Props> = ({ currentSale, open, onClose }) => {
    const { t } = useTranslation();
    window.onfocus = () => window.document.getElementById('done')?.focus();

    const onPrint = useCallback(() => {
        printJS({
            printable: `/shop-api/sales/${currentSale.id}/ticket`,
            showModal: true,
            modalMessage: t('downloadingSaleTicket'),
            type: 'pdf',
        });
    }, [t, currentSale.id]);

    useEffect(() => {
        if (open) {
            onPrint();
        }
    }, [open, onPrint]);

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '300px',
                    },
                },
            }}
        >
            <DialogContent sx={{ pb: 3 }}>
                <Stack alignItems="center" spacing={1}>
                    <CheckCircleOutline color="success" fontSize="large" />
                    <Typography>{t('paymentSuccessful')}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ pb: 3 }}>
                <Stack spacing={1} sx={{ width: '100%' }}>
                    <Button variant="contained" onClick={onPrint}>
                        {t('printTicket')}
                    </Button>
                    <Button onClick={onClose} id="done">
                        {t('done')}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
