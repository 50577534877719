import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledInput,
    ControlledSelect,
    IParams,
    ISaveCategory,
    Page,
    RemoveModal,
    Section,
    useCategory,
    useDeleteCategory,
    useSaveCategory,
} from '../../../shared';
import { Button, Card, CardActions, CardContent, Grid, MenuItem } from '@mui/material';
import { ProductTaxes } from '../../enums';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCategorySchema } from '../../validators';
import { FormProvider, useForm } from 'react-hook-form';

export const CategoryEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;

    const { data: category, isFetching } = useCategory(id);
    const { mutateAsync: saveCategory, isPending: isSaving } = useSaveCategory();
    const { mutateAsync: deleteCategory } = useDeleteCategory();

    const form = useForm<ISaveCategory>({
        mode: 'all',
        resolver: yupResolver(useCategorySchema()),
    });

    useEffect(() => {
        if (category) {
            form.reset(category);
        }
    }, [category, form]);

    const onSubmit = useCallback(
        async (item: ISaveCategory) => {
            await saveCategory({ id, item });
            navigate('/admin/categories');
        },
        [id, navigate, saveCategory],
    );

    const onDelete = useCallback(async () => {
        await deleteCategory(id);
        navigate('/admin/categories');
    }, [deleteCategory, navigate, id]);

    const actions = useMemo(
        () => [
            <Button
                key={'submit'}
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isFetching}
            >
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={
                            <Button color="primary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('deleteCategory')}
                        text={t('deleteCategoryText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate('/admin/categories')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isFetching, t, id, onDelete, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={category?.id ? t('updateCategory') : t('newCategory')}
            loading={isSaving}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Card variant="outlined">
                        <CardContent>
                            <Section>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <ControlledInput name={`name`} label={t('name')} required />
                                        <ControlledSelect name="tax" label={t('productTax')}>
                                            <MenuItem value={''} key={''} sx={{ height: '30px' }} />
                                            {Object.values(ProductTaxes)?.map((value) => (
                                                <MenuItem value={parseInt(value)} key={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </ControlledSelect>
                                    </Grid>
                                </Grid>
                            </Section>
                        </CardContent>

                        <CardActions sx={{ backgroundColor: 'background.default' }}>{actions}</CardActions>
                    </Card>
                </form>
            </FormProvider>
        </Page>
    );
};
