import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKeys } from '../../shared';
import { supportCaseClient } from '../clients';
import { ISupportCaseInfo } from '../models';

const client = supportCaseClient;
const itemKey = QueryKeys.SUPPORT_CASE_INFO;

export function useSupportCaseInfo(code?: string, organisation?: string) {
    return useQuery<ISupportCaseInfo, AxiosError>({
        queryKey: [itemKey, { code, organisation }],
        queryFn: () => client.getInfo(code, organisation),
        enabled: !!code && !!organisation,
        retry: false,
    });
}
