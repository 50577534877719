import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Login, LogoutPage, Unauthenticated } from '../../../auth';
import {
    DrawerContextProvider,
    Loading,
    PermissionsContext,
    SelectedOrganisationProvider,
    ShopProvider,
    useGetCurrentUser,
    useGetUserRoles,
} from '../../../shared';
import { Header } from '../header/header.component';
import { Routes } from '../routes/routes.component';

export const Main: FC = () => {
    const { data: user, isPending } = useGetCurrentUser();
    const { data: roles } = useGetUserRoles(user?.data?.id);
    const location = useLocation();

    if (isPending) return <Loading fullscreen />;

    if (location.pathname === '/unauthenticated') return <Unauthenticated />;

    if (location?.pathname === '/logout') return <LogoutPage />;

    if (!user?.success) return <Login />;

    return (
        <PermissionsContext.Provider value={{ permissions: roles?.items }}>
            <SelectedOrganisationProvider>
                <ShopProvider>
                    <DrawerContextProvider>
                        <Header />
                        <Routes />
                    </DrawerContextProvider>
                </ShopProvider>
            </SelectedOrganisationProvider>
        </PermissionsContext.Provider>
    );
};
