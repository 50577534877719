import { formatAmount, formatProductPrice, FormGrid, IProduct, LabelValue, Section, Widget } from '../../../shared';
import { ListAltTwoTone } from '@mui/icons-material';
import { UploadProductImage } from '../upload-product-image/upload-product-image.component';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    product: IProduct;
}

export const ProductDetails: FC<Props> = ({ product }) => {
    const { t } = useTranslation();

    return (
        <Widget title={t('product')} icon={<ListAltTwoTone color="primary" />}>
            <Section title={t('productDetails')}>
                <FormGrid xs={12} sm={6} md={4}>
                    <LabelValue label={t('name')} value={product?.name} />
                    <LabelValue label={t('barcodes')} value={product?.barcodes?.join(', ')} />
                    <LabelValue label={t('category')} value={product?.category?.name} />
                    <LabelValue label={t('productTax')} value={`${product?.tax?.toString()} %`} />
                    <LabelValue label={t('measurementUnit')} value={t(product.measurementUnit)} />
                </FormGrid>
            </Section>

            <Section title={t('pricing')}>
                <FormGrid xs={12} sm={6} md={4}>
                    <LabelValue
                        label={t('productPrice')}
                        value={
                            product?.price !== undefined && product?.price !== null
                                ? formatProductPrice(product?.measurementUnit, product?.price, t)
                                : undefined
                        }
                    />
                    <LabelValue
                        label={t('solidarityProductPrice')}
                        value={
                            product?.solidarityPrice !== undefined && product?.solidarityPrice !== null
                                ? formatProductPrice(product?.measurementUnit, product?.solidarityPrice, t)
                                : undefined
                        }
                    />
                    <LabelValue
                        label={t('excludeFromBudgetText')}
                        value={product?.excludeFromBudget ? t('yes') : t('no')}
                    />
                </FormGrid>
            </Section>

            <Section title={t('productDisplayDetails')}>
                <LabelValue
                    label={t('shopCategories')}
                    value={product?.shopCategories?.map(({ name }) => name).join(', ')}
                    sx={{ pl: 2 }}
                />
            </Section>

            <Section title={t('productStockDetails')}>
                <FormGrid xs={12} sm={6} md={4}>
                    <LabelValue
                        label={t('stockCount')}
                        value={
                            <>
                                {product?.stockCount
                                    ? formatAmount(product?.measurementUnit, product?.stockCount, t)
                                    : t('outOfStock')}
                            </>
                        }
                    />
                    <LabelValue
                        label={t('minExpectedStockTarget')}
                        value={formatAmount(product?.measurementUnit, product?.stockTarget, t)}
                    />
                    <LabelValue
                        label={t('standardOrderAmount')}
                        value={formatAmount(product?.measurementUnit, product?.standardOrderAmount, t)}
                    />
                    <LabelValue label={t('amountPerColli')} value={product?.amountPerColli?.toString()} />
                    <LabelValue label={t('storeOrder')} value={product?.storeOrder?.toString()} />
                    <LabelValue label={t('standardSupplier')} value={product?.supplier?.name} />
                    <LabelValue label={t('articleNumberSupplier')} value={product?.articleNumberSupplier} />
                </FormGrid>
            </Section>

            {product && <UploadProductImage product={product} />}
        </Widget>
    );
};
