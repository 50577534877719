import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ICurrentUserResponse } from '../models';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../enums';

async function getCurrentUser(onGetErrorMessage?: string): Promise<ICurrentUserResponse> {
    const { data } = await axios.get('/auth/user', { errorMessage: onGetErrorMessage });
    return data;
}

export const useGetCurrentUser = () => {
    const { t } = useTranslation();
    return useQuery<ICurrentUserResponse, AxiosError>({
        queryKey: [QueryKeys.AUTH_USER],
        queryFn: () => getCurrentUser(t('getUserError')),
    });
};
