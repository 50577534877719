import { IBudget, shopApi } from '../../shared';

class BudgetsClient {
    public async getBudget(organisationId?: string): Promise<IBudget> {
        const { data } = await shopApi.get(`/budgets?organisationId=${organisationId}`);
        return data;
    }

    public async updateBudget(budget: IBudget): Promise<IBudget> {
        const { data } = await shopApi.post(`/budgets`, budget);
        return data;
    }
}

export const budgetsClient = new BudgetsClient();
