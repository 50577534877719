import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';

export function useCreditSchema(totalPrice: number) {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                amount: number()
                    .transform((value) => (value ? value : null))
                    .max(totalPrice, t('totalPriceExceeded'))
                    .test('creditExceeded', t('creditExceeded'), (value, context) => {
                        return (
                            Number(value) <=
                            (context.parent.supportTypeInfo?.credit || 0) -
                                (context.parent.supportTypeInfo?.spendCredit || 0)
                        );
                    })
                    .nullable(),
                supportTypeInfo: object()
                    .shape({
                        credit: number(),
                        supportTypeId: string().required(),
                        supportPeriodId: string().required(),
                    })
                    .required(),
            }),
        [t, totalPrice],
    );
}
