import { DataGrid, GridFeatureMode, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { ComponentProps, Dispatch } from 'react';
import { CustomTableFooter } from '../table-footer/table-footer.component';

interface Props extends ComponentProps<typeof DataGrid> {
    sortModel?: GridSortItem[];
    onSortModelChange?: Dispatch<GridSortModel>;
    defaultSortModel?: GridSortItem[];
    page?: number;
    setPage: (page: number) => void;
    pageSize?: number;
    setPageSize: (pageSize: number) => void;
    mode?: GridFeatureMode;
}
export function TableComponent({
    onSortModelChange,
    defaultSortModel,
    page,
    setPage,
    pageSize = 5,
    setPageSize,
    mode = 'server',
    ...props
}: Props) {
    const handleSortModelChange = (newModel: GridSortModel) => {
        if (onSortModelChange && defaultSortModel && props.sortModel) {
            if (!newModel[0]) {
                onSortModelChange(defaultSortModel);
            } else if (newModel[0].sort !== props.sortModel[0].sort || newModel[0].field !== props.sortModel[0].field) {
                onSortModelChange(newModel);
            }
        }
    };

    return (
        <DataGrid
            autoHeight
            pagination
            paginationMode={mode}
            sortingMode={mode}
            sortingOrder={['asc', 'desc']}
            onSortModelChange={handleSortModelChange}
            disableColumnFilter
            disableColumnMenu
            pageSizeOptions={props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 20]}
            paginationModel={{ page: page ? page - 1 : 0, pageSize: pageSize }}
            onPaginationModelChange={(value) => {
                setPage(value?.page + 1);
                setPageSize(value?.pageSize || 5);
            }}
            slots={{ footer: CustomTableFooter }}
            {...props}
        />
    );
}
