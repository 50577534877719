import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { useShowDrawer } from '../../../shared';
import { AppTitle } from '../app-title/app-title.component';
import { Navigation } from '../navigation/navigation.component';

export function Header() {
    const { showDrawer, setShowDrawer, hasDrawer } = useShowDrawer();

    return (
        <AppBar>
            <Toolbar>
                <AppTitle />
                {hasDrawer && (
                    <IconButton
                        color="inherit"
                        aria-label="open side navigation"
                        edge="start"
                        onClick={() => setShowDrawer(!showDrawer)}
                        sx={{ ml: 1, mr: 2, display: { xl: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Navigation />
            </Toolbar>
        </AppBar>
    );
}
