import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = ComponentProps<typeof TextField> & { setValue: (value: string) => void };

export const SearchInput: FC<Props> = ({ value, setValue, ...props }) => {
    const { t } = useTranslation();
    return (
        <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={t('search')}
            size="small"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};
